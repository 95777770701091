import {
  AbstractExperienceFactory
} from '@/views/Cart/views/AddToCart/entry/experienceFactories/abstract.experience.factory'
import { FullActivity, Package } from '@/shared/types/catalog'
import { ActivitySaleMode, PackageSaleMode } from '@/shared/constants/catalog'
import { isEmpty } from 'lodash-es'
import { ExperienceConfig } from '@/views/Cart/views/AddToCart/entry/experienceFactories/types'
import { ExperienceBuilder } from '@/views/Cart/views/AddToCart/entry/experienceFactories/builders/experience.builder'
import { ExperienceImportance } from '@/views/Cart/views/AddToCart/entry/experienceFactories/enums'

export class BuyActivityExperienceFactory implements AbstractExperienceFactory {
  protected readonly experienceBuilder: ExperienceBuilder

  constructor (
    private readonly activity: FullActivity,
    private readonly pack: Package
  ) {
    this.experienceBuilder = new ExperienceBuilder({
      activity,
      pack
    })
  }

  public create (importance: ExperienceImportance): ExperienceConfig {
    this.experienceBuilder
      .activatePriceField({ unique: this.activity.saleMode === ActivitySaleMode.Subscription })

    if (this.activity.saleMode === ActivitySaleMode.Reservation) {
      if (this.pack.resourcesSelectable) {
        this.experienceBuilder.activateResourceField()
      }

      if (!isEmpty(this.pack.question)) {
        this.experienceBuilder.activateQuestionField()
      }

      if (this.pack.packageSaleMode === PackageSaleMode.InheritsActivity) {
        this.experienceBuilder.activateDateField()
        this.experienceBuilder.activateSlotField()
      }
    }

    return this.experienceBuilder.build()
  }
}
