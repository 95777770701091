import { ValueOf } from '@/shared/types/utils'
import { ApiTenantLoading } from '@/shared/api/tenant/constants'

export const SET_LOADING = 'SET_LOADING'
export const FORCE_LOADING = 'FORCE_LOADING'

// Router

export const ROUTE_GUARD = 'ROUTE_GUARD'

// Navigation

export const SET_IS_FIRST_PAGE = 'SET_IS_FIRST_PAGE'

// Catalog

export const GET_ACTIVITY = 'GET_ACTIVITY'
export const GET_ACTIVITIES = 'GET_ACTIVITIES'
export const GET_ACTIVITY_CATEGORIES = 'GET_ACTIVITY_CATEGORIES'
export const GET_ADDONS = 'GET_ADDONS'

export const GET_RESOURCES = 'GET_RESOURCES'
export const GET_CLOSED_DAYS = 'GET_CLOSED_DAYS'
export const GET_AVAILABILITIES = 'GET_AVAILABILITIES'

// Form

export const GET_FORM_RESERVATION_CUSTOMER = 'GET_FORM_RESERVATION_CUSTOMER'

// Add To Cart

export const LOADING_LOAD_ATC = 'LOADING_LOAD_ATC'
export const LOADING_MANAGE_BUNDLE = 'LOADING_MANAGE_BUNDLE'

// Order

export const SET_MEMORY_UUID = 'SET_MEMORY_UUID'
export const SET_MEMORY_EXPIRES_AT = 'SET_MEMORY_EXPIRES_AT'
export const SET_MEMORY_EXPIRATION_TIMEOUT_ID = 'SET_MEMORY_EXPIRATION_TIMEOUT_ID'
export const SET_MEMORY_EXPERIENCES = 'SET_MEMORY_EXPERIENCES'
export const SET_MEMORY_GIFTCARD = 'SET_MEMORY_GIFTCARD'
export const SET_MEMORY_PAYMENT_INFO = 'SET_MEMORY_PAYMENT_INFO'

export const UPDATE_ERROR = 'UPDATE_ERROR'

export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'
export const UPDATE_CUSTOMER_FORM = 'UPDATE_CUSTOMER_FORM'
export const REMOVE_CUSTOMER_FORM = 'REMOVE_CUSTOMER_FORM'
export const UPDATE_CUSTOMER_FULL = 'UPDATE_CUSTOMER_FULL'

export const RESET_ORDER = 'RESET_ORDER'
export const CREATE_ORDER = 'CREATE_ORDER'
export const GET_ORDER = 'GET_ORDER'
export const CREATE_ORDER_EXPERIENCE = 'CREATE_ORDER_EXPERIENCE'
export const UPDATE_ORDER_EXPERIENCE = 'UPDATE_ORDER_EXPERIENCE'
export const REMOVE_ORDER_EXPERIENCE = 'REMOVE_ORDER_EXPERIENCE'

export const UPDATE_ORDER_PRODUCTS = 'UPDATE_ORDER_PRODUCTS'
export const UPDATE_ORDER_CUSTOMER = 'UPDATE_ORDER_CUSTOMER'
export const UPDATE_ORDER_RECIPIENT = 'UPDATE_ORDER_RECIPIENT'
export const GET_ORDER_COUPON_ELIGIBILITY = 'GET_ORDER_COUPON_ELIGIBILITY'
export const POST_ORDER_COUPON = 'POST_ORDER_COUPON'
export const REMOVE_ORDER_COUPON = 'REMOVE_ORDER_COUPON'

export const UPDATE_ORDER_VALIDATION = 'UPDATE_ORDER_VALIDATION'
export const POST_ORDER_EVENT = 'POST_ORDER_EVENT'
export const GET_ORDER_PAYMENT = 'GET_ORDER_PAYMENT'

// Auth

export const GET_CUSTOMER_AUTH_CODE = 'GET_CUSTOMER_AUTH_CODE'
export const GET_CUSTOMER_AUTH_TOKEN = 'GET_CUSTOMER_AUTH_TOKEN'

export const SET_AUTH_ACCESS_TOKEN = 'SET_AUTH_ACCESS_TOKEN'
export const SET_AUTH_REFRESH_TOKEN = 'SET_AUTH_REFRESH_TOKEN'

export const GET_CUSTOMER_AUTH_INFO = 'GET_CUSTOMER_AUTH_INFO'

// Payment

export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS'
export const CREATE_PAYMENT = 'CREATE_PAYMENT'
export const GET_PAYMENT = 'GET_PAYMENT'

// Customer

export const UPDATE_CUSTOMER_INFO = 'UPDATE_CUSTOMER_INFO'
export const GET_CUSTOMER_SUBSCRIPTIONS = 'GET_CUSTOMER_SUBSCRIPTIONS'
export const GET_CUSTOMER_SUBSCRIPTION = 'GET_CUSTOMER_SUBSCRIPTION'
export const GET_CUSTOMER_INVOICES = 'GET_CUSTOMER_INVOICES'
export const GET_CUSTOMER_PAYMENT_TOKENS = 'GET_CUSTOMER_PAYMENT_TOKENS'
export const GET_CUSTOMER_VIRTUAL_CARD = 'GET_CUSTOMER_VIRTUAL_CARD'
export const CREATE_SUBSCRIPTION_PAYMENT_TOKEN_UPDATE_LINK = 'CREATE_SUBSCRIPTION_PAYMENT_TOKEN_UPDATE_LINK'

// Don't forget to put the new mutations in here or the loading feature won't work
export const LOADING_STATE = {
  [SET_LOADING]: false,
  [FORCE_LOADING]: false,

  // Router

  [ROUTE_GUARD]: false,

  // Navigation

  [SET_IS_FIRST_PAGE]: false,

  // Catalog

  [GET_ACTIVITY]: false,
  [GET_ACTIVITIES]: false,
  [GET_ACTIVITY_CATEGORIES]: false,
  [GET_ADDONS]: false,

  [GET_RESOURCES]: false,
  [GET_CLOSED_DAYS]: false,
  [GET_AVAILABILITIES]: false,

  // Form

  [GET_FORM_RESERVATION_CUSTOMER]: false,

  // Add To Cart

  [LOADING_LOAD_ATC]: false,
  [LOADING_MANAGE_BUNDLE]: false,

  // Order

  [SET_MEMORY_UUID]: false,
  [SET_MEMORY_EXPIRES_AT]: false,
  [SET_MEMORY_EXPIRATION_TIMEOUT_ID]: false,
  [SET_MEMORY_EXPERIENCES]: false,
  [SET_MEMORY_GIFTCARD]: false,
  [SET_MEMORY_PAYMENT_INFO]: false,

  [UPDATE_ERROR]: false,

  [UPDATE_CUSTOMER]: false,
  [UPDATE_CUSTOMER_FORM]: false,
  [REMOVE_CUSTOMER_FORM]: false,
  [UPDATE_CUSTOMER_FULL]: false,

  [RESET_ORDER]: false,
  [CREATE_ORDER]: false,
  [GET_ORDER]: false,
  [CREATE_ORDER_EXPERIENCE]: false,
  [UPDATE_ORDER_EXPERIENCE]: false,
  [REMOVE_ORDER_EXPERIENCE]: false,

  [UPDATE_ORDER_PRODUCTS]: false,
  [UPDATE_ORDER_CUSTOMER]: false,
  [UPDATE_ORDER_RECIPIENT]: false,
  [GET_ORDER_COUPON_ELIGIBILITY]: false,
  [POST_ORDER_COUPON]: false,
  [REMOVE_ORDER_COUPON]: false,

  [UPDATE_ORDER_VALIDATION]: false,
  [POST_ORDER_EVENT]: false,
  [GET_ORDER_PAYMENT]: false,

  // Auth

  [GET_CUSTOMER_AUTH_CODE]: false,
  [GET_CUSTOMER_AUTH_TOKEN]: false,

  [SET_AUTH_ACCESS_TOKEN]: false,
  [SET_AUTH_REFRESH_TOKEN]: false,

  [GET_CUSTOMER_AUTH_INFO]: false,

  // Payment

  [GET_PAYMENT_METHODS]: false,
  [CREATE_PAYMENT]: false,
  [GET_PAYMENT]: false,

  // Customer

  [UPDATE_CUSTOMER_INFO]: false,
  [GET_CUSTOMER_SUBSCRIPTIONS]: false,
  [GET_CUSTOMER_SUBSCRIPTION]: false,
  [GET_CUSTOMER_INVOICES]: false,
  [GET_CUSTOMER_PAYMENT_TOKENS]: false,
  [GET_CUSTOMER_VIRTUAL_CARD]: false,

  ...Object.values(ApiTenantLoading).reduce((acc, loading) => {
    acc[loading] = false
    return acc
  }, {} as Record<ValueOf<typeof ApiTenantLoading>, boolean>)
}
