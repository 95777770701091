import { Vue } from 'vue-property-decorator'
import { FullActivity } from '@/shared/types/catalog'
import { ROUTE_CART_ACTIVITIES } from '@/views/Cart/routes'

/**
 * Redirect if activity is missing
 * Returns true in case of redirection
 */
export function redirectIfActivityIsMissing (
  activity?: FullActivity
): boolean {
  if (!activity) {
    console.error('[GUIDAP] the activity is missing')
    Vue.$grouter.replace({ name: ROUTE_CART_ACTIVITIES })
    return true
  }
  return false
}
