
import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

@Component({
  data: () => ({
    STYLING: {
      // type
      primary: {
        type: 'is-primary',
        outlined: false
      },
      secondary: {
        type: null,
        outlined: true
      },
      danger: {
        type: 'is-danger',
        outlined: true
      },
      // size
      small: {
        size: 'is-small'
      },
      medium: {
        size: 'is-medium'
      },
      large: {
        size: 'is-large'
      }
    }
  })
})
export default class GuidapButton extends Vue {
  @Prop({ default: 'primary' }) readonly type!: 'primary' | 'secondary' | 'danger'
  @Prop({ default: 'medium' }) readonly size!: 'small' | 'medium' | 'large'
  @Prop(String) readonly href!: string
  @Prop(String) readonly iconLeft!: string
  @Prop(String) readonly iconRight!: string
  @Prop(Boolean) readonly loading!: boolean
  @Prop(Boolean) readonly expanded!: boolean
  @Prop(Boolean) readonly disabled!: boolean

  @Emit('click')
  click (): void { /* empty */ }
}
