
export const BLACKLISTED_ENTRY_POINTS = [
  'api'
]

export const BLACKLISTED_FUNCTIONS = [
  'close',
  'updateTranslations',
  'debugTranslations',
  'isCustomerLoggedIn'
]

export const BLACKLISTED_FUNCTIONS_WHEN_ROUTE_ACTIVE = [
  'openActivities',
  'openActivity',
  'openActivityDetails',
  'openActivityReserve',
  'openActivityBuyGift',
  'openPackageReserve',
  'openUseGiftcard',
  'openCart',
  'openCustomerHome',
  'openCustomerSubscriptions',
  'openCustomerInvoices',
  'openCustomerPaymentMethods',
  'openCustomerProfile',
  'logout'
]
