import Vue, { VueConstructor } from 'vue'
import Analytics from 'analytics'
import AnalyticsLogger from '@/shared/plugins/analytics/impl/logger'
import GTM from '@/shared/plugins/analytics/impl/gtm'
import GUA from '@/shared/plugins/analytics/impl/gua'
import FBP from '@/shared/plugins/analytics/impl/fbp'
import { AnalyticsPluginType } from '@/shared/plugins/analytics/types'
import { coreSettings } from '@/core/settings'

declare module 'vue/types/vue' {
  interface Vue {
    $analytics: AnalyticsPluginType
  }
  interface VueConstructor {
    $analytics: AnalyticsPluginType
  }
}

const AnalyticsPlugin = {
  install (vue: VueConstructor<Vue>): void {
    const analyticsInstance = Analytics({
      app: 'guidap-analytics',
      plugins: [
        AnalyticsLogger(
          { currency: coreSettings.currency },
          { enabled: process.env.NODE_ENV === 'development' }
        ),
        GTM({ currency: coreSettings.currency }),
        GUA({ currency: coreSettings.currency }),
        FBP({ currency: coreSettings.currency })
      ]
    })

    const analyticsPlugin: AnalyticsPluginType = {
      pageEvent: (title) => {
        analyticsInstance.page({ title })
      },
      trackEvent: (eventName, props) => {
        analyticsInstance.track(eventName, props)
      }
    }
    vue.prototype.$analytics = analyticsPlugin
    vue.$analytics = analyticsPlugin
  }
}

export default AnalyticsPlugin
