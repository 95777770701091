import Vue, { VueConstructor } from 'vue'
import { Store } from 'vuex'
import VueI18n from 'vue-i18n'
import { throttle } from 'lodash-es'
import i18n from '@/i18n'
import { RootState } from '@/shared/store'
import {
  ROUTE_CART_ACTIVITIES,
  ROUTE_CART_CONTENT,
  ROUTE_CART_USE_GIFTCARD
} from '@/views/Cart/routes'
import { ROUTE_CUSTOMER_LOGIN } from '@/routes'
import {
  ROUTE_CUSTOMER_INVOICES,
  ROUTE_CUSTOMER_ORDERS,
  ROUTE_CUSTOMER_PAYMENT_METHODS,
  ROUTE_CUSTOMER_PROFILE
} from '@/views/Customer/routes'
import { ModalEvent } from '@/components/modals/constants'
import { Customer } from '@/shared/types/order'
import { UPDATE_CUSTOMER_FULL } from '@/shared/store/mutations'
import { Language } from '@/core/settings/constants'
import { setLangSetting } from '@/core/settings'
import { CustomerOrdersTab } from '@/views/Customer/views/CustomerOrders/constants'
import { openAtcWithWorkflow } from '@/views/Cart/views/AddToCart/entry'
import {
  BuyActivityEntryWorkflow
} from '@/views/Cart/views/AddToCart/entry/workflows/buyActivity.entry.workflow'
import {
  BuyGiftcardEntryWorkflow
} from '@/views/Cart/views/AddToCart/entry/workflows/buyGiftcard.entry.workflow'

export function installGuidapInVue (vue: VueConstructor<Vue>, store: Store<RootState>): void {
  const pushClosedRoute = throttle(
    () => { vue.$grouter.push() },
    50,
    { trailing: false }
  )

  vue.$guidap = vue.prototype.$guidap = {
    openActivities: () => {
      vue.$grouter.push({ name: ROUTE_CART_ACTIVITIES })
    },
    openActivityDetails: (uuid: string) => {
      vue.$grouter.push({
        name: ROUTE_CART_ACTIVITIES,
        props: { uuid }
      })
    },
    openActivityReserve: (activityUuid: string) => {
      openAtcWithWorkflow('push', BuyActivityEntryWorkflow.generateRouteData({
        activityUuid
      }))
    },
    openActivityBuyGift: (activityUuid: string) => {
      openAtcWithWorkflow('push', BuyGiftcardEntryWorkflow.generateRouteData({
        activityUuid
      }))
    },
    openPackageReserve: (activityUuid: string, packUuid: string) => {
      openAtcWithWorkflow('push', BuyActivityEntryWorkflow.generateRouteData({
        activityUuid,
        packUuid
      }))
    },
    openUseGiftcard: () => {
      vue.$grouter.push({ name: ROUTE_CART_USE_GIFTCARD })
    },
    openCart: () => {
      vue.$grouter.push({ name: ROUTE_CART_CONTENT })
    },

    openCustomerHome: () => {
      vue.$grouter.push({ name: ROUTE_CUSTOMER_LOGIN })
    },
    openCustomerSubscriptions: () => {
      vue.$grouter.push({
        name: ROUTE_CUSTOMER_ORDERS,
        props: { tab: CustomerOrdersTab.Subscriptions }
      })
    },
    openCustomerInvoices: () => {
      vue.$grouter.push({ name: ROUTE_CUSTOMER_INVOICES })
    },
    openCustomerPaymentMethods: () => {
      vue.$grouter.push({ name: ROUTE_CUSTOMER_PAYMENT_METHODS })
    },
    openCustomerProfile: () => {
      vue.$grouter.push({ name: ROUTE_CUSTOMER_PROFILE })
    },

    logout: async (options = { preventRedirection: false }) => {
      await store.dispatch('auth/logout')
      if (options.preventRedirection) {
        return
      }
      vue.$grouter.push({ name: ROUTE_CUSTOMER_LOGIN })
    },

    close: (options = { preventHistoryUpdate: false }) => {
      vue.$modal.$emit(ModalEvent.Close)
      if (options.preventHistoryUpdate) {
        return
      }
      pushClosedRoute()
    },

    setCustomer: (customer: Customer) => {
      store.commit(`cart/${UPDATE_CUSTOMER_FULL}`, customer)
    },
    setLanguage: (lang: Language) => {
      setLangSetting(lang)
    },
    updateTranslations: (lang: string, messagesObject: VueI18n.LocaleMessageObject) => {
      i18n.mergeLocaleMessage(lang.toLowerCase(), messagesObject)
    },
    debugTranslations: () => {
      i18n.setLocaleMessage('debug', {})
      i18n.fallbackLocale = 'debug'
      i18n.locale = 'debug'
    }
  }
}
