
import { Component, Vue } from 'vue-property-decorator'
import GuidapButton from '@/components/ui/GuidapButton.vue'

@Component({
  components: {
    GuidapButton
  }
})
export default class GuidapReserveButton extends Vue {
  openActivities (): void {
    this.$guidap.openActivities()
  }
}
