import { FullActivity } from '@/shared/types/catalog'
import { Image } from '@/shared/types/image'
import {
  OrderExperienceType,
  OrderCouponType,
  OrderStatus,
  OrderBehavior
} from '@/shared/constants/order'
import { FormErrorFormatted } from '@/shared/types/form'
import { FormError } from '@/shared/constants/form'
import { SubscriptionPeriodUnit } from '@/shared/constants/customer'

export interface OrderRequirements {
  experiences?: Record<string, unknown>
  customer?: Record<string, unknown>
  payment?: Record<string, unknown>
}

export type OrderPrice = {
  uuid: string
  name: string
  quantity: number
  unitPrice: number
  total: number
  subscriptionMaxBillingCycles?: number | null
  subscriptionPeriodLength?: number | null
  subscriptionPeriodUnit?: SubscriptionPeriodUnit | null
}

export type OrderResource = {
  uuid: string
  name: string
  quantity: number
}

export interface OrderProduct {
  uuid: string
  name: string
  quantity: number
  unitPrice: number
  total: number
  images: Image
}

export interface OrderDetails {
  packageUuid: string
  packageName: string
  activityUuid: string
  activityName: string
  categoryUuid: string
  categoryName: string
  images: Image
}

export interface OrderExperience {
  uuid: string
  details: OrderDetails
  startAt: string
  endAt: string
  deletedAt: string
  question: string
  answer: string
  total: number
  type: OrderExperienceType
  nestedExperiences?: string[]
  prices: OrderPrice[]
  resources: OrderResource[]
}

export class OrderCoupon {
  code: string | null = null
  discount: number | null = null
  type: OrderCouponType | null = null
}

export class Order {
  uuid: string | null = null
  reference: string | null = null
  status: OrderStatus | null = null
  behavior: OrderBehavior | null = null
  expiresAt: number | null = null
  total: number | null = null
  leftToPay: number | null = null
  coupon = new OrderCoupon()
  requirements: OrderRequirements = {}
  experiences: OrderExperience[] = []
  products: OrderProduct[] = []
}

export interface CustomerFormType {
  uuid: string
  answer: boolean
}

export interface Customer {
  gender: number
  firstname: string
  lastname: string
  company: string
  email: string
  phone: string
  locale: string
  form: CustomerFormType[]
}

export type CustomerErrorForm = Record<string, FormError[]>
// EXAMPLE: { '69741f0b-98a9-11ec-ad48-0242ac12000a': ['The form is required.'] }

export interface CustomerError {
  gender?: string[]
  firstname?: string[]
  lastname?: string[]
  company?: string[]
  email?: string[]
  phone?: string[]
  locale?: string[]
  form?: CustomerErrorForm
}

export interface CustomerFormattedErrors {
  form: FormErrorFormatted
}

export interface Recipient {
  firstname: string
  lastname: string
  email: string
  phone: string
}

// Coupon

export interface CouponEligibility {
  discount: number
  giftCardExperience: OrderExperience
  giftCardActivity: FullActivity
}
