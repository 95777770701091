
export enum Language {
  En = 'en',
  Fr = 'fr',
  Es = 'es',
  Nl = 'nl',
  De = 'de',
  It = 'it',
  Pt = 'pt',
  Ca = 'ca'
}

export const DEFAULT_LANGUAGE = Language.Fr
