import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/shared/store'

export class State {
}

const getters = <GetterTree<State, RootState>>{
}

const mutations = <MutationTree<State>>{
}

const actions = <ActionTree<State, RootState>>{
}

export default {
  namespaced: true,
  state: new State(),
  getters,
  mutations,
  actions
}
