
export enum ActivitySaleMode {
  Reservation = 'reservation',
  Ticketing = 'ticketing',
  Subscription = 'subscription',
}

export enum PackageSaleMode {
  InheritsActivity = 'inherits_activity',
  SellsCoupon = 'sells_coupon',
  SellsBundle = 'sells_bundle',
}
