import {
  AbstractExperienceFactory
} from '@/views/Cart/views/AddToCart/entry/experienceFactories/abstract.experience.factory'
import { FullActivity, Package } from '@/shared/types/catalog'
import { ActivitySaleMode, PackageSaleMode } from '@/shared/constants/catalog'
import { isEmpty } from 'lodash-es'
import { createDateFromString, getReadableTimeFromString } from '@/shared/utils/date'
import { indexRecordValueByKey } from '@/shared/utils/record'
import { OrderExperience } from '@/shared/types/order'
import { ExperienceConfig } from '@/views/Cart/views/AddToCart/entry/experienceFactories/types'
import { ExperienceBuilder } from '@/views/Cart/views/AddToCart/entry/experienceFactories/builders/experience.builder'
import { ExperienceImportance } from '@/views/Cart/views/AddToCart/entry/experienceFactories/enums'

export class EditActivityExperienceFactory implements AbstractExperienceFactory {
  protected readonly experienceBuilder: ExperienceBuilder

  constructor (
    private readonly activity: FullActivity,
    private readonly pack: Package,
    private readonly orderExperience: OrderExperience
  ) {
    this.experienceBuilder = new ExperienceBuilder({
      activity,
      pack,
      orderExperience
    })
  }

  public create (importance: ExperienceImportance): ExperienceConfig {
    this.experienceBuilder
      .deactivatePackField()
      .activatePriceField({
        initialPriceQuantityByUuid: indexRecordValueByKey(this.orderExperience.prices, 'uuid', 'quantity')
      })

    if (this.activity.saleMode === ActivitySaleMode.Reservation) {
      if (this.pack.resourcesSelectable) {
        this.experienceBuilder.activateResourceField({
          initialResourceQuantityByUuid: indexRecordValueByKey(this.orderExperience.resources, 'uuid', 'quantity')
        })
      }

      if (!isEmpty(this.pack.question)) {
        this.experienceBuilder.activateQuestionField({ initialAnswer: this.orderExperience.answer })
      }

      if (this.pack.packageSaleMode === PackageSaleMode.InheritsActivity) {
        this.experienceBuilder.activateDateField({
          initialDate: createDateFromString(this.orderExperience.startAt)
        })
        this.experienceBuilder.activateSlotField({
          initialSlotDeparture: getReadableTimeFromString(this.orderExperience.startAt)
        })
      }
    }

    return this.experienceBuilder.build()
  }
}
