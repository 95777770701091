import Vue from 'vue'
import i18n from '@/i18n'
import { RouteOptionsType } from '@/shared/router/types'
import store from '@/shared/store'
import { ROUTE_CART_CONTENT } from '@/views/Cart/routes'

/**
 * Redirect if a coupon is being used in cart
 * Returns true in case of redirection
 */
export function redirectIfCartIsUsingCoupon (
  next: (options?: RouteOptionsType) => void,
  redirectionTranslation: string
): boolean {
  if (store.getters['cart/isCartUsingCoupon']) {
    Vue.$toast.openWarning(i18n.t(redirectionTranslation))
    next({ name: ROUTE_CART_CONTENT })
    return true
  }
  return false
}
