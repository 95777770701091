
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import GuidapIcon from '@/components/ui/GuidapIcon.vue'

@Component({
  components: {
    GuidapIcon
  }
})
export default class GuidapButtonRoot extends Vue {
  @Prop(String) readonly rootClass!: string
  @Prop(String) readonly icon!: string

  @Emit('click')
  click (): void { /* empty */ }
}
