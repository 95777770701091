
import { Component, Prop, Vue } from 'vue-property-decorator'
import GuidapButtonRoot from '@/components/ui/GuidapButtonRoot.vue'
import { CustomerPage } from '@/components/exports/GuidapCustomerAccountButton/constants'

@Component({
  components: {
    GuidapButtonRoot
  }
})
export default class GuidapCustomerAccountButton extends Vue {
  @Prop() readonly page!: CustomerPage

  openCustomerHome (): void {
    if (this.page === CustomerPage.Subscriptions) {
      this.$guidap.openCustomerSubscriptions()
      return
    } else if (this.page === CustomerPage.Invoices) {
      this.$guidap.openCustomerInvoices()
      return
    } else if (this.page === CustomerPage.PaymentMethods) {
      this.$guidap.openCustomerPaymentMethods()
      return
    }

    this.$guidap.openCustomerHome()
  }
}
