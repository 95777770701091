import Vue from 'vue'
import { AxiosPromise, CancelTokenSource } from 'axios'
import {
  UPDATE_CUSTOMER_INFO,
  GET_CUSTOMER_INVOICES,
  GET_CUSTOMER_PAYMENT_TOKENS,
  GET_CUSTOMER_SUBSCRIPTION,
  GET_CUSTOMER_SUBSCRIPTIONS,
  GET_CUSTOMER_VIRTUAL_CARD,
  CREATE_SUBSCRIPTION_PAYMENT_TOKEN_UPDATE_LINK
} from '@/shared/store/mutations'
import {
  Subscription,
  Invoice,
  PaymentToken,
  ShortSubscription,
  VirtualCard,
  SubscriptionPaymentTokenUpdateLink,
  CustomerInfo
} from '@/shared/types/customer'
import { UpdateCustomerInfoRequest } from '@/shared/api/customer/types'

export function updateCustomerInfo (
  body: UpdateCustomerInfoRequest,
  request: CancelTokenSource | null = null
): AxiosPromise<CustomerInfo> {
  return Vue.$httpAuth.put(
    '/customer',
    body,
    {
      cancelToken: request?.token,
      metadata: { loading: UPDATE_CUSTOMER_INFO }
    }
  )
}

export function getCustomerSubscriptions (
  request: CancelTokenSource | null = null
): AxiosPromise<ShortSubscription[]> {
  return Vue.$httpAuth.get(
    '/customer/subscriptions',
    {
      cancelToken: request?.token,
      metadata: { loading: GET_CUSTOMER_SUBSCRIPTIONS }
    }
  )
}

export function getCustomerSubscription (
  uuid: string,
  request: CancelTokenSource | null = null
): AxiosPromise<Subscription> {
  return Vue.$httpAuth.get(
    `/customer/subscriptions/${uuid}`,
    {
      cancelToken: request?.token,
      metadata: { loading: GET_CUSTOMER_SUBSCRIPTION }
    }
  )
}

export function createSubscriptionPaymentTokenUpdateLink (
  uuid: string,
  returnUrl: string,
  request: CancelTokenSource | null = null
): AxiosPromise<SubscriptionPaymentTokenUpdateLink> {
  return Vue.$httpAuth.post(
    `/customer/subscriptions/${uuid}/payment-token`,
    {
      returnUrl
    },
    {
      cancelToken: request?.token,
      metadata: { loading: CREATE_SUBSCRIPTION_PAYMENT_TOKEN_UPDATE_LINK }
    }
  )
}

export function getCustomerInvoices (
  request: CancelTokenSource | null = null
): AxiosPromise<Invoice[]> {
  return Vue.$httpAuth.get(
    '/customer/invoices',
    {
      cancelToken: request?.token,
      metadata: { loading: GET_CUSTOMER_INVOICES }
    }
  )
}

export function getCustomerPaymentTokens (
  request: CancelTokenSource | null = null
): AxiosPromise<PaymentToken[]> {
  return Vue.$httpAuth.get(
    '/customer/payment-tokens',
    {
      cancelToken: request?.token,
      metadata: { loading: GET_CUSTOMER_PAYMENT_TOKENS }
    }
  )
}

export function getCustomerVirtualCard (
  request: CancelTokenSource | null = null
): AxiosPromise<VirtualCard> {
  return Vue.$httpAuth.get(
    '/customer/virtual-card',
    {
      cancelToken: request?.token,
      metadata: { loading: GET_CUSTOMER_VIRTUAL_CARD }
    }
  )
}
