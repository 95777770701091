import { RouteOptionsType } from '@/shared/router/types'
import { ROUTE_CUSTOMER_ORDERS } from '@/views/Customer/routes'
import store from '@/shared/store'
import { ROUTE_CUSTOMER_LOGIN } from '@/routes'

/**
 * Redirect if the customer is logged in
 * Returns true in case of redirection
 */
export function redirectIfCustomerIsLoggedIn (next: (options?: RouteOptionsType) => void): boolean {
  if (store.getters['auth/isLoggedIn']) {
    next({ name: ROUTE_CUSTOMER_ORDERS })
    return true
  }
  return false
}

/**
 * Redirect if the customer is not logged in
 * Returns true in case of redirection
 */
export function redirectIfCustomerIsNotLoggedIn (
  next: (options?: RouteOptionsType) => void,
  to: RouteOptionsType
): boolean {
  if (!store.getters['auth/isLoggedIn']) {
    next({
      name: ROUTE_CUSTOMER_LOGIN,
      state: { redirectOptions: to }
    })
    return true
  }
  return false
}
