import { RouteOptionsType } from '@/shared/router/types'
import { ROUTE_CART_ACTIVITIES } from '@/views/Cart/routes'
import { isFeatureEnabled } from '@/core/feature'
import { FeatureFlag } from '@/core/feature/constants'

/**
 * Redirect if the customer account feature is missing
 * Returns true in case of redirection
 */
export function redirectIfCustomerAccountFeatureIsMissing (next: (options?: RouteOptionsType) => void): boolean {
  if (!isFeatureEnabled(FeatureFlag.CartCustomerAccount)) {
    console.error(`[GUIDAP Feature] ${FeatureFlag.CartCustomerAccount} disabled`)
    next({ name: ROUTE_CART_ACTIVITIES })
    return true
  }
  return false
}
