import {
  AbstractExperienceFactory
} from '@/views/Cart/views/AddToCart/entry/experienceFactories/abstract.experience.factory'
import { ExperienceConfig } from '@/views/Cart/views/AddToCart/entry/experienceFactories/types'
import { ExperienceBuilder } from '@/views/Cart/views/AddToCart/entry/experienceFactories/builders/experience.builder'
import { FullActivity, Package } from '@/shared/types/catalog'
import { ExperienceImportance } from '@/views/Cart/views/AddToCart/entry/experienceFactories/enums'

export class BuyGiftcardExperienceFactory implements AbstractExperienceFactory {
  protected readonly experienceBuilder: ExperienceBuilder

  constructor (
    private readonly activity: FullActivity,
    private readonly pack: Package
  ) {
    this.experienceBuilder = new ExperienceBuilder({
      activity,
      pack
    })
  }

  public create (importance: ExperienceImportance): ExperienceConfig {
    return this.experienceBuilder
      .activatePriceField()
      .activateRecipientField()
      .build()
  }
}
