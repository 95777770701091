
import { Component, Vue } from 'vue-property-decorator'
import GuidapButtonRoot from '@/components/ui/GuidapButtonRoot.vue'

@Component({
  components: {
    GuidapButtonRoot
  }
})
export default class GuidapCartButton extends Vue {
  private get experiencesCount (): number {
    return this.$store.getters['cart/experiencesCount']
  }

  openCart (): void {
    this.$guidap.openCart()
  }
}
