import { parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js/max'

export function parsePhone (phone: string): PhoneNumber | null {
  if (!phone) {
    return null
  }
  return parsePhoneNumberFromString(phone) || null
}

export function isPhoneValid (phone: string): boolean {
  if (!phone) {
    return false
  }
  const parsedPhone = parsePhoneNumberFromString(phone)
  return !!parsedPhone && parsedPhone.isValid()
}

export function isPhoneE164 (phone: string): boolean {
  return !!phone && phone.charAt(0) === '+' && isPhoneValid(phone)
}

export function formatPhone (phone: string, options = { toHref: false }): string {
  const parsedPhone = parsePhoneNumberFromString(phone)

  if (!parsedPhone) {
    return phone
  }

  return parsedPhone.format(options?.toHref ? 'RFC3966' : 'INTERNATIONAL')
}
