import { getCurrencyDigits } from '@/shared/plugins/price/utils/currency'

/**
 * Turns 3 euros into 300 cents
 * @param {number} amountInMajor The amount in major unit (euros).
 * @param {string} currency Currency from which we use digits.
 * @returns {number} return the amount in minor unit (cents).
 */
export function convertToMinorUnit (amountInMajor: number, currency: string): number {
  if (!amountInMajor) {
    return 0
  }

  const amountInMinor = amountInMajor * Math.pow(10, getCurrencyDigits(currency))
  const strAmount = amountInMinor.toFixed(0)
  return parseInt(strAmount)
}

/**
 * Turns 300 cents into 3 euros
 * @param {number} amountInMinor The amount in minor unit (cents)
 * @param {number} currency Currency from which we use digits.
 * @returns {number} return the amount in major (euros).
 */
export function convertToMajorUnit (amountInMinor: number, currency: string): number {
  if (!amountInMinor) {
    return 0
  }

  // we truncate the minor amount because it should be impossible to get a decimal minor unit amount.
  const truncatedAmount = Math.trunc(amountInMinor)
  return truncatedAmount / Math.pow(10, getCurrencyDigits(currency))
}
