
export enum FormName {
  ReservationCustomer = 'reservation_customer'
}

export enum FormType {
  OptIn = 'opt_in'
}

export enum FormError {
  DoesNotExist = 'The form does not exist.',
  Required = 'The form is required.',
  NotValid = 'The answer to the form is not valid.'
}
