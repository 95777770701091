import Vue, { VueConstructor } from 'vue'
import { Store } from 'vuex'
import { RootState } from '@/shared/store'
import VueI18n from 'vue-i18n'
import { Language } from '@/core/settings/constants'
import { getCustomerSubscriptions, getCustomerVirtualCard } from '@/shared/api/customer'
import { Customer } from '@/shared/types/order'

export function installGuidapInWindow (vue: VueConstructor<Vue>, store: Store<RootState>): void {
  // Protect the window.GUIDAP object, the plugin can't be changed from the outside
  window.GUIDAP = Object.assign(
    {},
    typeof window.GUIDAP === 'object' ? window.GUIDAP || {} : {},
    {
      openActivities: () => {
        vue.$guidap.openActivities()
      },
      openActivityDetails: (uuid: string) => {
        vue.$guidap.openActivityDetails(uuid)
      },
      // @deprecated Use openActivityReserve() instead
      openActivity: (uuid: string) => {
        vue.$guidap.openActivityReserve(uuid)
      },
      openActivityReserve: (uuid: string) => {
        vue.$guidap.openActivityReserve(uuid)
      },
      openActivityBuyGift: (uuid: string) => {
        vue.$guidap.openActivityBuyGift(uuid)
      },
      openPackageReserve: (activityUuid: string, packUuid: string) => {
        vue.$guidap.openPackageReserve(activityUuid, packUuid)
      },
      openUseGiftcard: () => {
        vue.$guidap.openUseGiftcard()
      },
      openCart: () => {
        vue.$guidap.openCart()
      },
      openCustomerHome: () => {
        vue.$guidap.openCustomerHome()
      },
      openCustomerSubscriptions: () => {
        vue.$guidap.openCustomerSubscriptions()
      },
      openCustomerInvoices: () => {
        vue.$guidap.openCustomerInvoices()
      },
      openCustomerPaymentMethods: () => {
        vue.$guidap.openCustomerPaymentMethods()
      },
      openCustomerProfile: () => {
        vue.$guidap.openCustomerProfile()
      },
      logout: async (options = { preventRedirection: false }) => {
        await vue.$guidap.logout(options)
      },
      close: () => {
        vue.$guidap.close()
      },
      setCustomer: (customer: Customer) => {
        vue.$guidap.setCustomer(customer)
      },
      setLanguage: (lang: Language) => {
        vue.$guidap.setLanguage(lang)
      },
      updateTranslations: (lang: string, messagesObject: VueI18n.LocaleMessageObject) => {
        vue.$guidap.updateTranslations(lang, messagesObject)
      },
      debugTranslations: () => {
        vue.$guidap.debugTranslations()
      },
      // External functions
      isCustomerLoggedIn: () => {
        return store.getters['auth/isLoggedIn']
      },
      // API calls returning an AJAX response
      api: {
        customer: {
          getSubscriptions: getCustomerSubscriptions,
          getVirtualCard: getCustomerVirtualCard
        }
      }
    })
}
