import Vue from 'vue'
import Buefy, { ConfigProgrammatic } from 'buefy'

Vue.use(Buefy)

ConfigProgrammatic.setOptions({
  defaultIconPack: 'guidap',
  customIconPacks: {
    guidap: {
      iconPrefix: 'gd-'
    }
  }
})
