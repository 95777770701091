
import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

@Component({})
export default class Alert extends Vue {
  @Prop(Boolean) readonly isOpen!: boolean

  @Emit('close')
  close (): void {
    (this.$refs.alert as Vue & { close: () => boolean }).close()
  }
}
