import { AbstractEntryWorkflow } from '@/views/Cart/views/AddToCart/entry/abstract.entry.workflow'
import { EntryWorkflowMode, EntryWorkflowType } from '@/views/Cart/views/AddToCart/entry/enums'
import {
  EntryWorkflowGiftcard,
  EntryWorkflowProps,
  EntryWorkflowState
} from '@/views/Cart/views/AddToCart/entry/types'
import { OrderExperience } from '@/shared/types/order'
import {
  EditUseGiftcardExperienceFactory
} from '@/views/Cart/views/AddToCart/entry/experienceFactories/factories/editUseGiftcard.experience.factory'
import {
  redirectIfActivityIsMissing
} from '@/views/Cart/views/AddToCart/entry/guards/redirectIfActivityIsMissing'
import {
  redirectIfExperienceIsMissing
} from '@/views/Cart/views/AddToCart/entry/guards/redirectIfExperienceIsMissing'
import {
  AbstractExperienceFactory
} from '@/views/Cart/views/AddToCart/entry/experienceFactories/abstract.experience.factory'
import {
  redirectIfExperienceIsUsingDeletedConfig
} from '@/views/Cart/views/AddToCart/entry/guards/redirectIfExperienceIsUsingDeletedConfig'
import {
  redirectIfGiftcardIsMissing
} from '@/views/Cart/views/AddToCart/entry/guards/redirectIfGiftcardIsMissing'
import {
  redirectIfPackIsMissing
} from '@/views/Cart/views/AddToCart/entry/guards/redirectIfPackIsMissing'
import { FullActivity, Package } from '@/shared/types/catalog'

export class EditUseGiftcardEntryWorkflow extends AbstractEntryWorkflow {
  declare protected readonly pack: Package
  private readonly orderExperience: OrderExperience
  private readonly giftcard: EntryWorkflowGiftcard

  constructor (options: {
    activity: FullActivity,
    pack: Package,
    orderExperience: OrderExperience,
    giftcard: EntryWorkflowGiftcard
  }) {
    super(EntryWorkflowMode.Edition, EntryWorkflowType.UseGiftcard, options)
    this.orderExperience = options.orderExperience
    this.giftcard = options.giftcard
  }

  public static generateRouteData (options: {
    activityUuid: string,
    packUuid: string,
    activity?: FullActivity,
    pack?: Package,
    orderExperience: OrderExperience,
    giftcard: EntryWorkflowGiftcard
  }): {
    props: EntryWorkflowProps,
    state: EntryWorkflowState
  } {
    return {
      props: {
        type: EntryWorkflowType.UseGiftcard,
        activityUuid: options.activityUuid,
        packUuid: options.packUuid
      },
      state: {
        mode: EntryWorkflowMode.Edition,
        activity: options.activity,
        pack: options.pack,
        orderExperience: options.orderExperience,
        giftcard: options.giftcard
      }
    }
  }

  public static supportRoute (
    mode: EntryWorkflowMode,
    type: EntryWorkflowType
  ): boolean {
    return (
      mode === EntryWorkflowMode.Edition &&
      type === EntryWorkflowType.UseGiftcard
    )
  }

  public validateGuards (): void {
    redirectIfActivityIsMissing(this.activity)
    redirectIfPackIsMissing(this.pack)
    redirectIfExperienceIsMissing(this.orderExperience)
    redirectIfExperienceIsUsingDeletedConfig(this.activity, this.orderExperience)
    redirectIfGiftcardIsMissing(this.giftcard)
  }

  public getSupportedExperienceFactory (activity: FullActivity, pack?: Package): AbstractExperienceFactory {
    if (!pack) {
      throw new Error('[GUIDAP - getSupportedExperienceFactory] Pack is required')
    }
    return new EditUseGiftcardExperienceFactory(activity, pack, this.orderExperience, this.giftcard)
  }
}
