import { GtmEcommerceItems } from '@/shared/plugins/analytics/impl/gtm/types'
import { Activity, Addon, Package, Price } from '@/shared/types/catalog'
import { Order, OrderExperience, OrderPrice, OrderProduct } from '@/shared/types/order'
import { OrderCouponType } from '@/shared/constants/order'

export function createGtmItemFromActivity (
  activity: Activity
): GtmEcommerceItems {
  return {
    item_id: activity.uuid,
    item_name: activity.name,
    affiliation: 'GUIDAP',
    item_brand: 'GUIDAP',
    item_category: activity.name,
    item_list_id: 'activity',
    item_list_name: 'activity'
  }
}

export function createGtmItemFromPackage (
  activity: Activity,
  pack: Package
): GtmEcommerceItems {
  return {
    item_id: pack.uuid,
    item_name: pack.name,
    affiliation: 'GUIDAP',
    item_brand: 'GUIDAP',
    item_category: activity.name,
    item_category2: pack.name,
    item_list_id: 'package',
    item_list_name: 'package'
  }
}

export function createGtmItemFromPrice (
  activity: Activity,
  pack: Package,
  price: Price,
  quantity?: number
): GtmEcommerceItems {
  return {
    item_id: price.uuid,
    item_name: price.name,
    affiliation: 'GUIDAP',
    item_brand: 'GUIDAP',
    item_category: activity.name,
    item_category2: pack.name,
    item_category3: price.name,
    item_list_id: 'price',
    item_list_name: 'price',
    price: price.amount,
    quantity
  }
}

export function createGtmItemFromAddon (
  addon: Addon,
  quantity?: number
): GtmEcommerceItems {
  return {
    item_id: addon.uuid,
    item_name: addon.name,
    affiliation: 'GUIDAP',
    item_brand: 'GUIDAP',
    item_category4: addon.name,
    item_list_id: 'addon',
    item_list_name: 'addon',
    price: addon.amount,
    quantity
  }
}

export function getGtmItemListFromOrder (order: Order, coupon?: string): GtmEcommerceItems[] {
  const items: GtmEcommerceItems[] = []

  order.experiences.forEach(experience => {
    items.push(
      ...experience.prices.map(orderPrice =>
        createGtmItemFromOrderPrice(experience, orderPrice, coupon)
      )
    )
  })
  items.push(
    ...order.products.map(orderProduct =>
      createGtmItemFromOrderProduct(orderProduct, coupon)
    )
  )

  return items
}

export function createGtmItemFromOrderPrice (
  experience: OrderExperience,
  orderPrice: OrderPrice,
  coupon?: string
): GtmEcommerceItems {
  return {
    item_id: orderPrice.uuid,
    item_name: orderPrice.name,
    affiliation: 'GUIDAP',
    item_brand: 'GUIDAP',
    item_category: experience.details.activityName,
    item_category2: experience.details.packageName,
    item_category3: orderPrice.name,
    item_list_id: 'price',
    item_list_name: 'price',
    price: orderPrice.unitPrice,
    quantity: orderPrice.quantity,
    coupon
  }
}

export function createGtmItemFromOrderProduct (
  orderProduct: OrderProduct,
  coupon?: string
): GtmEcommerceItems {
  return {
    item_id: orderProduct.uuid,
    item_name: orderProduct.name,
    affiliation: 'GUIDAP',
    item_brand: 'GUIDAP',
    item_category4: orderProduct.name,
    item_list_id: 'addon',
    item_list_name: 'addon',
    price: orderProduct.unitPrice,
    quantity: orderProduct.quantity,
    coupon
  }
}

export function getCouponFromOrder (order: Order): string | undefined {
  if (!order?.coupon?.code || order.coupon.type !== OrderCouponType.Voucher) {
    return undefined
  }
  return order.coupon.code
}
