import Vue, { VueConstructor } from 'vue'
import { Store } from 'vuex'
import axios, { AxiosInstance } from 'axios'
import { RootState } from '@/shared/store'
import { setErrorInterceptor } from '@/shared/plugins/http/httpErrorInterceptor'
import { setLoadingInterceptor } from '@/shared/plugins/http/httpLoadingInterceptor'
import { setAuthInterceptor } from '@/shared/plugins/http/httpAuthInterceptor'
import { CustomAxiosInterceptorError, HttpLoadingType } from '@/shared/plugins/http/types'
import { coreSettings } from '@/core/settings'

declare module 'axios' {
  export interface AxiosRequestConfig {
    metadata: {
      loading: HttpLoadingType
    }
  }
  export interface AxiosInterceptorManager<V> {
    use(
      onFulfilled?: (value: V) => V | Promise<V>,
      onRejected?: (error: CustomAxiosInterceptorError) => unknown
    ): number
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $http: AxiosInstance
    $httpAuth: AxiosInstance
  }
  interface VueConstructor {
    $http: AxiosInstance
    $httpAuth: AxiosInstance
  }
}

const HttpPlugin = {
  install (vue: VueConstructor<Vue>, store: Store<RootState>): void {
    // Classic $http plugin

    const axiosHttp = createHttpInstance()

    setLoadingInterceptor(axiosHttp, store)

    setErrorInterceptor(axiosHttp, vue)

    setBookingModuleTokenHeader(axiosHttp, coreSettings.token)

    vue.$http = vue.prototype.$http = axiosHttp

    // $http plugin requiring the user to be logged in

    const axiosHttpAuth = createHttpInstance()

    setLoadingInterceptor(axiosHttpAuth, store)

    setErrorInterceptor(axiosHttpAuth, vue)

    setAuthInterceptor(axiosHttpAuth, store)

    setBookingModuleTokenHeader(axiosHttpAuth, coreSettings.token)

    vue.$httpAuth = vue.prototype.$httpAuth = axiosHttpAuth
  }
}

function createHttpInstance (): AxiosInstance {
  return axios.create({
    baseURL: process.env.VUE_APP_PUBLIC_BASE_URL,
    metadata: { loading: null }
  })
}

export function setBookingModuleTokenHeader (axiosInstance: AxiosInstance, token: string | null): void {
  axiosInstance.defaults.headers.common['X-Booking-Module'] = token
}

export function setBookingModuleToken (token: string | null): void {
  if (Vue.$http) {
    setBookingModuleTokenHeader(Vue.$http, token)
  }
  if (Vue.$httpAuth) {
    setBookingModuleTokenHeader(Vue.$httpAuth, token)
  }
}

export default HttpPlugin
