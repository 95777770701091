import { AnalyticsPlugin } from 'analytics'
import {
  AnalyticsConfigType,
  AnalyticsPageType,
  AnalyticsTrackType
} from '@/shared/plugins/analytics/types'

export default function AnalyticsLogger (
  config: AnalyticsConfigType,
  { enabled = false }: { enabled: boolean }
): AnalyticsPlugin {
  return {
    name: 'guidap-logger',
    config,

    initialize: () => {
      console.log('[GUIDAP Analytics] initialize')
    },
    page: ({ payload }: AnalyticsPageType) => {
      console.log(`[GUIDAP Analytics] page: ${payload.properties.title}`)
    },
    track: ({ payload }: AnalyticsTrackType) => {
      console.log(`[GUIDAP Analytics] track: ${payload.event}`, payload.properties)
    },
    identify: () => { /* empty */ },
    loaded: () => {
      return enabled
    }
  }
}
