import { PluginRouteType, RouteOptionsType } from '@/shared/router/types'
import { routes as cartRoutes } from '@/views/Cart/routes'
import { routes as customerRoutes } from '@/views/Customer/routes'
import { redirectIfCustomerAccountFeatureIsMissing } from '@/shared/router/guards/feature'
import { redirectIfCustomerIsLoggedIn, redirectIfCustomerIsNotLoggedIn } from '@/shared/router/guards/auth'

export const ROUTE_CART_ROOT = 'cart-root'
export const ROUTE_CUSTOMER_LOGIN = 'customer-login'
export const ROUTE_CUSTOMER_ROOT = 'customer-root'

export const routes: PluginRouteType[] = [
  {
    path: '/cart',
    name: ROUTE_CART_ROOT,
    component: () => import(/* webpackChunkName: "guidap-route-cart" */'@/views/Cart/CartView.vue'),
    children: cartRoutes
  },
  {
    path: '/customer/login',
    name: ROUTE_CUSTOMER_LOGIN,
    component: () => import(/* webpackChunkName: "guidap-route-customer-login" */'@/views/CustomerLogin/index.vue'),
    beforeEnter: async (
      to: RouteOptionsType,
      from: RouteOptionsType | null,
      next: (options?: RouteOptionsType) => void
    ): Promise<void> => {
      if (
        redirectIfCustomerAccountFeatureIsMissing(next) ||
        redirectIfCustomerIsLoggedIn(next)
      ) {
        return
      }
      next()
    }
  },
  {
    path: '/customer',
    name: ROUTE_CUSTOMER_ROOT,
    component: () => import(/* webpackChunkName: "guidap-route-customer" */'@/views/Customer/index.vue'),
    beforeEnter: async (
      to: RouteOptionsType,
      from: RouteOptionsType | null,
      next: (options?: RouteOptionsType) => void
    ): Promise<void> => {
      if (
        redirectIfCustomerAccountFeatureIsMissing(next) ||
        redirectIfCustomerIsNotLoggedIn(next, to)
      ) {
        return
      }
      next()
    },
    children: customerRoutes
  },
  {
    path: '/',
    name: 'root-default',
    component: () => import(/* webpackChunkName: "guidap-route-customer-login" */'@/views/CustomerLogin/index.vue'),
    default: true
  }
]
