import { AbstractEntryWorkflow } from '@/views/Cart/views/AddToCart/entry/abstract.entry.workflow'
import { EntryWorkflowMode, EntryWorkflowType } from '@/views/Cart/views/AddToCart/entry/enums'
import {
  DefaultExperienceFactory
} from '@/views/Cart/views/AddToCart/entry/experienceFactories/factories/default.experience.factory'
import {
  BuyGiftcardExperienceFactory
} from '@/views/Cart/views/AddToCart/entry/experienceFactories/factories/buyGiftcard.experience.factory'
import {
  redirectIfActivityIsMissing
} from '@/views/Cart/views/AddToCart/entry/guards/redirectIfActivityIsMissing'
import {
  redirectIfGiftcardIsDisabled
} from '@/views/Cart/views/AddToCart/entry/guards/redirectIfGiftcardIsDisabled'
import { FullActivity, Package } from '@/shared/types/catalog'
import {
  AbstractExperienceFactory
} from '@/views/Cart/views/AddToCart/entry/experienceFactories/abstract.experience.factory'
import { EntryWorkflowProps, EntryWorkflowState } from '@/views/Cart/views/AddToCart/entry/types'
import {
  redirectIfActivityIsNotReservable
} from '@/views/Cart/views/AddToCart/entry/guards/redirectIfActivityIsNotReservable'

export class BuyGiftcardEntryWorkflow extends AbstractEntryWorkflow {
  constructor (options: {
    activity: FullActivity,
    pack?: Package
  }) {
    super(EntryWorkflowMode.Creation, EntryWorkflowType.BuyGiftcard, options)
  }

  public static generateRouteData (options: {
    activityUuid: string,
    packUuid?: string,
    activity?: FullActivity,
    pack?: Package
  }): {
    props: EntryWorkflowProps,
    state: EntryWorkflowState
  } {
    return {
      props: {
        type: EntryWorkflowType.BuyGiftcard,
        activityUuid: options.activityUuid,
        packUuid: options.packUuid
      },
      state: {
        mode: EntryWorkflowMode.Creation,
        activity: options.activity,
        pack: options.pack
      }
    }
  }

  public static supportRoute (
    mode: EntryWorkflowMode,
    type: EntryWorkflowType
  ): boolean {
    return (
      mode === EntryWorkflowMode.Creation &&
      type === EntryWorkflowType.BuyGiftcard
    )
  }

  public validateGuards (): void {
    redirectIfActivityIsMissing(this.activity)
    redirectIfActivityIsNotReservable(this.activity)
    redirectIfGiftcardIsDisabled(this.activity, this.pack)
  }

  public getSupportedExperienceFactory (activity: FullActivity, pack?: Package): AbstractExperienceFactory {
    if (!pack) {
      return new DefaultExperienceFactory(activity)
    }
    return new BuyGiftcardExperienceFactory(activity, pack)
  }
}
