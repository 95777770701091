
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import GuidapBox from '@/components/ui/GuidapBox.vue'
import GuidapItem from '@/components/ui/GuidapItem.vue'
import GuidapButton from '@/components/ui/GuidapButton.vue'
import GuidapButtonText from '@/components/ui/GuidapButtonText.vue'
import GuidapIcon from '@/components/ui/GuidapIcon.vue'
import GuidapTooltip from '@/components/ui/GuidapTooltip.vue'
import GuidapLoading from '@/components/ui/GuidapLoading.vue'
import PackageItem from '@/views/Cart/views/AddToCart/internals/ui/fields/pack/components/PackageItem.vue'
import { FullActivity, Package } from '@/shared/types/catalog'
import { ActivitySaleMode } from '@/shared/constants/catalog'
import { AnalyticsEvent } from '@/shared/plugins/analytics/constants'
import { isFeatureEnabled } from '@/core/feature'
import { FeatureFlag } from '@/core/feature/constants'
import { openAtcWithWorkflow } from '@/views/Cart/views/AddToCart/entry'
import {
  BuyActivityEntryWorkflow
} from '@/views/Cart/views/AddToCart/entry/workflows/buyActivity.entry.workflow'
import {
  BuyGiftcardEntryWorkflow
} from '@/views/Cart/views/AddToCart/entry/workflows/buyGiftcard.entry.workflow'
import { GET_ACTIVITY } from '@/shared/store/mutations'

@Component({
  components: {
    GuidapBox,
    GuidapItem,
    GuidapButton,
    GuidapButtonText,
    GuidapIcon,
    GuidapTooltip,
    GuidapLoading,
    PackageItem
  }
})
export default class GuidapBookingWidget extends Vue {
  @Prop(String) readonly activityUuid!: string
  @Prop() readonly activity!: FullActivity

  localActivity: FullActivity | null = null

  protected get loadingGetActivity (): boolean {
    return this.$store.state.loading[GET_ACTIVITY]
  }

  private get isCartEmpty (): boolean {
    return this.$store.getters['cart/isCartEmpty']
  }

  private get isCartUsingCoupon (): boolean {
    return this.$store.getters['cart/isCartUsingCoupon']
  }

  private get currentActivity (): FullActivity {
    return this.activity || this.localActivity
  }

  private get isSubscription (): boolean {
    return this.currentActivity && this.currentActivity.saleMode === ActivitySaleMode.Subscription
  }

  private get isTicketing (): boolean {
    return this.currentActivity && this.currentActivity.saleMode === ActivitySaleMode.Ticketing
  }

  private get isActivityReservable (): boolean {
    return !!(this.currentActivity?.isActive || this.currentActivity?.isReservable)
  }

  private get isGiftcardEnabled (): boolean {
    return isFeatureEnabled(FeatureFlag.Giftcard)
  }

  mounted (): void {
    let promise = Promise.resolve()
    if (!this.activity) {
      if (!this.activityUuid) {
        throw new Error('[GUIDAP guidap-booking-widget] missing activity-uuid')
      }

      promise = this.fetchActivity(this.activityUuid)
    }

    promise.then(() => {
      if (this.currentActivity) {
        this.$analytics.trackEvent(AnalyticsEvent.ViewPackageList, {
          fullActivity: this.currentActivity,
          packs: this.currentActivity.packages
        })
      }
    })
  }

  @Watch('activityUuid')
  onPropertyChanged (_uuid: string): void {
    if (_uuid && !this.activity) {
      this.fetchActivity(_uuid)
    }
  }

  fetchActivity (uuid: string): Promise<void> {
    return this.$store.dispatch('catalog/fetchActivity', uuid)
      .then((activity: FullActivity) => {
        this.localActivity = activity
        this.$analytics.trackEvent(AnalyticsEvent.SelectActivity, {
          fullActivity: activity
        })
      })
  }

  openPackage (pack: Package): void {
    openAtcWithWorkflow('push', BuyActivityEntryWorkflow.generateRouteData({
      activityUuid: this.currentActivity.uuid,
      activity: this.currentActivity,
      packUuid: pack.uuid,
      pack
    }))
  }

  openActivity (): void {
    openAtcWithWorkflow('push', BuyActivityEntryWorkflow.generateRouteData({
      activityUuid: this.currentActivity.uuid,
      activity: this.currentActivity
    }))
  }

  openGiftcard (): void {
    openAtcWithWorkflow('push', BuyGiftcardEntryWorkflow.generateRouteData({
      activityUuid: this.currentActivity.uuid,
      activity: this.currentActivity
    }))
  }

  useGiftcard (): void {
    this.$guidap.openUseGiftcard()
  }
}
