
export enum StorageKey {
  // Cart
  OrderUuid = 'GUIDAP_order_uuid',
  OrderExpiresAt = 'GUIDAP_order_expires_at',
  OrderExperiences = 'GUIDAP_order_experiences',
  OrderGiftcard = 'GUIDAP_order_giftcard',
  OrderCustomer = 'GUIDAP_order_customer',
  OrderRecipient = 'GUIDAP_order_recipient',
  // Customer
  CustomerAccessToken = 'GUIDAP_customer_access_token',
  CustomerRefreshToken = 'GUIDAP_customer_refresh_token',
  // Payment
  PaymentInfo = 'GUIDAP_payment_info',
  PaymentSession = 'GUIDAP_payment_session',
}
