
import { Component, Mixins } from 'vue-property-decorator'
import PopupsMixin from '@/components/exports/GuidapCart/mixins/popups'
import CartFinderMixin from '@/components/exports/GuidapCart/mixins/cartFinder'
import { ExternalEvent } from '@/shared/utils/event/constants'
import { dispatchExternalEvent } from '@/shared/utils/event'

@Component({})
export default class GuidapCart extends Mixins(
  PopupsMixin,
  CartFinderMixin
) {
  mounted (): void {
    dispatchExternalEvent(ExternalEvent.Mounted)
  }
}
