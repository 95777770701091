/* eslint-disable camelcase */

// https://developers.facebook.com/docs/facebook-pixel/reference

export enum FbpContentType {
  Product = 'product',
  ProductGroup = 'product_group'
}

interface FbpPageView {
  content_name: string
  content_category: 'page'
}

export interface FbpContents {
  id: string,
  quantity: number
}

interface FbpViewContentProduct {
  content_name: string
  content_category: string
  content_type: FbpContentType.Product
  content_ids: string[]
  value?: number
  currency: string
}

interface FbpViewContentProductWithQuantity {
  content_name: string
  content_category: string
  content_type: FbpContentType.Product
  contents: FbpContents[]
  value?: number
  currency: string
}

interface FbpAddToCart {
  content_name: string
  content_type: FbpContentType.Product
  content_ids: string[]
  contents: FbpContents[]
  value: number
  currency: string
}

interface FbpInitiateCheckout {
  content_category: string
  content_ids: string[]
  contents: FbpContents[]
  num_items: number
  value: number | null
  currency: string
}

interface FbpPurchase {
  content_name: string
  content_type: FbpContentType.Product
  content_ids: string[]
  contents: FbpContents[]
  num_items: number
  value: number | null
  currency: string
}

export interface WindowFbq {
  fbq: {
    // init
    (v1: 'init', v2: string): void
    // page view
    (v1: 'track', v2: 'PageView'): void
    (v1: 'trackCustom', v2: 'VirtualPageView', v3: FbpPageView): void
    // content view
    (v1: 'track', v2: 'ViewContent', v3: FbpViewContentProduct | FbpViewContentProductWithQuantity): void
    // add to cart
    (v1: 'track', v2: 'AddToCart', v3: FbpAddToCart): void
    (v1: 'track', v2: 'Schedule'): void
    (v1: 'track', v2: 'CustomizeProduct'): void
    // checkout
    (v1: 'track', v2: 'InitiateCheckout', v3: FbpInitiateCheckout): void
    (v1: 'track', v2: 'Purchase', v3: FbpPurchase): void
  }
}
