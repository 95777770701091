import Vue, { VueConstructor } from 'vue'
import AlertBus from '@/components/alerts/eventBus'

declare module 'vue/types/vue' {
  interface Vue {
    $alert: typeof AlertBus
  }
  interface VueConstructor {
    $alert: typeof AlertBus
  }
}

const AlertPlugin = {
  install (vue: VueConstructor<Vue>): void {
    vue.$alert = vue.prototype.$alert = AlertBus
  }
}

export default AlertPlugin
