
import { Component, Prop, Vue } from 'vue-property-decorator'
import GuidapButton from '@/components/ui/GuidapButton.vue'

@Component({
  components: {
    GuidapButton
  }
})
export default class GuidapActivityDetailsButtonButton extends Vue {
  @Prop(String) readonly activityUuid!: string

  openActivityDetails (): void {
    this.$guidap.openActivityDetails(this.activityUuid)
  }
}
