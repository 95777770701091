import { Vue } from 'vue-property-decorator'
import { ROUTE_CART_ACTIVITIES } from '@/views/Cart/routes'
import { isFeatureEnabled } from '@/core/feature'
import { FeatureFlag } from '@/core/feature/constants'
import { FullActivity, Package } from '@/shared/types/catalog'
import i18n from '@/i18n'
import { openAtcWithWorkflow } from '@/views/Cart/views/AddToCart/entry'
import {
  BuyActivityEntryWorkflow
} from '@/views/Cart/views/AddToCart/entry/workflows/buyActivity.entry.workflow'

/**
 * Redirect if the giftcard coupon is missing
 * Returns true in case of redirection
 */
export function redirectIfGiftcardIsDisabled (
  activity: FullActivity,
  pack?: Package
): boolean {
  if (
    !isFeatureEnabled(FeatureFlag.Giftcard) ||
    !activity.giftCardEnabled
  ) {
    Vue.$toast.openDanger(i18n.t('error.activity-cannot-be-gifted'))
    console.error('[GUIDAP] the activity cannot be gifted')
    openAtcWithWorkflow('replace', BuyActivityEntryWorkflow.generateRouteData({
      activityUuid: activity.uuid,
      activity,
      pack
    }))
    Vue.$grouter.replace({ name: ROUTE_CART_ACTIVITIES })
    return true
  }
  return false
}
