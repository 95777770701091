import { FeatureFlag } from '@/core/feature/constants'

let flags: Partial<Record<FeatureFlag, boolean|string>> = {}

export function setFeatureFlags (_flags: Partial<Record<FeatureFlag, boolean|string>>): void {
  flags = _flags
}

export function isFeatureEnabled (flag: FeatureFlag): boolean {
  return !!flags[flag]
}

export function getFeatureContent (flag: FeatureFlag): boolean | string | null {
  const flagContent = flags[flag]
  if (typeof flagContent === 'boolean' || typeof flagContent === 'string') {
    return flagContent
  }
  return null
}
