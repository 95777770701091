import '@/assets/style'
import Vue, { ComponentOptions } from 'vue'
import VueCustomElement from 'vue-custom-element'
import store from '@/shared/store'
import { pinia } from '@/shared/stores'
import i18n from '@/i18n'
import { installListeners } from '@/shared/listeners/install'
import GuidapCart from '@/components/exports/GuidapCart/index.vue'
import GuidapBookingWidget from '@/components/exports/GuidapBookingWidget.vue'
import GuidapCartButton from '@/components/exports/GuidapCartButton.vue'
import GuidapReserveButton from '@/components/exports/GuidapReserveButton.vue'
import GuidapActivityReserveButton from '@/components/exports/GuidapActivityReserveButton.vue'
import GuidapActivityBuyGiftButton from '@/components/exports/GuidapActivityBuyGiftButton.vue'
import GuidapActivityDetailsButton from '@/components/exports/GuidapActivityDetailsButton.vue'
import GuidapUseGiftcardButton from '@/components/exports/GuidapUseGiftcardButton.vue'
import GuidapCustomerAccountButton from '@/components/exports/GuidapCustomerAccountButton/index.vue'
import { dispatchExternalEvent } from '@/shared/utils/event'
import { ExternalEvent } from '@/shared/utils/event/constants'
import { isFeatureEnabled } from '@/core/feature'
import { FeatureFlag } from '@/core/feature/constants'
import { fetchAndUseTenant } from '@/core/tenant'
import { installPlugins } from '@/shared/plugins/install'
import { installCoreSettings } from '@/core/settings/install'

Vue.config.productionTip = false

installCoreSettings()
installPlugins()
Vue.use(VueCustomElement)

installListeners()

const customElementOptions = {
  beforeCreateVueInstance (RootComponentDefinition: ComponentOptions<Vue>) {
    RootComponentDefinition.store = store
    RootComponentDefinition.pinia = pinia
    RootComponentDefinition.i18n = i18n
    return RootComponentDefinition
  }
}

dispatchExternalEvent(ExternalEvent.Init)

fetchAndUseTenant().finally(() => {
  Vue.customElement('guidap-booking-widget', () => Promise.resolve((new GuidapBookingWidget()).$options), customElementOptions)
  Vue.customElement('guidap-cart-button', () => Promise.resolve((new GuidapCartButton()).$options), customElementOptions)
  Vue.customElement('guidap-reserve-button', () => Promise.resolve((new GuidapReserveButton()).$options), customElementOptions)
  Vue.customElement('guidap-activity-reserve-button', () => Promise.resolve((new GuidapActivityReserveButton()).$options), customElementOptions)
  Vue.customElement('guidap-activity-details-button', () => Promise.resolve((new GuidapActivityDetailsButton()).$options), customElementOptions)
  if (isFeatureEnabled(FeatureFlag.Giftcard)) {
    Vue.customElement('guidap-activity-buy-gift-button', () => Promise.resolve((new GuidapActivityBuyGiftButton()).$options), customElementOptions)
    Vue.customElement('guidap-use-giftcard-button', () => Promise.resolve((new GuidapUseGiftcardButton()).$options), customElementOptions)
  }
  if (isFeatureEnabled(FeatureFlag.CartCustomerAccount)) {
    Vue.customElement('guidap-customer-account-button', () => Promise.resolve((new GuidapCustomerAccountButton()).$options), customElementOptions)
  }

  const cart = new Vue({
    store,
    pinia,
    i18n,
    render: h => h(GuidapCart)
  }).$mount()
  document.body.appendChild(cart.$el)

  // eslint-disable-next-line no-console
  console.info(
    `%c GUIDAP %c Cart version=${process.env.VUE_APP_VERSION} %c Vue version=${Vue.version} `,
    'padding: 2px 0; background: #fbbd0b; color: white',
    'padding: 2px 0; background: #1C8D93; color: white',
    'padding: 2px 0; background: #676a6c; color: white'
  )
})
