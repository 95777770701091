
import { Component, Vue } from 'vue-property-decorator'
import AlertBus from '@/components/alerts/eventBus'
import Alert from '@/components/alerts/Alert.vue'

@Component({
  components: {
    Alert
  }
})
export default class AlertRoot extends Vue {
  component = null
  props = null
  events = null

  handleClose (): void {
    this.component = null
    this.props = null
    this.events = null
  }

  created (): void {
    AlertBus.$on('open', ({ component = null, props = null, events = null }) => {
      this.component = component
      this.props = props
      this.events = events
    })
    AlertBus.$on('close', () => {
      this.handleClose()
    })
  }
}
