import Vue from 'vue'
import i18n from '@/i18n'
import { RouteOptionsType } from '@/shared/router/types'
import store from '@/shared/store'
import { ROUTE_CART_CONTENT } from '@/views/Cart/routes'

/**
 * Redirect if a subscription is already in the cart
 * Returns true in case of redirection
 */
export function redirectIfCartIsBuyingSubscription (next: (options?: RouteOptionsType) => void): boolean {
  if (store.getters['cart/isCartSubscription']) {
    Vue.$toast.openWarning(i18n.t('prevent.you-are-buying-subscription'))
    next({ name: ROUTE_CART_CONTENT })
    return true
  }
  return false
}
