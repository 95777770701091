
export enum ExternalEvent {
  Init = 'guidapcartinit',
  Mounted = 'guidapcartmounted',
  ExperienceAdded = 'guidapcartexperienceadded',
  CouponUsed = 'guidapcartcouponused',
  OrderCompleted = 'guidapcartordercompleted',
  LoggedIn = 'guidapcartloggedin',
  LoggedOut = 'guidapcartloggedout',
}
