import Vue, { VueConstructor } from 'vue'
import { SnackbarProgrammatic as Snackbar } from 'buefy'
import { ToastPluginMessageType, ToastPluginType } from '@/shared/plugins/toast/types'

declare module 'vue/types/vue' {
  interface Vue {
    $toast: ToastPluginType
  }
  interface VueConstructor {
    $toast: ToastPluginType
  }
}

const ToastPlugin = {
  install (vue: VueConstructor<Vue>): void {
    vue.$toast = vue.prototype.$toast = {
      openSuccess: (message: ToastPluginMessageType, indefinite = false) => {
        return Snackbar.open({
          message: message.toString(),
          position: 'is-top',
          type: 'is-success',
          duration: 6000,
          indefinite,
          actionText: '✖',
          queue: false
        })
      },
      openWarning: (message: ToastPluginMessageType, indefinite = false) => {
        return Snackbar.open({
          message: message.toString(),
          position: 'is-top',
          type: 'is-warning',
          duration: 10000,
          indefinite,
          actionText: '✖',
          queue: false
        })
      },
      openDanger: (message: ToastPluginMessageType, indefinite = false) => {
        return Snackbar.open({
          message: message.toString(),
          position: 'is-top',
          type: 'is-danger',
          duration: 10000,
          indefinite,
          actionText: '✖',
          queue: false
        })
      }
    }
  }
}

export default ToastPlugin
