import {
  RouteType,
  RouterSplitPathType,
  RoutesByName,
  PluginRouteType
} from '@/shared/router/types'
import { propsFromSplitPath, splitStringPath } from '@/shared/router/utils/path'

export function cleanRoutes (routes: PluginRouteType[]): RouteType[] {
  return routes.map((route) => ({
    path: route.path,
    name: route.name,
    splitPath: splitStringPath(route.path),
    component: route.component,
    props: {},
    children: route.children ? cleanRoutes(route.children) : undefined,
    beforeEnter: route.beforeEnter,
    default: route.default
  }))
}

export function findDefaultRoute (routes: RouteType[]): RouteType | null {
  const foundDefaultRoute = routes.find(route => route.default)

  return foundDefaultRoute || null
}

export function findDeepRoutes (locationSplitPath: RouterSplitPathType, routes: RouteType[]): RouteType[] | null {
  const foundRoutes: RouteType[] = []
  const splitPathToSearch = [...locationSplitPath]
  let routesToSearch: RouteType[] | null = routes

  while (routesToSearch && routesToSearch.length > 0) {
    const foundRoute = findRoute(splitPathToSearch, routesToSearch)
    routesToSearch = null

    if (foundRoute) {
      foundRoutes.push(foundRoute)
      splitPathToSearch.splice(0, foundRoute.splitPath.length)

      if (foundRoute.children) {
        routesToSearch = foundRoute.children
      }
    }
  }

  return foundRoutes
}

function findRoute (locationSplitPath: RouterSplitPathType, routes?: RouteType[]): RouteType | null {
  if (!routes) {
    return null
  }

  const _locationSplitPath = locationSplitPath.length ? locationSplitPath : ['']

  const foundRoute = routes.find(route => {
    // The config route is longer than the current location
    // config: /1/2/3
    // location: /1/2
    if (route.splitPath.length > _locationSplitPath.length) {
      return false
    }

    for (let i = 0; i < route.splitPath.length; i++) {
      const isEmptyRoute = route.splitPath[i].length === 0 // Empty route ['']
      const isRouteProp = !isEmptyRoute && route.splitPath[i].startsWith(':') // Props in route [':uuid']

      if (
        route.splitPath[i] !== _locationSplitPath[i] &&
        (isEmptyRoute || !isRouteProp)
      ) {
        return false
      }
    }

    return true
  })

  if (!foundRoute) {
    return null
  }

  foundRoute.props = propsFromSplitPath(foundRoute.splitPath, locationSplitPath)

  return foundRoute
}

export function mapRoutesByName (routes: RouteType[], accRoutes?: RouteType[]): RoutesByName {
  return routes.reduce((acc: RoutesByName, route) => {
    const currentRoutes = accRoutes
      ? accRoutes.concat(route)
      : [route]

    if (route.name) {
      acc[route.name] = currentRoutes
    }

    if (route.children) {
      Object.assign(
        acc,
        mapRoutesByName(route.children, currentRoutes)
      )
    }

    return acc
  }, {})
}
