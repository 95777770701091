import { Component, Vue } from 'vue-property-decorator'
import { isEmpty } from 'lodash-es'
import {
  CREATE_ORDER,
  GET_ORDER
} from '@/shared/store/mutations'

@Component
export default class CartFinderMixin extends Vue {
  protected get loadingCreateOrder (): boolean {
    return this.$store.state.loading[CREATE_ORDER]
  }

  protected get loadingGetOrder (): boolean {
    return this.$store.state.loading[GET_ORDER]
  }

  protected get memoryUuid (): string {
    return this.$store.state.cart.memory.uuid
  }

  protected get memoryExpiresAt (): Date | null {
    return this.$store.state.cart.memory.expiresAt
  }

  protected get orderUuid (): string {
    return this.$store.state.cart.order.uuid
  }

  mounted (): void {
    this.findCart()
  }

  findCart (): void {
    if (this.loadingCreateOrder || this.loadingGetOrder) {
      return
    }

    const filledMemoryUuid = !isEmpty(this.memoryUuid)
    const filledOrderUuid = !isEmpty(this.orderUuid)

    if (filledMemoryUuid && !filledOrderUuid) {
      this.$store.dispatch('cart/fetchOrder', this.memoryUuid)
    }
  }
}
