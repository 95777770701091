import { Vue } from 'vue-property-decorator'
import { ROUTE_CART_CONTENT } from '@/views/Cart/routes'
import { Package } from '@/shared/types/catalog'

/**
 * Redirect if the pack is missing
 * Returns true in case of redirection
 */
export function redirectIfPackIsMissing (
  pack?: Package
): pack is undefined {
  if (!pack) {
    console.error('[GUIDAP] the pack is required')
    Vue.$grouter.replace({ name: ROUTE_CART_CONTENT })
    return true
  }
  return false
}
