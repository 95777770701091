
import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

@Component({})
export default class GuidapButtonText extends Vue {
  @Prop(String) readonly href!: string
  @Prop(Boolean) readonly hrefDownload!: boolean
  @Prop(String) readonly iconLeft!: string
  @Prop(String) readonly iconRight!: string
  @Prop(Boolean) readonly back!: boolean
  @Prop(Boolean) readonly disabled!: boolean

  @Emit('click')
  click (): void { /* empty */ }
}
