
export enum OrderExperienceType {
  Booking = 'booking',
  Giftcard = 'giftcard',
  Ticketing = 'ticketing',
  Subscription = 'subscription',
  Bundle = 'bundle'
}

export enum OrderCouponType {
  Voucher = 'voucher',
  Giftcard = 'gift_card'
}

export enum OrderStatus {
  Cart = 'cart',
  Waiting = 'waiting',
  WaitingMandatory = 'waiting_mandatory',
  Accepted = 'accepted',
  Paid = 'paid',
  Canceled = 'canceled',
  Refused = 'refused'
}

export enum OrderBehavior {
  Reservation = 'reservation',
  Cart = 'cart',
  Giftcard = 'gift_card',
  Subscription = 'subscription'
}

export enum OrderEvent {
  PaymentSubmitted = 'PAYMENT_SUBMITTED'
}
