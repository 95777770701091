import i18n from '@/i18n'
import { CoreSettings } from '@/core/settings/types'
import { DEFAULT_LANGUAGE, Language } from '@/core/settings/constants'
import { setBookingModuleToken } from '@/shared/plugins/http'

/**
 * ⚠️ The "coreSettings" object is not reactive.
 */
export const coreSettings: CoreSettings = {
  token: null,
  lang: DEFAULT_LANGUAGE,
  currency: 'EUR',
  memoryRouter: false,
  hideClose: false
}

export function setTokenSetting (token: string): void {
  coreSettings.token = token
  setBookingModuleToken(token)
}

export function setLangSetting (lang: Language): void {
  const lowerCasedLang = lang.toLowerCase() as Language

  if (!Object.values(Language).includes(lowerCasedLang)) {
    const validLanguages = Object.values(Language).join(', ')
    console.warn(`[GUIDAP Settings] lang "${lowerCasedLang}" is not supported, use one of the following: [${validLanguages}]`)
  }

  coreSettings.lang = lowerCasedLang
  i18n.locale = coreSettings.lang
}

export function setCurrencySetting (currency: string): void {
  coreSettings.currency = currency
}

export function setMemoryRouterSetting (memoryRouter: boolean): void {
  coreSettings.memoryRouter = memoryRouter
}

export function setHideCloseSetting (hideClose: boolean): void {
  coreSettings.hideClose = hideClose
}

/**
 * Set all the core settings in one call
 */
export function setCoreSettings (settings: Partial<CoreSettings>): void {
  if (settings.token) {
    setTokenSetting(settings.token)
  }

  if (settings.lang) {
    setLangSetting(settings.lang)
  }

  if (settings.currency) {
    setCurrencySetting(settings.currency)
  }

  // When using <script data-memory-router />, we receive an empty string
  if (typeof settings.memoryRouter !== 'undefined') {
    setMemoryRouterSetting(true)
  }

  // When using <script data-hide-close />, we receive an empty string
  if (typeof settings.hideClose !== 'undefined') {
    setHideCloseSetting(true)
  }
}
