import { pickBy, reduce } from 'lodash-es'

export function indexRecordValueByKey<I extends Record<string, unknown>, V extends keyof I> (
  list: I[],
  key: keyof I,
  value: V
): Record<string, I[V]> {
  return reduce(
    list,
    (acc: Record<string, I[V]>, item: I) => {
      acc[`${item[key]}`] = item[value] as I[V]
      return acc
    },
    {}
  )
}

export function removeRecordEmptyValues<R extends Record<string, unknown>> (record: R): R {
  return pickBy(record) as R
}
