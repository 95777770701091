import { Order } from '@/shared/types/order'
import { DetailByEvent } from '@/shared/utils/event/types'
import { ExternalEvent } from '@/shared/utils/event/constants'

export function dispatchExternalEvent<E extends ExternalEvent> (event: E, detail?: DetailByEvent[E]): boolean {
  return document.dispatchEvent(new CustomEvent(event, { detail }))
}

// Implementations

export function dispatchOrderCompletedExternalEvent (order: Order, paid: number): void {
  dispatchExternalEvent(ExternalEvent.OrderCompleted, {
    experiences: (order.experiences || []).map(experience => ({
      activityUuid: experience?.details?.activityUuid || null,
      packUuid: experience?.details?.packageUuid || null,
      prices: (experience.prices || []).map(price => ({ uuid: price.uuid, quantity: price.quantity })),
      resources: (experience.resources || []).map(resource => ({ uuid: resource.uuid, quantity: resource.quantity }))
    })),
    products: (order.products || []).map(product => ({ uuid: product.uuid, quantity: product.quantity })),
    couponCode: order?.coupon?.code || null,
    paid
  })
}

// Logs on development env

if (process.env.NODE_ENV === 'development') {
  const evenListenerHandler = (
    (e: CustomEvent) => !e.detail
      ? console.log(`[GUIDAP Event] ${e.type}`)
      : console.log(`[GUIDAP Event] ${e.type}`, e.detail)
  ) as EventListener

  Object.values(ExternalEvent).forEach(externalEvent => {
    document.addEventListener(externalEvent, evenListenerHandler, false)
  })
}
