import Vue from 'vue'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { RootState } from '@/shared/store'
import { GET_FORM_RESERVATION_CUSTOMER } from '@/shared/store/mutations'
import { fetchForm } from '@/shared/api/form'
import { Form } from '@/shared/types/form'
import { FormName } from '@/shared/constants/form'

export class State {
  formReservationCustomer: Form[] | null = null
}

const getters = <GetterTree<State, RootState>>{
}

const mutations = <MutationTree<State>>{
  [GET_FORM_RESERVATION_CUSTOMER] (state, formReservationCustomer: Form[]) {
    Vue.set(state, 'formReservationCustomer', formReservationCustomer)
  }
}

const actions = <ActionTree<State, RootState>>{
  fetchForm (store, formKey: FormName) {
    let getFormMutation: string

    switch (formKey) {
      case FormName.ReservationCustomer:
        getFormMutation = GET_FORM_RESERVATION_CUSTOMER
        break
      default:
        return
    }

    return fetchForm(formKey, getFormMutation)
      .then(response => {
        store.commit(getFormMutation, response.data)

        switch (formKey) {
          case FormName.ReservationCustomer:
            store.dispatch('cart/cleanOrderCustomerForm', response.data, { root: true })
            break
        }
      })
  }
}

export default {
  namespaced: true,
  state: new State(),
  getters,
  mutations,
  actions
}
