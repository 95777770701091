import { AbstractEntryWorkflow } from '@/views/Cart/views/AddToCart/entry/abstract.entry.workflow'
import { EntryWorkflowMode, EntryWorkflowType } from '@/views/Cart/views/AddToCart/entry/enums'
import { OrderExperience } from '@/shared/types/order'
import {
  EditActivityExperienceFactory
} from '@/views/Cart/views/AddToCart/entry/experienceFactories/factories/editActivity.experience.factory'
import {
  redirectIfActivityIsMissing
} from '@/views/Cart/views/AddToCart/entry/guards/redirectIfActivityIsMissing'
import {
  redirectIfExperienceIsMissing
} from '@/views/Cart/views/AddToCart/entry/guards/redirectIfExperienceIsMissing'
import {
  redirectIfExperienceIsUsingDeletedConfig
} from '@/views/Cart/views/AddToCart/entry/guards/redirectIfExperienceIsUsingDeletedConfig'
import {
  redirectIfPackIsMissing
} from '@/views/Cart/views/AddToCart/entry/guards/redirectIfPackIsMissing'
import { FullActivity, Package } from '@/shared/types/catalog'
import {
  AbstractExperienceFactory
} from '@/views/Cart/views/AddToCart/entry/experienceFactories/abstract.experience.factory'
import { EntryWorkflowProps, EntryWorkflowState } from '@/views/Cart/views/AddToCart/entry/types'

export class EditActivityEntryWorkflow extends AbstractEntryWorkflow {
  declare protected readonly pack: Package
  private readonly orderExperience: OrderExperience

  constructor (options: {
    activity: FullActivity,
    pack: Package,
    orderExperience: OrderExperience
  }) {
    super(EntryWorkflowMode.Edition, EntryWorkflowType.Departure, options)
    this.orderExperience = options.orderExperience
  }

  public static generateRouteData (options: {
    activityUuid: string,
    packUuid: string,
    activity?: FullActivity,
    pack?: Package,
    orderExperience: OrderExperience
  }): {
    props: EntryWorkflowProps,
    state: EntryWorkflowState
  } {
    return {
      props: {
        type: EntryWorkflowType.Departure,
        activityUuid: options.activityUuid,
        packUuid: options.packUuid
      },
      state: {
        mode: EntryWorkflowMode.Edition,
        activity: options.activity,
        pack: options.pack,
        orderExperience: options.orderExperience
      }
    }
  }

  public static supportRoute (
    mode: EntryWorkflowMode,
    type: EntryWorkflowType
  ): boolean {
    return (
      mode === EntryWorkflowMode.Edition &&
      type === EntryWorkflowType.Departure
    )
  }

  public validateGuards (): void {
    redirectIfActivityIsMissing(this.activity)
    redirectIfPackIsMissing(this.pack)
    redirectIfExperienceIsMissing(this.orderExperience)
    redirectIfExperienceIsUsingDeletedConfig(this.activity, this.orderExperience)
  }

  public getSupportedExperienceFactory (activity: FullActivity, pack?: Package): AbstractExperienceFactory {
    if (!pack) {
      throw new Error('[GUIDAP - getSupportedExperienceFactory] Pack is required')
    }
    return new EditActivityExperienceFactory(activity, pack, this.orderExperience)
  }
}
