import {
  AbstractExperienceFactory
} from '@/views/Cart/views/AddToCart/entry/experienceFactories/abstract.experience.factory'
import {
  EntryWorkflowMode,
  EntryWorkflowType
} from '@/views/Cart/views/AddToCart/entry/enums'
import { FullActivity, Package } from '@/shared/types/catalog'
import { EntryWorkflowProps, EntryWorkflowState } from '@/views/Cart/views/AddToCart/entry/types'

/**
 * To open the ATC we need a workflow.
 * The workflow is a set of rules that define how the ATC should behave.
 */
export abstract class AbstractEntryWorkflow {
  public readonly mode: EntryWorkflowMode
  public readonly type: EntryWorkflowType
  protected readonly activity: FullActivity
  protected readonly pack?: Package

  protected constructor (mode: EntryWorkflowMode, type: EntryWorkflowType, options: {
    activity: FullActivity,
    pack?: Package
  }) {
    this.mode = mode
    this.type = type
    this.activity = options.activity
    this.pack = options.pack
  }

  /**
   * Generates the URL data for the `openAtcWithWorkflow()` function.
   */
  public static generateRouteData (options: unknown): {
    props: EntryWorkflowProps,
    state: EntryWorkflowState
  } {
    throw new Error('[GUIDAP - generateRouteData] Not implemented')
  }

  /**
   * Receives the URL data and returns whether the workflow corresponds to the route.
   */
  public static supportRoute (
    mode: EntryWorkflowMode,
    type: EntryWorkflowType
  ): boolean {
    throw new Error('[GUIDAP - supportRoute] Not implemented')
  }

  /**
   * Once the workflow is created, it must be validated to ensure that it can be used.
   */
  public abstract validateGuards (): void

  /**
   * Returns the experience factory supported by the workflow.
   * It can change depending on the activity and package used.
   */
  public abstract getSupportedExperienceFactory (activity: FullActivity, pack?: Package): AbstractExperienceFactory
}
