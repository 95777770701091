import { Store } from 'vuex'
import { RootState } from '@/shared/store'
import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { SET_LOADING } from '@/shared/store/mutations'

export function setLoadingInterceptor (axiosInstance: AxiosInstance, store: Store<RootState>): void {
  axiosInstance.interceptors.request.use(
    (config) => {
      setGlobalLoading(store, config, true)
      return config
    },
    (error) => {
      setGlobalLoading(store, error.config, false)
      return Promise.reject(error)
    }
  )

  axiosInstance.interceptors.response.use(
    (response) => {
      setGlobalLoading(store, response.config, false)
      return response
    },
    (error) => {
      setGlobalLoading(store, error.config, false)
      return Promise.reject(error)
    }
  )
}

function setGlobalLoading (store: Store<RootState>, config: AxiosRequestConfig, value: boolean): void {
  if (config?.metadata?.loading) {
    const isArray = Array.isArray(config.metadata.loading)

    if (isArray) {
      for (const mutation of config.metadata.loading) {
        store.commit(SET_LOADING, [mutation, value])
      }
      return
    }

    store.commit(SET_LOADING, [config.metadata.loading, value])
  }
}
