import { Vue } from 'vue-property-decorator'
import { FullActivity } from '@/shared/types/catalog'
import { ActivitySaleMode } from '@/shared/constants/catalog'
import store from '@/shared/store'
import i18n from '@/i18n'
import { ROUTE_CART_CONTENT } from '@/views/Cart/routes'

/**
 * Redirect if cart is filled when buying subscription
 * Returns true in case of redirection
 */
export function redirectIfCartIsFilledWhenBuyingSubscription (
  activity: FullActivity
): boolean {
  if (
    activity.saleMode === ActivitySaleMode.Subscription &&
    !store.getters['cart/isCartEmpty']
  ) {
    Vue.$toast.openWarning(i18n.t('prevent.to-buy-subscription-you-need-empty-cart'))
    Vue.$grouter.replace({ name: ROUTE_CART_CONTENT })
    return true
  }
  return false
}
