import Vue from 'vue'
import { ROUTE_CART_ADD_TO_CART } from '@/views/Cart/routes'
import { AbstractEntryWorkflow } from '@/views/Cart/views/AddToCart/entry/abstract.entry.workflow'
import {
  BuyActivityEntryWorkflow
} from '@/views/Cart/views/AddToCart/entry/workflows/buyActivity.entry.workflow'
import {
  BuyGiftcardEntryWorkflow
} from '@/views/Cart/views/AddToCart/entry/workflows/buyGiftcard.entry.workflow'
import {
  UseGiftcardEntryWorkflow
} from '@/views/Cart/views/AddToCart/entry/workflows/useGiftcard.entry.workflow'
import {
  EditActivityEntryWorkflow
} from '@/views/Cart/views/AddToCart/entry/workflows/editActivity.entry.workflow'
import {
  EditUseGiftcardEntryWorkflow
} from '@/views/Cart/views/AddToCart/entry/workflows/editUseGiftcard.entry.workflow'
import { EntryWorkflowMode, EntryWorkflowType } from '@/views/Cart/views/AddToCart/entry/enums'
import { FullActivity, Package } from '@/shared/types/catalog'
import { OrderExperience } from '@/shared/types/order'
import {
  EntryWorkflowGiftcard,
  EntryWorkflowProps,
  EntryWorkflowState
} from '@/views/Cart/views/AddToCart/entry/types'

export function openAtcWithWorkflow (routerMethod: 'push' | 'replace', options: { props: EntryWorkflowProps, state: EntryWorkflowState }): void {
  Vue.$grouter[routerMethod]({
    name: ROUTE_CART_ADD_TO_CART,
    props: options.props,
    state: options.state
  })
}

export const EntryWorkflows = [
  BuyActivityEntryWorkflow,
  BuyGiftcardEntryWorkflow,
  EditActivityEntryWorkflow,
  EditUseGiftcardEntryWorkflow,
  UseGiftcardEntryWorkflow
]

export function getEntryWorkflowFromRoute (
  mode: EntryWorkflowMode,
  type: EntryWorkflowType,
  options: {
    activity: FullActivity,
    pack?: Package,
    orderExperience?: OrderExperience,
    giftcard?: EntryWorkflowGiftcard
  }): AbstractEntryWorkflow {
  const EntryWorkflow =
    EntryWorkflows.find(EntryWorkflow => EntryWorkflow.supportRoute(mode, type)) ||
    BuyActivityEntryWorkflow

  return new EntryWorkflow(options)
}
