import Vue from 'vue'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { keyBy } from 'lodash-es'
import { RootState } from '@/shared/store'
import {
  GET_ACTIVITIES,
  GET_ACTIVITY_CATEGORIES
} from '@/shared/store/mutations'
import {
  fetchActivity,
  fetchActivities,
  fetchCategories
} from '@/shared/api/catalog'
import {
  Activity,
  ActivityCategory,
  FullActivity
} from '@/shared/types/catalog'
import { AnalyticsEvent } from '@/shared/plugins/analytics/constants'

export class State {
  activities: Activity[] | null = null
  activityCategories: ActivityCategory[] | null = null
}

const getters = <GetterTree<State, RootState>>{
  activitiesByUuid (state): Record<string, Activity> {
    return keyBy(state.activities || [], 'uuid')
  }
}

const mutations = <MutationTree<State>>{
  [GET_ACTIVITIES] (state, activities: Activity[]) {
    Vue.set(state, 'activities', activities)
  },
  [GET_ACTIVITY_CATEGORIES] (state, activityCategories: ActivityCategory[]) {
    Vue.set(state, 'activityCategories', activityCategories)
  }
}

const actions = <ActionTree<State, RootState>>{
  async fetchActivity (store, uuid: string): Promise<FullActivity> {
    return fetchActivity(uuid)
  },
  async fetchActivities (store) {
    if (
      store.state.activities !== null ||
      await store.dispatch('getLoading', GET_ACTIVITIES, { root: true })
    ) {
      return
    }

    return fetchActivities([])
      .then(response => {
        store.commit(GET_ACTIVITIES, response.data)
        if (response.data && Vue.$analytics) {
          Vue.$analytics.trackEvent(AnalyticsEvent.ViewActivityList, {
            activities: response.data
          })
        }
        return response.data
      })
  },
  async fetchCategories (store) {
    if (
      store.state.activityCategories !== null ||
      await store.dispatch('getLoading', GET_ACTIVITY_CATEGORIES, { root: true })
    ) {
      return
    }

    return fetchCategories()
      .then(response => {
        store.commit(GET_ACTIVITY_CATEGORIES, response.data)
        return response.data
      })
  }
}

export default {
  namespaced: true,
  state: new State(),
  getters,
  mutations,
  actions
}
