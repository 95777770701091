import Vue, { VueConstructor } from 'vue'
import MarkdownIt from 'markdown-it'
import MarkdownItLinkAttributes from 'markdown-it-link-attributes'
import { MarkdownPluginType } from '@/shared/plugins/markdown/types'

declare module 'vue/types/vue' {
  interface Vue {
    $markdown: MarkdownPluginType
  }
  interface VueConstructor {
    $markdown: MarkdownPluginType
  }
}

const MarkdownPlugin = {
  install (vue: VueConstructor<Vue>): void {
    const markdown = new MarkdownIt({ html: true })
    markdown.use(MarkdownItLinkAttributes, {
      attrs: {
        target: '_blank',
        rel: 'noopener'
      }
    })

    vue.$markdown = vue.prototype.$markdown = {
      toHTML: (value: string, inline?: boolean): string => {
        if (!value) {
          return ''
        }
        if (inline) {
          return markdown.renderInline(value)
        }
        return markdown.render(value)
      }
    }

    vue.mixin({
      filters: {
        toHTML: vue.$markdown.toHTML
      }
    })
  }
}

export default MarkdownPlugin
