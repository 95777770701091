import Vue, { VueConstructor } from 'vue'
import ModalBus from '@/components/modals/eventBus'

declare module 'vue/types/vue' {
  interface Vue {
    $modal: typeof ModalBus
  }
  interface VueConstructor {
    $modal: typeof ModalBus
  }
}

const ModalPlugin = {
  install (vue: VueConstructor<Vue>): void {
    vue.$modal = vue.prototype.$modal = ModalBus
  }
}

export default ModalPlugin
