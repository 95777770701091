import { AnalyticsPlugin } from 'analytics'
import {
  AnalyticsPageType,
  AnalyticsTrackType,
  AnalyticsFunctions,
  AnalyticsConfigType,
  AnalyticsInitializeType
} from '@/shared/plugins/analytics/types'
import { WindowGa } from '@/shared/plugins/analytics/impl/gua/types'
import { OrderCouponType } from '@/shared/constants/order'
import { AnalyticsEvent } from '@/shared/plugins/analytics/constants'

declare global {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  interface Window extends WindowGa {}
}

const guaImpl: AnalyticsFunctions = {
  [AnalyticsEvent.ViewActivityList] (): void { /* empty */ },

  [AnalyticsEvent.SelectActivity] ({ fullActivity }): void {
    window.ga('ec:addImpression', {
      id: fullActivity.uuid,
      name: fullActivity.name,
      list: 'activity',
      category: '' // TODO: Activity category
    })
    window.ga('send', 'pageview')
  },

  [AnalyticsEvent.ViewPackageList] (): void { /* empty */ },

  [AnalyticsEvent.SelectPackage] ({ pack, fullActivity }): void {
    window.ga('ec:addImpression', {
      id: pack.uuid,
      name: pack.name,
      list: 'package',
      category: fullActivity.name
    })
    window.ga('send', 'pageview')
  },

  [AnalyticsEvent.ViewPriceList] (): void { /* empty */ },

  [AnalyticsEvent.SelectPrice] (): void { /* empty */ },

  [AnalyticsEvent.ViewAddonList] (): void { /* empty */ },

  [AnalyticsEvent.SelectAddon] (): void { /* empty */ },

  [AnalyticsEvent.AddToCart] ({ experienceBody, activity, pack }): void {
    let total = 0
    experienceBody.prices.forEach(experiencePrice => {
      const foundPrice = pack.prices.find(packPrice => packPrice.uuid === experiencePrice.uuid)
      if (!foundPrice) {
        return
      }
      window.ga('ec:addProduct', {
        id: experiencePrice.uuid,
        name: foundPrice.name,
        category: pack.name,
        variant: 'slot' in experienceBody ? experienceBody.slot : undefined,
        price: foundPrice.amount,
        quantity: experiencePrice.quantity
      })
      total += foundPrice.amount * experiencePrice.quantity
    })
    window.ga('ec:addProduct', {
      id: pack.uuid,
      name: pack.name,
      category: activity.name,
      variant: 'slot' in experienceBody ? experienceBody.slot : undefined,
      price: total,
      quantity: 1
    })
    window.ga('ec:setAction', 'add')
    window.ga('send', 'event', 'UX', 'click', 'add to cart')
  },

  [AnalyticsEvent.RemoveExperienceFromCart] (): void { /* empty */ },

  [AnalyticsEvent.RemoveAddonFromCart] (): void { /* empty */ },

  [AnalyticsEvent.ViewCart] (): void { /* empty */ },

  [AnalyticsEvent.BeginCheckout] (): void { /* empty */ },

  [AnalyticsEvent.AddPaymentInfo] (): void { /* empty */ },

  [AnalyticsEvent.OrderCompleted] ({ order, paid }): void {
    window.ga('ec:setAction', 'purchase', {
      id: order.reference,
      affiliation: 'GUIDAP',
      revenue: paid,
      // tax,
      coupon: order.coupon && order.coupon.type === OrderCouponType.Voucher ? order.coupon.code : null
    })
    window.ga('send', 'pageview')
  }
}

/**
 * Google Universal Analytics
 * DEPRECATED: will drop 01 July 2023
 * https://support.google.com/analytics/answer/11583528
 */
export default function GUA (config: AnalyticsConfigType): AnalyticsPlugin {
  return {
    name: 'guidap-gua',
    config,

    initialize: ({ config }: AnalyticsInitializeType) => {
      if (window.ga) {
        if (process.env.NODE_ENV === 'development') {
          console.log('[GUIDAP Analytics GUA] running')
        }
        window.ga('require', 'ec')
        window.ga('set', 'currencyCode', config.currency)
      }
    },
    page: ({ payload }: AnalyticsPageType) => {
      window.ga('send', {
        hitType: 'pageview',
        title: payload.properties.title
      })
    },
    track: ({ config, payload }: AnalyticsTrackType) => {
      if (guaImpl[payload.event]) {
        try {
          guaImpl[payload.event](payload.properties, config)
        } catch (e) {
          console.warn(`[GUIDAP Analytics GUA] Error on ${payload.event}`, payload.properties)
        }
      }
    },
    identify: () => { /* empty */ },
    loaded: () => {
      return !!window.ga
    }
  }
}
