import { Component, Vue } from 'vue-property-decorator'
import { v4 as uuidv4 } from 'uuid'
import ModalRoot from '@/components/modals/ModalRoot.vue'
import AlertRoot from '@/components/alerts/AlertRoot.vue'

@Component
export default class PopupsMixin extends Vue {
  mounted (): void {
    if (document.body && !document.getElementById('guidap-popups')) {
      const elementPopups = document.createElement('div')
      elementPopups.id = 'guidap-popups'
      document.body.appendChild(elementPopups)

      const elementModal = document.createElement('div')
      const modalId = `guidap-modal-${uuidv4()}`
      elementModal.id = modalId
      elementPopups.appendChild(elementModal)
      const modalRoot = new ModalRoot({ parent: this, el: `#${modalId}` })
      modalRoot.$mount()

      const elementAlert = document.createElement('div')
      const alertId = `guidap-alert-${uuidv4()}`
      elementAlert.id = alertId
      elementPopups.appendChild(elementAlert)
      const alertRoot = new AlertRoot({ parent: this, el: `#${alertId}` })
      alertRoot.$mount()
    }
  }
}
