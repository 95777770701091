
import { Component, Prop, Vue } from 'vue-property-decorator'
import GuidapButton from '@/components/ui/GuidapButton.vue'

@Component({
  components: {
    GuidapButton
  }
})
export default class GuidapActivityBuyGiftButton extends Vue {
  @Prop(String) readonly activityUuid!: string

  openActivityBuyGift (): void {
    this.$guidap.openActivityBuyGift(this.activityUuid)
  }
}
