import Vue from 'vue'
import qsParse from 'qs/lib/parse'
import { debounce, get } from 'lodash-es'
import store from '@/shared/store'
import { ModalEvent } from '@/components/modals/constants'
import {
  BLACKLISTED_ENTRY_POINTS,
  BLACKLISTED_FUNCTIONS,
  BLACKLISTED_FUNCTIONS_WHEN_ROUTE_ACTIVE
} from '@/shared/plugins/urlParams/constants'
import { ExternalEvent } from '@/shared/utils/event/constants'

let searchParams: Record<string, unknown>

// Cart mounted

function useFunction (func: string, params?: unknown[] | null, usingRouter = false): void {
  if (
    BLACKLISTED_FUNCTIONS.includes(func) ||
    BLACKLISTED_ENTRY_POINTS.includes(func.split('.')[0])
  ) {
    // Some functions cannot be used by the URL query params
    throw new Error('The given function is not supported.')
  } else if (usingRouter && BLACKLISTED_FUNCTIONS_WHEN_ROUTE_ACTIVE.includes(func)) {
    // Some functions are blocked when the router is active
    console.warn(`[GUIDAP warning]: The parameter "${func}" is ignored when using "g-path"`)
    return
  }

  if (params) {
    get(window.GUIDAP, func)(...params)
  } else {
    get(window.GUIDAP, func)()
  }
}

function useFunctionList (funcList: string[], paramsList?: unknown[][], usingRouter = false): void {
  // Open the right page depending on the url params
  if (!Array.isArray(funcList)) {
    return
  }

  funcList.forEach((func, index) => {
    let params = (Array.isArray(paramsList) && paramsList[index])
      ? paramsList[index] || null
      : null
    try {
      if (params) {
        params = JSON.parse(`${params}`) as unknown[]
      }
      useFunction(func, params, usingRouter)
    } catch (e) {
      console.error(`[GUIDAP Error]: Something went wrong with the following url parameter "${func}"=>"${params}"\n`, e)
    }
  })
}

const removeAuthQueryParams = debounce(() => {
  Vue.$grouter.removeQueryParams(['g-auth-code', 'g-email-code-verifier'])
}, 50)

function connectByEmailCode (): Promise<void> {
  const authCode = searchParams['g-auth-code'] as string
  const emailCodeVerifier = searchParams['g-email-code-verifier'] as string

  if (authCode && emailCodeVerifier) {
    return store.dispatch('auth/postCustomerAuthToken', {
      authCode: authCode,
      emailCodeVerifier: emailCodeVerifier
    })
      .finally(() => {
        removeAuthQueryParams()
      })
  }

  return Promise.resolve()
}

async function onCartMounted (): Promise<void> {
  // In case of customer connection by email, try to log in

  try {
    await connectByEmailCode()
  } catch (e) {
    // The Promise we use does not catch the error before the finally, thus we need to put this try/catch
    console.error(e)
  }

  // On page load open the modal in case a route is present in the URL

  const usingRouter = !!searchParams[Vue.$grouter.queryParam]

  if (usingRouter) {
    Vue.$modal.$emit(ModalEvent.Open, { component: () => import(/* webpackChunkName: "guidap-modal-navigation" */'@/components/modals/impl/NavigationModal.vue') })
  }

  // Functions in the URL to use

  const funcList = searchParams['g-fn'] as string[]
  const paramsList = searchParams['g-params'] as unknown[][]

  if (funcList) {
    useFunctionList(funcList, paramsList, usingRouter)
  }
}

const UrlParamsPlugin = {
  install (): void {
    searchParams = qsParse(window.location.search, { ignoreQueryPrefix: true, allowSparse: true })

    // Launch functions on cart mount
    // To test: ?g-fn[0]=openActivities&g-fn[1]=setLanguage&g-params[1]=["en"]&g-fn[2]=setCustomer&g-params[2]=[{"firstname":"Denis"}]

    document.addEventListener(
      ExternalEvent.Mounted,
      onCartMounted,
      false
    )
  },

  removeEventListener: (): void => {
    document.removeEventListener(
      ExternalEvent.Mounted,
      onCartMounted
    )
  }
}

export default UrlParamsPlugin
