import Vue from 'vue'
import { AxiosPromise, CancelTokenSource } from 'axios'
import {
  CustomerAuthCodeRequest,
  CustomerAuthTokenWithRefreshTokenRequest,
  CustomerAuthTokenWithCodeVerifierRequest,
  CustomerAuthTokenWithEmailCodeVerifierRequest
} from '@/shared/api/auth/types'
import { CustomerAuthToken } from '@/shared/types/auth'
import { CustomerInfo } from '@/shared/types/customer'
import { GET_CUSTOMER_AUTH_CODE, GET_CUSTOMER_AUTH_TOKEN, GET_CUSTOMER_AUTH_INFO } from '@/shared/store/mutations'

export function postCustomerAuthCode (
  body: CustomerAuthCodeRequest,
  request: CancelTokenSource | null = null
): AxiosPromise<void> {
  return Vue.$http.post(
    '/customer/auth/code',
    body,
    {
      cancelToken: request?.token,
      metadata: { loading: GET_CUSTOMER_AUTH_CODE }
    }
  )
}

export function postCustomerAuthToken (
  body: CustomerAuthTokenWithRefreshTokenRequest | CustomerAuthTokenWithCodeVerifierRequest | CustomerAuthTokenWithEmailCodeVerifierRequest,
  request: CancelTokenSource | null = null
): AxiosPromise<CustomerAuthToken> {
  return Vue.$http.post(
    '/customer/auth/token',
    body,
    {
      cancelToken: request?.token,
      metadata: { loading: GET_CUSTOMER_AUTH_TOKEN }
    }
  )
}

export function getCustomerAuthInfo (
  request: CancelTokenSource | null = null
): AxiosPromise<CustomerInfo> {
  return Vue.$httpAuth.get(
    '/customer/auth/me',
    {
      cancelToken: request?.token,
      metadata: { loading: GET_CUSTOMER_AUTH_INFO }
    }
  )
}
