import Vue, { VueConstructor } from 'vue'
import { Store } from 'vuex'
import { RootState } from '@/shared/store'
import { GuidapPluginType, WindowGuidapType } from '@/shared/plugins/guidap/types'
import { installGuidapInWindow } from '@/shared/plugins/guidap/utils/installWindow'
import { installGuidapInVue } from '@/shared/plugins/guidap/utils/installPlugin'

declare global {
  interface Window {
    GUIDAP: WindowGuidapType
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $guidap: GuidapPluginType
  }
  interface VueConstructor {
    $guidap: GuidapPluginType
  }
}

const GuidapPlugin = {
  install (vue: VueConstructor<Vue>, store: Store<RootState>): void {
    installGuidapInVue(vue, store)
    installGuidapInWindow(vue, store)
  }
}

export default GuidapPlugin
