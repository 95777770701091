import Vue, { VueConstructor } from 'vue'
import { isEmpty } from 'lodash-es'
import storage from 'local-storage-fallback'
import { getStorageKey, initStorage } from '@/shared/plugins/storage/utils'
import { StoragePluginType } from '@/shared/plugins/storage/types'
import { StorageKey } from '@/shared/plugins/storage/constants'

declare module 'vue/types/vue' {
  interface Vue {
    $storage: StoragePluginType
  }
  interface VueConstructor {
    $storage: StoragePluginType
  }
}

const StoragePlugin = {
  install (vue: VueConstructor<Vue>): void {
    vue.$storage = vue.prototype.$storage = {
      get: <T = unknown>(key: StorageKey): T | null => {
        const item = storage.getItem(getStorageKey(key))
        if (!item) {
          return null
        }

        try {
          const parsedItem = JSON.parse(item)
          if (
            !parsedItem ||
            isEmpty(parsedItem) ||
            parsedItem === 'null' ||
            parsedItem === 'undefined'
          ) {
            return null
          }

          return parsedItem
        } catch (e) {
          console.error(e)
        }

        return null
      },
      set: <T = unknown>(key: StorageKey, value: T): void => {
        storage.setItem(getStorageKey(key), JSON.stringify(value || null))
      },
      remove: (key: StorageKey): void => {
        storage.removeItem(getStorageKey(key))
      },
      clear: (): void => {
        storage.clear()
      }
    }

    initStorage()
  }
}

export default StoragePlugin
