import { AnalyticsPlugin } from 'analytics'
import {
  AnalyticsPageType,
  AnalyticsTrackType,
  AnalyticsFunctions,
  AnalyticsConfigType
} from '@/shared/plugins/analytics/types'
import { WindowFbq, FbpContents, FbpContentType } from '@/shared/plugins/analytics/impl/fbp/types'
import { AnalyticsEvent } from '@/shared/plugins/analytics/constants'

declare global {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  interface Window extends WindowFbq {}
}

const fbpImpl: AnalyticsFunctions = {
  [AnalyticsEvent.ViewActivityList] (): void { /* empty */ },

  [AnalyticsEvent.SelectActivity] ({ fullActivity }, options): void {
    window.fbq('track', 'ViewContent', {
      content_name: fullActivity.name,
      content_category: 'activity',
      content_type: FbpContentType.Product,
      content_ids: [fullActivity.uuid],
      currency: options.currency
    })
  },

  [AnalyticsEvent.ViewPackageList] (): void { /* empty */ },

  [AnalyticsEvent.SelectPackage] ({ pack }, options): void {
    window.fbq('track', 'ViewContent', {
      content_name: pack.name,
      content_category: 'package',
      content_type: FbpContentType.Product,
      content_ids: [pack.uuid],
      currency: options.currency
    })
  },

  [AnalyticsEvent.ViewPriceList] (): void { /* empty */ },

  [AnalyticsEvent.SelectPrice] ({ price, quantity }, options): void {
    window.fbq('track', 'ViewContent', {
      content_name: price.name,
      content_category: 'price',
      content_type: FbpContentType.Product,
      contents: [{ id: price.uuid, quantity }],
      currency: options.currency
    })
  },

  [AnalyticsEvent.ViewAddonList] (): void { /* empty */ },

  [AnalyticsEvent.SelectAddon] ({ addon, quantity }, options): void {
    window.fbq('track', 'ViewContent', {
      content_name: addon.name,
      content_category: 'addon',
      content_type: FbpContentType.Product,
      contents: [{ id: addon.uuid, quantity }],
      currency: options.currency
    })
  },

  [AnalyticsEvent.AddToCart] ({ experienceBody, pack }, options): void {
    let total = 0
    const contentIds = [experienceBody.package]
    const contents: FbpContents[] = [{ id: experienceBody.package, quantity: 1 }]

    experienceBody.prices.forEach(experiencePrice => {
      const foundPrice = pack.prices.find(packPrice => packPrice.uuid === experiencePrice.uuid)
      if (!foundPrice) {
        return
      }
      contentIds.push(experiencePrice.uuid)
      contents.push({ id: experiencePrice.uuid, quantity: experiencePrice.quantity })
      total += foundPrice.amount * experiencePrice.quantity
    })

    window.fbq('track', 'AddToCart', {
      content_name: pack.name,
      content_type: FbpContentType.Product,
      content_ids: contentIds,
      contents,
      value: total,
      currency: options.currency
    })
    if ('slot' in experienceBody && experienceBody.slot) {
      window.fbq('track', 'Schedule')
    }
    if ('resources' in experienceBody && experienceBody.resources.length > 0) {
      window.fbq('track', 'CustomizeProduct')
    }
  },

  [AnalyticsEvent.RemoveExperienceFromCart] (): void { /* empty */ },

  [AnalyticsEvent.RemoveAddonFromCart] (): void { /* empty */ },

  [AnalyticsEvent.ViewCart] (): void { /* empty */ },

  [AnalyticsEvent.BeginCheckout] ({ order }, options): void {
    const contentIds: string[] = []
    const contents: FbpContents[] = []

    order.experiences.forEach(experience => {
      experience.prices.forEach(experiencePrice => {
        contentIds.push(experiencePrice.uuid)
        contents.push({ id: experiencePrice.uuid, quantity: experiencePrice.quantity })
      })
    })

    window.fbq('track', 'InitiateCheckout', {
      content_category: 'price',
      content_ids: contentIds,
      contents,
      num_items: contents.length,
      value: order.leftToPay,
      currency: options.currency
    })
  },

  [AnalyticsEvent.AddPaymentInfo] (): void { /* empty */ },

  [AnalyticsEvent.OrderCompleted] ({ order, paid }, options): void {
    const contentNames: string[] = []
    const contentIds: string[] = []
    const contents: FbpContents[] = []

    order.experiences.forEach(experience => {
      contentNames.push(experience.details.packageName)
      experience.prices.forEach(experiencePrice => {
        contentIds.push(experiencePrice.uuid)
        contents.push({ id: experiencePrice.uuid, quantity: experiencePrice.quantity })
      })
    })

    window.fbq('track', 'Purchase', {
      content_name: contentNames.join(' | '),
      content_type: FbpContentType.Product,
      content_ids: contentIds,
      contents,
      num_items: contents.length,
      value: paid,
      currency: options.currency
    })
  }
}

/**
 * Facebook Pixel
 * https://developers.facebook.com/docs/meta-pixel/get-started
 * https://developers.facebook.com/docs/meta-pixel/reference
 */
export default function FBP (config: AnalyticsConfigType): AnalyticsPlugin {
  return {
    name: 'guidap-fbp',
    config,

    initialize: () => {
      if (!window.fbq) {
        return
      }
      if (process.env.NODE_ENV === 'development') {
        console.log('[GUIDAP Analytics FBP] running')
      }
    },
    page: ({ payload }: AnalyticsPageType) => {
      window.fbq('track', 'PageView')
      window.fbq('trackCustom', 'VirtualPageView', { content_name: payload.properties.title, content_category: 'page' })
    },
    track: ({ config, payload }: AnalyticsTrackType) => {
      if (fbpImpl[payload.event]) {
        try {
          fbpImpl[payload.event](payload.properties, config)
        } catch (e) {
          console.warn(`[GUIDAP Analytics FBP] Error on ${payload.event}`, payload.properties)
        }
      }
    },
    identify: () => { /* empty */ },
    loaded: () => {
      return !!window.fbq
    }
  }
}
