import Vue from 'vue'
import { RoutePropsType, RouterSplitPathType, RouteType } from '@/shared/router/types'

export function splitStringPath (path: string): RouterSplitPathType {
  // Turns a path "/activity/uuid/" into ['activity', 'uuid']

  const decodedPath = decodeURI(path || '')
  const splitPath = decodedPath.split('/').filter(subPath => !!subPath)

  return splitPath.length ? splitPath : ['']
}

export function propsFromSplitPath (routeSplitPath: RouterSplitPathType, locationSplitPath: RouterSplitPathType): Record<string, string> {
  const props: Record<string, string> = {}

  if (
    !routeSplitPath ||
    !locationSplitPath ||
    routeSplitPath.length > locationSplitPath.length
  ) {
    return props
  }

  for (let i = 0; i < routeSplitPath.length; i++) {
    if (routeSplitPath[i].length >= 2 && routeSplitPath[i].charAt(0) === ':') {
      props[routeSplitPath[i].substring(1)] = locationSplitPath[i]
    }
  }

  return props
}

export function routesToPath (routes: RouteType[], props: RoutePropsType = {}, missingPropDefaultValue = Vue.$grouter.missingPropDefaultValue): string {
  return routes.reduce(
    (acc: string, route: RouteType) =>
      acc +
      route.splitPath.reduce(
        (innerAcc: string, innerPath: string) => {
          if (innerPath.startsWith(':')) {
            return innerAcc + '/' + (props[innerPath.substring(1)] || missingPropDefaultValue)
          }

          return innerAcc + '/' + innerPath
        },
        ''
      ),
    ''
  )
}
