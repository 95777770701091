import { initGUIDAPInitConfigWithUrlParams } from '@/shared/plugins/urlParams/utils'
import { setCoreSettings } from '@/core/settings'

/**
This file needs to be imported first to initialize coreSettings ASAP
In production env, the GUIDAPInitConfig object is configured inside /builder/entryPoints/index.ejs
 */
export function installCoreSettings (): void {
  // Local development
  if (process.env.NODE_ENV === 'development') {
    window.GUIDAPInitConfig = {
      token: process.env.VUE_APP_DEMO_AUTHORIZATION
    }
  }

  // Iframe support
  initGUIDAPInitConfigWithUrlParams()

  // Library export
  if (!window || !window.GUIDAPInitConfig || !window.GUIDAPInitConfig.token) {
    throw new Error('[GUIDAP Error]: No token provided in configuration')
  }

  // Init the coreSettings object
  setCoreSettings(window.GUIDAPInitConfig)
}
