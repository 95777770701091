import Vue from 'vue'
import { AxiosPromise, CancelTokenSource } from 'axios'
import { AvailabilityRequest } from '@/shared/api/catalog/types'
import {
  GET_ACTIVITIES,
  GET_ACTIVITY,
  GET_RESOURCES,
  GET_ACTIVITY_CATEGORIES,
  GET_CLOSED_DAYS,
  GET_AVAILABILITIES,
  GET_ADDONS
} from '@/shared/store/mutations'
import {
  ActivityCategory,
  Activity,
  Addon,
  FullActivity,
  Resource,
  Slot
} from '@/shared/types/catalog'
import { coreSettings } from '@/core/settings'

// TODO: One of these days, we should probably move this into a reusable util or decorator
const cachedFullActivityByUuid: Record<string, FullActivity | Promise<FullActivity>> = {}

export async function fetchActivity (
  uuid: string,
  request: CancelTokenSource | null = null
): Promise<FullActivity> {
  if (cachedFullActivityByUuid[uuid] !== undefined) {
    return cachedFullActivityByUuid[uuid]
  }
  cachedFullActivityByUuid[uuid] = Vue.$http
    .get<FullActivity>(
      `/public/catalog/activities/${uuid}`,
      {
        params: { language: coreSettings.lang },
        cancelToken: request?.token,
        metadata: { loading: GET_ACTIVITY }
      }
    )
    .then(response => {
      cachedFullActivityByUuid[response.data.uuid] = response.data
      return response.data
    })
  return cachedFullActivityByUuid[uuid]
}

export function fetchActivities (
  categories: string[],
  request: CancelTokenSource | null = null
): AxiosPromise<Activity[]> {
  return Vue.$http.get(
    '/public/catalog/activities',
    {
      params: {
        language: coreSettings.lang,
        categories: categories.length ? categories.join(',') : undefined
      },
      cancelToken: request?.token,
      metadata: { loading: GET_ACTIVITIES }
    }
  )
}

export function fetchCategories (
  request: CancelTokenSource | null = null
): AxiosPromise<ActivityCategory[]> {
  return Vue.$http.get(
    '/public/catalog/categories',
    {
      params: { language: coreSettings.lang },
      cancelToken: request?.token,
      metadata: { loading: GET_ACTIVITY_CATEGORIES }
    }
  )
}

const cachedResourcesByPriceUuid: Record<string, Resource[]> = {}

export async function fetchResources (
  priceUuid: string,
  request: CancelTokenSource | null = null
): Promise<Resource[]> {
  if (cachedResourcesByPriceUuid[priceUuid]) {
    return Promise.resolve(cachedResourcesByPriceUuid[priceUuid])
  }
  return Vue.$http
    .get<Resource[]>(
      `/public/catalog/prices/${priceUuid}/resources`,
      {
        params: { language: coreSettings.lang },
        cancelToken: request?.token,
        metadata: { loading: GET_RESOURCES }
      }
    )
    .then(response => {
      cachedResourcesByPriceUuid[priceUuid] = response.data
      return response.data
    })
}

export function fetchAddons (
  prices: string[] | null,
  request: CancelTokenSource | null = null
): AxiosPromise<Addon[]> {
  return Vue.$http.get(
    '/public/catalog/addons',
    {
      params: {
        prices,
        language: coreSettings.lang
      },
      cancelToken: request?.token,
      metadata: { loading: GET_ADDONS }
    }
  )
}

export function fetchClosedDays (
  packUuid: string,
  month: string,
  request: CancelTokenSource | null = null
): AxiosPromise<string[]> {
  return Vue.$http.get(
    '/public/catalog/closeddays',
    {
      params: { package: packUuid, month, offset: 0 },
      cancelToken: request?.token,
      metadata: { loading: GET_CLOSED_DAYS }
    }
  )
}

export function fetchAvailabilities (
  body: AvailabilityRequest[],
  request: CancelTokenSource | null = null
): AxiosPromise<Slot[]> {
  return Vue.$http.post(
    '/public/catalog/availabilities',
    body,
    {
      params: {
        groups: ['extended']
      },
      cancelToken: request?.token,
      metadata: { loading: GET_AVAILABILITIES }
    }
  )
}
