import Vue from 'vue'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/shared/store'
import { SET_IS_FIRST_PAGE } from '@/shared/store/mutations'
import { coreSettings } from '@/core/settings'

export class State {
  isFirstPage = false
}

const getters = <GetterTree<State, RootState>>{
  hideCloseButton (state): boolean {
    return coreSettings.hideClose && state.isFirstPage
  }
}

const mutations = <MutationTree<State>>{
  [SET_IS_FIRST_PAGE] (state, isFirstPage: boolean) {
    if (state.isFirstPage === isFirstPage) {
      return
    }
    Vue.set(state, 'isFirstPage', isFirstPage)
  }
}

const actions = <ActionTree<State, RootState>>{
}

export default {
  namespaced: true,
  state: new State(),
  getters,
  mutations,
  actions
}
