
export enum CurrencyIso {
  EUR = 'EUR',
  CHF = 'CHF',
  GBP = 'GBP',
  USD = 'USD'
}

export const CURRENCIES: Map<string, string> = new Map([
  [CurrencyIso.EUR, '€'],
  [CurrencyIso.CHF, 'CHF'],
  [CurrencyIso.GBP, '£'],
  [CurrencyIso.USD, '$']
])

export const DEFAULT_CURRENCY_DIGITS = 2
