import { PluginRouteType } from '@/shared/router/types'

export const ROUTE_CUSTOMER_ORDERS = 'customer-orders'
export const ROUTE_CUSTOMER_SUBSCRIBE = 'customer-subscription'
export const ROUTE_CUSTOMER_INVOICES = 'customer-invoices'
export const ROUTE_CUSTOMER_PAYMENT_METHODS = 'customer-payment-methods'
export const ROUTE_CUSTOMER_PROFILE = 'customer-profile'

export const routes: PluginRouteType[] = [
  {
    path: '/orders/:tab',
    name: ROUTE_CUSTOMER_ORDERS,
    component: () => import(/* webpackChunkName: "guidap-route-customer-orders" */'@/views/Customer/views/CustomerOrders/CustomerOrdersView.vue')
  },
  {
    path: '/subscription/:uuid',
    name: ROUTE_CUSTOMER_SUBSCRIBE,
    component: () => import(/* webpackChunkName: "guidap-route-customer-subscription" */'@/views/Customer/views/CustomerSubscription/index.vue')
  },
  {
    path: '/invoices',
    name: ROUTE_CUSTOMER_INVOICES,
    component: () => import(/* webpackChunkName: "guidap-route-customer-invoices" */'@/views/Customer/views/CustomerInvoices/index.vue')
  },
  {
    path: '/payment-methods',
    name: ROUTE_CUSTOMER_PAYMENT_METHODS,
    component: () => import(/* webpackChunkName: "guidap-route-customer-payment-methods" */'@/views/Customer/views/CustomerPaymentMethods/index.vue')
  },
  {
    path: '/profile/:tab',
    name: ROUTE_CUSTOMER_PROFILE,
    component: () => import(/* webpackChunkName: "guidap-route-customer-profile" */'@/views/Customer/views/CustomerProfile/CustomerProfileView.vue')
  },
  {
    path: '/',
    name: 'customer-default',
    component: () => import(/* webpackChunkName: "guidap-route-customer-orders" */'@/views/Customer/views/CustomerOrders/CustomerOrdersView.vue')
  }
]
