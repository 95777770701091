import { AxiosError, AxiosInstance } from 'axios'
import Vue, { VueConstructor } from 'vue'
import i18n from '@/i18n'
import store from '@/shared/store'
import { GuidapApiError, GuidapApiLegacyError } from '@/shared/api/types'
import { ERROR_CODE_REQUIRING_CONTEXT } from '@/shared/plugins/http/constants'

export function setErrorInterceptor (axiosInstance: AxiosInstance, vue: VueConstructor<Vue>): void {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error: AxiosError<GuidapApiError & GuidapApiLegacyError>) => {
      const errorCode = error?.response?.data?.code ||
        error?.response?.data?.error?.errorCode

      if (
        errorCode &&
        i18n.te(`api-error.${errorCode.toLowerCase()}`) // $te checks if the key exists
      ) {
        vue.$toast.openDanger(getErrorMessage(errorCode))
      }

      return Promise.reject(error)
    }
  )
}

function getErrorMessage (errorCode: string): string {
  const context: Record<string, unknown> = {}

  if (ERROR_CODE_REQUIRING_CONTEXT[errorCode]) {
    switch (errorCode) {
      case ERROR_CODE_REQUIRING_CONTEXT.CUSTOMER_ALREADY_HAS_SUBSCRIPTION:
        context.email = store.state.cart.customer.email
        break
    }
  }

  return i18n.t(`api-error.${errorCode.toLowerCase()}`, context)
}
