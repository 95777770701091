import { differenceInMonths, isFuture, Locale, format } from 'date-fns'
import { enGB, fr, es, nl, de, it, pt, ca } from 'date-fns/locale'
import { formatInTimeZone, OptionsWithTZ } from 'date-fns-tz'
import { coreSettings } from '@/core/settings'
import { Language } from '@/core/settings/constants'
import { FormatOptions } from '@/shared/utils/date/types'

const locales: Record<Language, Locale> = {
  [Language.En]: enGB,
  [Language.Fr]: fr,
  [Language.Es]: es,
  [Language.Nl]: nl,
  [Language.De]: de,
  [Language.It]: it,
  [Language.Pt]: pt,
  [Language.Ca]: ca
}
const backendTimeZone: string = process.env.VUE_APP_BACKEND_TIME_ZONE

function getDateFnsFormatOptions (): OptionsWithTZ {
  return {
    locale: locales[coreSettings.lang] || fr
  }
}

function formatWithOrWithoutTmz (date: Date, formatStr: string, options?: FormatOptions): string {
  if (options?.withTimezone) {
    return formatInTimeZone(date, backendTimeZone, formatStr, getDateFnsFormatOptions())
  }
  return format(date, formatStr, getDateFnsFormatOptions())
}

// Format to String
// https://date-fns.org/v2.28.0/docs/format

/**
 * @returns 29/07/2022
 */
export function formatDateToReadableShort (date: Date, options?: FormatOptions): string {
  return formatWithOrWithoutTmz(date, 'P', options)
}

/**
 * @returns 29 juillet 2022
 */
export function formatDateToReadableMedium (date: Date, options?: FormatOptions): string {
  return formatWithOrWithoutTmz(date, 'PPP', options)
}

/**
 * @returns vendredi 29 juillet 2022
 */
export function formatDateToReadableLong (date: Date, options?: FormatOptions): string {
  return formatWithOrWithoutTmz(date, 'PPPP', options)
}

/**
 * @returns 13:00
 */
export function formatTimeToReadable (date: Date, options?: FormatOptions): string {
  return formatWithOrWithoutTmz(date, 'HH:mm', options)
}

/**
 * @returns 2022-07-29
 */
export function formatDateToISO (date: Date, options?: FormatOptions): string {
  return formatWithOrWithoutTmz(date, 'yyyy-MM-dd', options)
}

// Expiration check (our timestamp are in seconds)

export function isTimestampExpired (timestamp: number | null): boolean {
  return !!timestamp && timestampDifference(timestamp) < 0
}

export function timestampDifference (timestamp: number): number {
  return dateWithoutTimezone(
    new Date(parseInt(`${timestamp}`) * 1000)
  ).getTime() - Date.now()
}

export function isDateExpired (date: Date | null): boolean {
  if (!date) {
    return true
  }
  return !isFuture(date)
}

export function differenceInMonthsFromNow (date: Date | null): number {
  if (!date) {
    return 0
  }
  return differenceInMonths(date, new Date())
}

// Utils

export function getReadableTimeFromString (dateStr: string): string {
  return dateStr.split('T')[1].slice(0, 5)
}

export function createDateFromString (dateStr: string): Date {
  const splitDate = dateStr.slice(0, 10).split('-').map((str) => parseInt(str))
  return new Date(splitDate[0], splitDate[1] - 1, splitDate[2])
}

// TODO: This code does not work properly, it only does when the timeZone of the customer is Europe/Paris
// https://stackoverflow.com/a/52352512/16796418
export function dateWithoutTimezone (date: Date): Date {
  const userTimezoneOffset = Math.abs(date.getTimezoneOffset() * 60000)
  return (new Date(date.getTime() - userTimezoneOffset))
}
