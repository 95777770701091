import {
  DateFieldOptions,
  ExperienceConfig,
  ExperienceSettings,
  PackFieldOptions,
  PriceFieldOptions,
  QuestionFieldOptions,
  ResourceFieldOptions,
  SlotFieldOptions
} from '@/views/Cart/views/AddToCart/entry/experienceFactories/types'

export class ExperienceBuilder {
  private readonly settings: ExperienceSettings
  private packField: PackFieldOptions = { selectable: true }
  private priceField: PriceFieldOptions | false = false
  private resourceField: ResourceFieldOptions | false = false
  private questionField: QuestionFieldOptions | false = false
  private dateField: DateFieldOptions | false = false
  private slotField: SlotFieldOptions | false = false
  private recipientField: true | false = false

  constructor (settings: ExperienceSettings) {
    this.settings = settings
  }

  public build (): ExperienceConfig {
    return {
      settings: this.settings,
      packField: this.packField,
      priceField: this.priceField,
      resourceField: this.resourceField,
      questionField: this.questionField,
      dateField: this.dateField,
      slotField: this.slotField,
      recipientField: this.recipientField
    }
  }

  public deactivatePackField (): ExperienceBuilder {
    this.packField.selectable = false
    return this
  }

  public activatePriceField (options?: Partial<PriceFieldOptions>): ExperienceBuilder {
    this.priceField = JSON.parse(JSON.stringify({
      minPriceQuantityByUuid: options?.minPriceQuantityByUuid || {},
      initialPriceQuantityByUuid: options?.initialPriceQuantityByUuid ||
        options?.minPriceQuantityByUuid ||
        {},
      unique: options?.unique || false
    }))
    return this
  }

  public activateResourceField (options?: Partial<ResourceFieldOptions>): ExperienceBuilder {
    this.resourceField = {
      initialResourceQuantityByUuid: options?.initialResourceQuantityByUuid || {}
    }
    return this
  }

  public activateQuestionField (options?: Partial<QuestionFieldOptions>): ExperienceBuilder {
    this.questionField = {
      initialAnswer: options?.initialAnswer || null
    }
    return this
  }

  public activateDateField (options?: Partial<DateFieldOptions>): ExperienceBuilder {
    this.dateField = {
      initialDate: options?.initialDate || null
    }
    return this
  }

  public activateSlotField (options?: Partial<SlotFieldOptions>): ExperienceBuilder {
    this.slotField = {
      initialSlotDeparture: options?.initialSlotDeparture || null
    }
    return this
  }

  public activateRecipientField (): ExperienceBuilder {
    this.recipientField = true
    return this
  }
}
