import qsParse from 'qs/lib/parse'
import { Language } from '@/core/settings/constants'

/**
 * Init GUIDAPInitConfig with queryParams found in the URL, used by iframe
 * To test: ?g-token=SUPERDUPERTOKEN&g-lang=en&g-currency=JPY&g-fn[0]=openActivities
 */
export function initGUIDAPInitConfigWithUrlParams (): void {
  const searchParams = qsParse(window.location.search, { ignoreQueryPrefix: true, allowSparse: true })

  const token = searchParams['g-token'] as string
  const lang = searchParams['g-lang'] as Language
  const currency = searchParams['g-currency'] as string
  const hideClose = searchParams['g-hide-close'] as boolean

  if (window.GUIDAPInitConfig) {
    if (!window.GUIDAPInitConfig.token && token) {
      // You can't set the token if one is in place
      window.GUIDAPInitConfig.token = token
    }
    if (lang) {
      window.GUIDAPInitConfig.lang = lang
    }
    if (currency) {
      window.GUIDAPInitConfig.currency = currency
    }
    if (hideClose !== undefined) {
      window.GUIDAPInitConfig.hideClose = true
    }
  }
}
