import { PluginRouteType } from '@/shared/router/types'

export const ROUTE_CART_PAYMENT_SESSION_LOADING = 'cart-payment-session-loading'
export const ROUTE_CART_PAYMENT_SESSION_FAILURE = 'cart-payment-session-failure'
export const ROUTE_CART_PAYMENT_SESSION_SUCCESS = 'cart-payment-session-success'

export const routes: PluginRouteType[] = [
  {
    path: '/loading',
    name: ROUTE_CART_PAYMENT_SESSION_LOADING,
    component: () => import(/* webpackChunkName: "guidap-route-payment-session-loading" */'@/views/Cart/views/PaymentSession/views/PaymentSessionLoading.vue')
  },
  {
    path: '/failure',
    name: ROUTE_CART_PAYMENT_SESSION_FAILURE,
    component: () => import(/* webpackChunkName: "guidap-route-payment-session-failure" */'@/views/Cart/views/PaymentSession/views/PaymentSessionFailure.vue')
  },
  {
    path: '/success',
    name: ROUTE_CART_PAYMENT_SESSION_SUCCESS,
    component: () => import(/* webpackChunkName: "guidap-route-payment-session-success" */'@/views/Cart/views/PaymentSession/views/PaymentSessionSuccess/index.vue')
  }
]
