
import { Component, Ref, Vue } from 'vue-property-decorator'
import { throttle } from 'lodash-es'
import Modal from '@/components/modals/Modal.vue'
import { ModalEvent } from '@/components/modals/constants'

const GUIDAP_POPUP_OPENED_CLASS = 'gdp-scoped-ui'

@Component({
  components: {
    Modal
  }
})
export default class ModalRoot extends Vue {
  @Ref() readonly componentRef!: { close: () => void }

  component = null
  props = null
  events = null
  hasGuidapRootClassName = false

  created (): void {
    this.handleClose = throttle(this.handleClose, 50, { trailing: false })

    this.hasGuidapRootClassName = !!document.documentElement.className.match(GUIDAP_POPUP_OPENED_CLASS)

    this.$modal.$on(ModalEvent.Open, this.handleOpenEvent)
    this.$modal.$on(ModalEvent.Close, this.handleCloseEvent)
  }

  handleClose (): void {
    this.component = null
    this.props = null
    this.events = null
  }

  handleOpenEvent ({ component = null, props = null, events = null } = {}): void {
    this.addRootClass()
    this.$nextTick(() => {
      this.component = component || null
      this.props = props || null
      this.events = events || null
    })
  }

  handleCloseEvent (): void {
    if (this.componentRef && this.componentRef.close) {
      this.componentRef.close()
    } else {
      this.handleClose()
    }
  }

  addRootClass (): void {
    if (this.hasGuidapRootClassName) {
      return
    }
    document.documentElement.className += ` ${GUIDAP_POPUP_OPENED_CLASS}`
  }

  removeRootClass (): void {
    if (this.hasGuidapRootClassName) {
      return
    }
    document.documentElement.className = document.documentElement.className.replace(GUIDAP_POPUP_OPENED_CLASS, '')
  }

  beforeDestroy (): void {
    this.$modal.$off(ModalEvent.Open, this.handleOpenEvent)
    this.$modal.$off(ModalEvent.Close, this.handleCloseEvent)
  }
}
