import Vue from 'vue'
import { AxiosInstance, AxiosResponse } from 'axios'
import { Store } from 'vuex'
import { RootState } from '@/shared/store'
import { CustomerAuthToken } from '@/shared/types/auth'
import { CustomAxiosInterceptorError } from '@/shared/plugins/http/types'

export function setAuthInterceptor (axiosInstance: AxiosInstance, store: Store<RootState>): void {
  let refreshAuthTokenPromise: Promise<AxiosResponse<CustomerAuthToken>> | null = null

  // Response interceptor for API calls
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (
        !error.response ||
        error.response.status !== 401 || // Only works with Authorization error
        error.config._retry // A request can only fail once
      ) {
        return Promise.reject(error)
      }

      // Without refreshToken we can't refresh the accessToken
      if (!store.state.auth.refreshToken) {
        await Vue.$guidap.logout()
        return Promise.reject(error)
      }

      if (!refreshAuthTokenPromise) {
        refreshAuthTokenPromise = getRefreshTokenOrLogoutPromise(store, error)
      }

      // In case multiple requests are run at the same time,
      // we need to wait for the token to be refreshed (once) before going through
      return refreshAuthTokenPromise
        .then((response: AxiosResponse<CustomerAuthToken>) => {
          setFailedRequestAuthorizationHeader(error, response.data.accessToken)

          // After obtaining the tokens, do the request once more
          return retryRequest(axiosInstance, error)
        })
        .finally(() => {
          refreshAuthTokenPromise = null
        })
    }
  )
}

function retryRequest (
  axiosInstance: AxiosInstance,
  error: CustomAxiosInterceptorError
): Promise<AxiosResponse<unknown>> {
  // _retry is a custom field that indicates we are in retry mode from now on
  error.config._retry = true
  return Promise.resolve(axiosInstance(error.config))
}

function getRefreshTokenOrLogoutPromise (
  store: Store<RootState>,
  error: CustomAxiosInterceptorError
): Promise<AxiosResponse<CustomerAuthToken>> {
  return store.dispatch(
    'auth/postCustomerAuthToken',
    { refreshToken: store.state.auth.refreshToken }
  )
    .catch(async () => {
      await Vue.$guidap.logout()
      return Promise.reject(error)
    })
}

function setFailedRequestAuthorizationHeader (
  error: CustomAxiosInterceptorError,
  accessToken: string
) {
  if (!error.config.headers) {
    error.config.headers = {}
  }
  error.config.headers.Authorization = `Bearer ${accessToken}`
}
