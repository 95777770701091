import { Vue } from 'vue-property-decorator'
import { FullActivity } from '@/shared/types/catalog'
import { ROUTE_CART_ACTIVITIES } from '@/views/Cart/routes'
import i18n from '@/i18n'

/**
 * Redirect if activity is not reservable
 * Should only block buy, not edit, or use giftcard
 * Returns true in case of redirection
 */
export function redirectIfActivityIsNotReservable (
  activity: FullActivity
): boolean {
  if (!activity.isActive && !activity.isReservable) {
    Vue.$toast.openDanger(i18n.t('error.activity-not-available'))
    Vue.$grouter.replace({ name: ROUTE_CART_ACTIVITIES })
    return true
  }
  return false
}
