import Vue from 'vue'
import qsParse from 'qs/lib/parse'
import qsStringify from 'qs/lib/stringify'
import { Location as HistoryLocation } from 'history'
import { RouterSplitPathType, RouteType } from '@/shared/router/types'
import { splitStringPath } from '@/shared/router/utils/path'
import { findDeepRoutes } from '@/shared/router/utils/route'

function getSearchParams (): Record<string, string | undefined> {
  return qsParse(window.location.search, { ignoreQueryPrefix: true, allowSparse: true })
}

function locationStringWithSearchParams (searchParams: Record<string, string | undefined>): string {
  const search = qsStringify(searchParams, { encode: true })

  return (window.location.pathname || '/') +
    (search ? `?${search}` : '') +
    window.location.hash
}

export function locationStringWithPath (path?: string, queryParam = Vue.$grouter.queryParam): string {
  const searchParams = getSearchParams()

  if (!path && searchParams[queryParam]) {
    delete searchParams[queryParam]
  } else if (path) {
    searchParams[queryParam] = path
  }

  return locationStringWithSearchParams(searchParams)
}

export function locationStringRemoveQueryParams (queryParams: string[]): string {
  const searchParams = getSearchParams()

  if (queryParams) {
    queryParams.forEach(queryParamToRemove => {
      delete searchParams[queryParamToRemove]
    })
  }

  return locationStringWithSearchParams(searchParams)
}

export function getLocationPath (location: HistoryLocation | Location, queryParam = Vue.$grouter.queryParam): string | undefined {
  const searchParams = qsParse(location.search, { ignoreQueryPrefix: true, allowSparse: true })
  return searchParams[queryParam]
}

export function splitLocationPath (location: HistoryLocation, queryParam = Vue.$grouter.queryParam): RouterSplitPathType | null {
  const foundPath = getLocationPath(location, queryParam)

  if (!foundPath) {
    return null
  }

  return splitStringPath(foundPath)
}

export function routeNameFromLocation (
  location: HistoryLocation,
  cleanedRoutes: RouteType[] = Vue.$grouter.routes,
  queryParam = Vue.$grouter.queryParam
): string | null {
  const locationSplitPath = splitLocationPath(location, queryParam)
  if (locationSplitPath) {
    const foundRoutes = findDeepRoutes(locationSplitPath, cleanedRoutes)
    if (foundRoutes && foundRoutes.length) {
      return foundRoutes[foundRoutes.length - 1].name
    }
  }
  return null
}
