import Vue from 'vue'
import { AxiosPromise, CancelTokenSource } from 'axios'
import { FormName } from '@/shared/constants/form'
import { HttpLoadingType } from '@/shared/plugins/http/types'
import { Form } from '@/shared/types/form'
import { coreSettings } from '@/core/settings'

export function fetchForm (
  name: FormName,
  loading: HttpLoadingType,
  request: CancelTokenSource | null = null
): AxiosPromise<Form[]> {
  return Vue.$http.get(
    `/public/form/${name}`,
    {
      params: { language: coreSettings.lang },
      cancelToken: request?.token,
      metadata: { loading }
    }
  )
}
