import Vue from 'vue'
import { GetterTree, MutationTree, ActionTree } from 'vuex'
import { RootState } from '@/shared/store'
import {
  GET_CUSTOMER_AUTH_INFO,
  SET_AUTH_ACCESS_TOKEN,
  SET_AUTH_REFRESH_TOKEN
} from '@/shared/store/mutations'
import { StorageKey } from '@/shared/plugins/storage/constants'
import { getCustomerAuthInfo, postCustomerAuthCode, postCustomerAuthToken } from '@/shared/api/auth'
import {
  CustomerAuthTokenWithRefreshTokenRequest,
  CustomerAuthTokenWithCodeVerifierRequest,
  CustomerAuthTokenWithEmailCodeVerifierRequest,
  CustomerAuthCodeRequest
} from '@/shared/api/auth/types'
import { CustomerInfo } from '@/shared/types/customer'
import { ExternalEvent } from '@/shared/utils/event/constants'
import { dispatchExternalEvent } from '@/shared/utils/event'

export class State {
  accessToken: string | null = null
  refreshToken: string | null = null
  customerInfo: CustomerInfo | null = null
}

const getters = <GetterTree<State, RootState>>{
  isLoggedIn (state): boolean {
    return !!(state.accessToken || state.refreshToken)
  }
}

const mutations = <MutationTree<State>>{
  [SET_AUTH_ACCESS_TOKEN] (state, accessToken: string | null) {
    const _accessToken = typeof accessToken === 'string' ? accessToken : null

    Vue.set(state, 'accessToken', _accessToken)
    Vue.$storage.set(StorageKey.CustomerAccessToken, _accessToken)
    Vue.$httpAuth.defaults.headers.common.Authorization = `Bearer ${_accessToken}`
  },
  [SET_AUTH_REFRESH_TOKEN] (state, refreshToken: string | null) {
    const _refreshToken = typeof refreshToken === 'string' ? refreshToken : null

    Vue.set(state, 'refreshToken', _refreshToken)
    Vue.$storage.set(StorageKey.CustomerRefreshToken, _refreshToken)
  },
  [GET_CUSTOMER_AUTH_INFO] (state, customerInfo: CustomerInfo) {
    Vue.set(state, 'customerInfo', customerInfo)
  }
}

const actions = <ActionTree<State, RootState>>{
  postCustomerAuthCode (store, body: CustomerAuthCodeRequest) {
    return postCustomerAuthCode(body)
  },
  postCustomerAuthToken (
    store,
    body: CustomerAuthTokenWithRefreshTokenRequest | CustomerAuthTokenWithCodeVerifierRequest | CustomerAuthTokenWithEmailCodeVerifierRequest
  ) {
    return postCustomerAuthToken(body)
      .then(response => {
        store.commit(SET_AUTH_ACCESS_TOKEN, response.data.accessToken)
        store.commit(SET_AUTH_REFRESH_TOKEN, response.data.refreshToken)
        dispatchExternalEvent(ExternalEvent.LoggedIn)
        return response
      })
  },
  getCustomerAuthInfo (store) {
    return getCustomerAuthInfo()
      .then(response => {
        store.commit(GET_CUSTOMER_AUTH_INFO, response.data)
      })
  },
  logout (store) {
    store.commit(SET_AUTH_ACCESS_TOKEN, null)
    store.commit(SET_AUTH_REFRESH_TOKEN, null)
    dispatchExternalEvent(ExternalEvent.LoggedOut)
  }
}

export default {
  namespaced: true,
  state: new State(),
  getters,
  mutations,
  actions
}
