import { currencySymbol } from '@/shared/plugins/price/utils/currency'
import { PricePrettifyOptionsType } from '@/shared/plugins/price/types'
import { CurrencyIso } from '@/shared/plugins/price/constants'

const isNativeI18nAvailable = window.Intl && typeof window.Intl === 'object'
const defaultLocale = Intl.NumberFormat().resolvedOptions().locale

function convertCommaToDot (value: number | string): string {
  return value.toString().replace(',', '.')
}

function formatWithI18n (value: number, options: Intl.NumberFormatOptions): string {
  if (!isNativeI18nAvailable) {
    return value.toLocaleString() + ' ' + currencySymbol(options.currency)
  }

  return new Intl
    .NumberFormat(defaultLocale, options)
    .format(value)
}

/**
 * Format a number into a price.
 * The minimum number of decimal can set to 0 by setting "decimal" to false.
 */
export function formatAsPrice (value: number | string, options: PricePrettifyOptionsType = {}): string | null {
  if (value === null || typeof value === 'undefined') {
    return null
  }

  const valueAsNumber = Number(convertCommaToDot(value))
  if (Number.isNaN(valueAsNumber)) {
    return value.toString()
  }

  return formatWithI18n(valueAsNumber, {
    style: 'currency',
    currency: options.currency || CurrencyIso.EUR,
    // With the "decimal" option, we let Intl decide the minimum digits based on the currency given
    minimumFractionDigits: options.decimal ? undefined : 0
  })
}
