import { AnalyticsPlugin } from 'analytics'
import {
  AnalyticsFunctions,
  AnalyticsPageType,
  AnalyticsTrackType,
  AnalyticsConfigType
} from '@/shared/plugins/analytics/types'
import { GtmEcommerceItems, WindowGtag } from '@/shared/plugins/analytics/impl/gtm/types'
import { AnalyticsEvent } from '@/shared/plugins/analytics/constants'
import {
  createGtmItemFromActivity,
  createGtmItemFromAddon,
  createGtmItemFromOrderPrice,
  createGtmItemFromOrderProduct,
  createGtmItemFromPackage,
  createGtmItemFromPrice,
  getCouponFromOrder,
  getGtmItemListFromOrder
} from '@/shared/plugins/analytics/impl/gtm/utils'

declare global {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  interface Window extends WindowGtag {}
}

const gtmImpl: AnalyticsFunctions = {
  [AnalyticsEvent.ViewActivityList] ({ activities }): void {
    window.gtag('event', 'view_item_list', {
      item_list_id: 'activity',
      item_list_name: 'activity',
      items: activities.map(activity => createGtmItemFromActivity(activity))
    })
  },

  // This event falsifies the reports by sending too much irrelevant information.
  [AnalyticsEvent.SelectActivity] (): void { /* empty */ },

  [AnalyticsEvent.ViewPackageList] ({ fullActivity, packs }): void {
    window.gtag('event', 'view_item_list', {
      item_list_id: 'package',
      item_list_name: 'package',
      items: packs.map(pack => createGtmItemFromPackage(fullActivity, pack))
    })
  },

  // This event falsifies the reports by sending too much irrelevant information.
  [AnalyticsEvent.SelectPackage] (): void { /* empty */ },

  [AnalyticsEvent.ViewPriceList] ({ fullActivity, pack, prices }): void {
    window.gtag('event', 'view_item_list', {
      item_list_id: 'price',
      item_list_name: 'price',
      items: prices.map(price => createGtmItemFromPrice(fullActivity, pack, price))
    })
  },

  [AnalyticsEvent.SelectPrice] ({ fullActivity, pack, price, quantity }, options): void {
    window.gtag('event', 'view_item', {
      currency: options.currency,
      value: price.amount,
      items: [createGtmItemFromPrice(fullActivity, pack, price, quantity)]
    })
  },

  [AnalyticsEvent.ViewAddonList] ({ addons }): void {
    window.gtag('event', 'view_item_list', {
      item_list_id: 'addon',
      item_list_name: 'addon',
      items: addons.map(addon => createGtmItemFromAddon(addon))
    })
  },

  [AnalyticsEvent.SelectAddon] ({ addon, quantity }, options): void {
    window.gtag('event', 'view_item', {
      currency: options.currency,
      value: addon.amount,
      items: [createGtmItemFromAddon(addon, quantity)]
    })
  },

  [AnalyticsEvent.AddToCart] ({
    experienceBody,
    activity,
    pack
  }, options): void {
    let total = 0
    const items: GtmEcommerceItems[] = []

    experienceBody.prices.forEach(price => {
      const foundPrice = pack.prices.find(packPrice => packPrice.uuid === price.uuid)
      if (foundPrice) {
        items.push(createGtmItemFromPrice(activity, pack, foundPrice, price.quantity))
        total += foundPrice.amount * price.quantity
      }
    })

    window.gtag('event', 'add_to_cart', {
      currency: options.currency,
      value: total,
      items
    })
  },

  [AnalyticsEvent.RemoveExperienceFromCart] ({ experience }, options): void {
    window.gtag('event', 'remove_from_cart', {
      currency: options.currency,
      value: experience.total,
      items: experience.prices.map(orderPrice =>
        createGtmItemFromOrderPrice(experience, orderPrice)
      )
    })
  },

  [AnalyticsEvent.RemoveAddonFromCart] ({ orderProduct }, options): void {
    window.gtag('event', 'remove_from_cart', {
      currency: options.currency,
      value: orderProduct.total,
      items: [createGtmItemFromOrderProduct(orderProduct)]
    })
  },

  [AnalyticsEvent.ViewCart] ({ order }, options): void {
    window.gtag('event', 'view_cart', {
      currency: options.currency,
      value: order.leftToPay,
      items: getGtmItemListFromOrder(order)
    })
  },

  [AnalyticsEvent.BeginCheckout] ({ order }, options): void {
    const coupon = getCouponFromOrder(order)

    window.gtag('event', 'begin_checkout', {
      currency: options.currency,
      value: order.leftToPay,
      coupon,
      items: getGtmItemListFromOrder(order, coupon)
    })
  },

  [AnalyticsEvent.AddPaymentInfo] ({ order, paymentMethod }, options): void {
    const coupon = getCouponFromOrder(order)

    window.gtag('event', 'add_payment_info', {
      currency: options.currency,
      value: order.leftToPay,
      coupon,
      payment_type: paymentMethod,
      items: getGtmItemListFromOrder(order, coupon)
    })
  },

  [AnalyticsEvent.OrderCompleted] ({ order, paid }, options): void {
    const coupon = getCouponFromOrder(order)

    window.gtag('event', 'purchase', {
      currency: options.currency,
      transaction_id: order.reference,
      value: paid,
      coupon,
      items: getGtmItemListFromOrder(order, coupon)
    })
  }
}

/**
 * Google Tag Manager & Google Analytics 4
 *
 * An event sent here will be sent to both Google Analytics 4 and Google Tag Manager.
 *
 * There exists two ways to send events to Google services (we use "gtag.js"):
 * https://support.google.com/tagmanager/answer/7582054?hl=en
 *
 * - gtag.js: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag
 * - Tag Manager: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm
 */
export default function GTM (config: AnalyticsConfigType): AnalyticsPlugin {
  return {
    name: 'guidap-gtm',
    config,

    initialize: () => {
      if (window.dataLayer) {
        if (process.env.NODE_ENV === 'development') {
          console.log('[GUIDAP Analytics GTM/GA4] running')
        }
      }
      if (!window.gtag) {
        window.dataLayer = window.dataLayer || []
        /* eslint-disable prefer-rest-params */
        window.gtag = () => { window.dataLayer.push(arguments) }
      }
    },
    page: ({ payload }: AnalyticsPageType) => {
      window.gtag('event', 'page_view', {
        page_title: payload.properties.title
      })
    },
    track: ({ config, payload }: AnalyticsTrackType) => {
      if (gtmImpl[payload.event]) {
        try {
          gtmImpl[payload.event](payload.properties, config)
        } catch (e) {
          console.warn(`[GUIDAP Analytics GTM/GA4] Error on ${payload.event}`, payload.properties)
        }
      }
    },
    identify: () => { /* empty */ },
    loaded: () => {
      return !!window.gtag
    }
  }
}
