import Vue from 'vue'
import Vuex, { GetterTree, MutationTree, ActionTree, StoreOptions } from 'vuex'
import moduleNavigation, { State as NavigationState } from '@/shared/store/modules/navigation'
import moduleCatalog, { State as CatalogState } from '@/shared/store/modules/catalog'
import moduleForm, { State as FormState } from '@/shared/store/modules/form'
import moduleCart, { State as CartState } from '@/shared/store/modules/cart'
import moduleAuth, { State as AuthState } from '@/shared/store/modules/auth'
import moduleCustomer, { State as CustomerState } from '@/shared/store/modules/customer'
import {
  SET_LOADING,
  LOADING_STATE
} from '@/shared/store/mutations'

Vue.use(Vuex)

// https://stackoverflow.com/a/59041769/16796418
class State {
  loading: Record<string, boolean> = LOADING_STATE
}

export interface RootState extends State {
  // Cart
  navigation: NavigationState
  catalog: CatalogState
  form: FormState
  cart: CartState
  // Customer
  auth: AuthState
  customer: CustomerState
}

const getters = <GetterTree<State, RootState>>{
}

const mutations = <MutationTree<State>>{
  [SET_LOADING] (state, [name, status]) {
    state.loading[name] = status
  }
}

const actions = <ActionTree<State, RootState>>{
  getLoading (store, name): boolean {
    return store.state.loading[name]
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const storeOptions: StoreOptions<any> = {
  state: new State(),
  getters,
  mutations,
  actions,
  modules: {
    // Cart
    navigation: moduleNavigation,
    catalog: moduleCatalog,
    form: moduleForm,
    cart: moduleCart,
    // Customer
    auth: moduleAuth,
    customer: moduleCustomer
  }
}

const store = new Vuex.Store<RootState>(storeOptions)

export default store
