import { Vue } from 'vue-property-decorator'
import { FullActivity } from '@/shared/types/catalog'
import i18n from '@/i18n'
import { ROUTE_CART_CONTENT } from '@/views/Cart/routes'
import { OrderExperience } from '@/shared/types/order'

/**
 * Redirect if the experience we try to edit has deleted package or price
 * Returns true in case of redirection
 */
export function redirectIfExperienceIsUsingDeletedConfig (
  activity: FullActivity,
  orderExperience: OrderExperience
): boolean {
  if (
    (() => {
      const foundPack = activity.packages.find(pack => pack.uuid === orderExperience?.details.packageUuid)
      if (!foundPack) {
        return true
      }
      const missingPrice = orderExperience.prices.find(orderPricePrice => {
        return !foundPack.prices.find(price => price.uuid === orderPricePrice.uuid)
      })
      if (missingPrice) {
        return true
      }
      return false
    })()
  ) {
    Vue.$toast.openWarning(i18n.t('prevent.you-cannot-edit-this-activity') as string)
    Vue.$grouter.replace({ name: ROUTE_CART_CONTENT })
    return true
  }
  return false
}
