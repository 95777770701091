
export enum EntryWorkflowMode {
  Creation = 'CREATION',
  Edition = 'EDITION'
}

export enum EntryWorkflowType {
  Departure = 'DEPARTURE',
  BuyGiftcard = 'BUY_GIFTCARD',
  UseGiftcard = 'USE_GIFTCARD'
}
