import Vue, { VueConstructor } from 'vue'
import { formatAsPrice } from '@/shared/plugins/price/utils/format'
import { convertToMajorUnit, convertToMinorUnit } from '@/shared/plugins/price/utils/conversion'
import { PricePluginType, PricePrettifyOptionsType } from '@/shared/plugins/price/types'
import { coreSettings } from '@/core/settings'

declare module 'vue/types/vue' {
  interface Vue {
    $price: PricePluginType
  }
  interface VueConstructor {
    $price: PricePluginType
  }
}

const PricePlugin = {
  install (vue: VueConstructor<Vue>): void {
    vue.$price = vue.prototype.$price = {
      currency: (value: number | string, options: PricePrettifyOptionsType = {}): string | null =>
        formatAsPrice(value, {
          currency: coreSettings.currency,
          decimal: true,
          ...options
        }),
      convertToMinorUnit: (amount: number): number => convertToMinorUnit(amount, coreSettings.currency),
      convertToMajorUnit: (amount: number): number => convertToMajorUnit(amount, coreSettings.currency)
    }

    vue.mixin({
      filters: {
        currency: vue.$price.currency,
        convertToMinorUnit: vue.$price.convertToMinorUnit,
        convertToMajorUnit: vue.$price.convertToMajorUnit
      }
    })
  }
}

export default PricePlugin
