import { pick } from 'lodash-es'
import { Customer, Recipient } from '@/shared/types/order'

const CUSTOMER_ALLOWED_PROPERTIES = [
  'gender',
  'firstname',
  'lastname',
  'company',
  'email',
  'phone',
  'locale',
  'form'
]

const RECIPIENT_ALLOWED_PROPERTIES = [
  'firstname',
  'lastname',
  'email',
  'phone'
]

export function cleanCustomer (customer: Partial<Customer>): Partial<Customer> {
  return pick(customer, CUSTOMER_ALLOWED_PROPERTIES)
}

export function cleanRecipient (recipient: Partial<Recipient>): Partial<Recipient> {
  return pick(recipient, RECIPIENT_ALLOWED_PROPERTIES)
}
