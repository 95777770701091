import Vue, { VueConstructor } from 'vue'
import { createBrowserHistory, createMemoryHistory, Listener, MemoryHistory } from 'history'
import {
  RouterPluginOptionsType,
  RouterPluginType,
  RouteOptionsType,
  PluginRouteType
} from '@/shared/router/types'
import {
  locationStringRemoveQueryParams,
  splitLocationPath,
  getLocationPath
} from '@/shared/router/utils/location'
import { cleanRoutes, mapRoutesByName } from '@/shared/router/utils/route'
import { ROUTER_MISSING_PROP_DEFAULT_VALUE, ROUTER_QUERY_PARAM } from '@/shared/router/constants'
import { getPathFromOptions } from '@/shared/router/utils/options'

declare module 'vue/types/vue' {
  interface Vue {
    $grouter: RouterPluginType
  }
  interface VueConstructor {
    $grouter: RouterPluginType
  }
}

const RouterPlugin = {
  install (
    vue: VueConstructor<Vue>,
    routes: PluginRouteType[] = [],
    options: RouterPluginOptionsType = {
      queryParam: ROUTER_QUERY_PARAM,
      missingPropDefaultValue: ROUTER_MISSING_PROP_DEFAULT_VALUE,
      memoryRouter: false
    }
  ): void {
    const queryParam = options?.queryParam || ROUTER_QUERY_PARAM
    const missingPropDefaultValue = options?.missingPropDefaultValue || ROUTER_MISSING_PROP_DEFAULT_VALUE
    const memoryRouter = !!options?.memoryRouter
    const memoryRouterWithQueryParam = memoryRouter && !!getLocationPath(window.location, queryParam)

    const history = memoryRouter
      ? createMemoryHistory({
        initialEntries: memoryRouterWithQueryParam ? [window.location] : undefined
      })
      : createBrowserHistory()
    const cleanedRoutes = cleanRoutes(routes || [])
    const routesByName = mapRoutesByName(cleanedRoutes)

    let backErrorListeners: CallableFunction[] = []

    vue.$grouter = vue.prototype.$grouter = {
      routes: cleanedRoutes,
      queryParam,
      missingPropDefaultValue,
      memoryRouter,

      getLocation: () => history.location,

      listen: (callback: Listener, ignoreIfNoRouteInPath = false) => {
        return history.listen(param => {
          if (ignoreIfNoRouteInPath && !splitLocationPath(param.location)) {
            return
          }
          callback(param)
        })
      },
      /**
       * Listen for an error when calling back() is not possible.
       * Call listener() to stop listening to the event.
       * Same behavior as history.listen(): https://github.com/remix-run/history/blob/main/docs/api-reference.md#historylistenlistener-listener
       */
      listenForBackError: (callback: CallableFunction) => {
        backErrorListeners.push(callback)
        return function () {
          backErrorListeners = backErrorListeners.filter((listener) => listener !== callback)
        }
      },

      resolve: (options?: RouteOptionsType): string => {
        return getPathFromOptions(options, routesByName)
      },
      push: (options?: RouteOptionsType): void => {
        try {
          history.push(getPathFromOptions(options, routesByName), options?.state)
        } catch (e) {
          console.error('[GUIDAP Router push Error]:', e)
        }
      },
      replace: (options?: RouteOptionsType): void => {
        try {
          history.replace(getPathFromOptions(options, routesByName), options?.state)
        } catch (e) {
          console.error('[GUIDAP Router replace Error]:', e)
        }
      },
      removeQueryParams: (queryParams: string[]): void => {
        try {
          if (memoryRouter) {
            createBrowserHistory().replace(locationStringRemoveQueryParams(queryParams))
            return
          }
          history.replace(locationStringRemoveQueryParams(queryParams))
        } catch (e) {
          console.error('[GUIDAP Router removeQueryParams Error]:', e)
        }
      },

      back: (): void => {
        if (
          // The history.location.key is set to "default" on the first page
          history.location.key === 'default' ||
          // We have different workflows depending on the Router mode
          (memoryRouter
            // When using the memoryRouter, the first page can be known using the "index" property
            ? (<MemoryHistory>history)?.index === 0
            /*
            The above behavior can be broken, if you change the history using the browser and then use the back() function
            The fix is to use the "idx" property contained in the "window.history.state" which indicates the index in the local history
            https://github.com/remix-run/history/issues/791#issuecomment-643818253
             */
            : window.history.state?.idx === 0
          )
        ) {
          backErrorListeners.forEach(callback => callback())
          console.error("[GUIDAP Router back Error]: You can't go back, the history stops here")
          return
        }
        history.back()
      },
      forward: (): void => history.forward()
    }
  }
}

export default RouterPlugin
