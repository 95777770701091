import Vue from 'vue'
import VueI18n, { LocaleMessages } from 'vue-i18n'
import { DEFAULT_LANGUAGE } from '@/core/settings/constants'

Vue.use(VueI18n)

function loadLocaleMessages (): LocaleMessages {
  const locales = require.context('./assets/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages: LocaleMessages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      const translations = locales(key)
      messages[locale] = translations.default ?? translations
    }
  })
  return messages
}

const numberFormats = {
  fr: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0
    }
  }
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || DEFAULT_LANGUAGE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || DEFAULT_LANGUAGE,
  messages: loadLocaleMessages(),
  numberFormats
})
