import Vue from 'vue'
import { AxiosPromise, CancelTokenSource } from 'axios'
import { Tenant } from '@/core/tenant/types'
import { ApiTenantLoading } from '@/shared/api/tenant/constants'

export function getTenant (
  request: CancelTokenSource | null = null
): AxiosPromise<Tenant> {
  return Vue.$http.get(
    '/public/account',
    {
      cancelToken: request?.token,
      metadata: { loading: ApiTenantLoading.GetTenant }
    }
  )
}
