import { RouteOptionsType, RoutePropsType, RoutesByName } from '@/shared/router/types'
import { routesToPath } from '@/shared/router/utils/path'
import { locationStringWithPath } from '@/shared/router/utils/location'

const getPathFromName = (name: string, props?: RoutePropsType, routesByName: RoutesByName = {}): string => {
  const foundRoutes = routesByName[name]
  if (!foundRoutes || !foundRoutes.length) {
    throw new Error(`[GUIDAP Router getPathFromName Error]: (${name}) path not found`)
  }
  return routesToPath(foundRoutes, props)
}

export const getPathFromOptions = (options?: RouteOptionsType, routesByName: RoutesByName = {}): string => {
  if (options?.name) {
    return locationStringWithPath(getPathFromName(options.name, options.props, routesByName))
  }
  // In case no "name" is given, returns the path without the query param
  return locationStringWithPath()
}
