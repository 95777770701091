import Vue from 'vue'
import { AxiosPromise, CancelTokenSource } from 'axios'
import {
  OrderExperienceCreationRequest,
  OrderExperienceUpdateRequest,
  OrderCustomerRequest,
  OrderRecipientRequest,
  GiftcardOrderExperienceCreationRequest,
  GiftcardOrderExperienceUpdateRequest,
  OrderProductsRequest
} from '@/shared/api/order/types'
import {
  CREATE_ORDER,
  CREATE_ORDER_EXPERIENCE,
  GET_ORDER,
  GET_ORDER_COUPON_ELIGIBILITY,
  GET_ORDER_PAYMENT,
  POST_ORDER_EVENT,
  POST_ORDER_COUPON,
  REMOVE_ORDER_COUPON,
  REMOVE_ORDER_EXPERIENCE,
  UPDATE_ORDER_CUSTOMER,
  UPDATE_ORDER_EXPERIENCE,
  UPDATE_ORDER_PRODUCTS,
  UPDATE_ORDER_RECIPIENT,
  UPDATE_ORDER_VALIDATION
} from '@/shared/store/mutations'
import { CouponEligibility, Order } from '@/shared/types/order'
import { PaymentInfo } from '@/shared/types/payment'
import { OrderEvent } from '@/shared/constants/order'

// Order

export function postOrder (
  request: CancelTokenSource | null = null
): AxiosPromise<Order> {
  return Vue.$http.post(
    '/public/order',
    {},
    {
      cancelToken: request?.token,
      metadata: { loading: CREATE_ORDER }
    }
  )
}

export function postOrderExperience (
  uuid: string,
  body: OrderExperienceCreationRequest | GiftcardOrderExperienceCreationRequest,
  request: CancelTokenSource | null = null
): AxiosPromise<Order> {
  return Vue.$http.post(
    `/public/order/${uuid}/experiences`,
    body,
    {
      cancelToken: request?.token,
      metadata: { loading: CREATE_ORDER_EXPERIENCE }
    }
  )
}

export function putOrderExperience (
  uuid: string,
  uuidExperience: string,
  body: OrderExperienceUpdateRequest | GiftcardOrderExperienceUpdateRequest,
  request: CancelTokenSource | null = null
): AxiosPromise<Order> {
  return Vue.$http.put(
    `/public/order/${uuid}/experiences/${uuidExperience}`,
    body,
    {
      cancelToken: request?.token,
      metadata: { loading: UPDATE_ORDER_EXPERIENCE }
    }
  )
}

export function deleteOrderExperience (
  uuid: string,
  uuidExperience: string,
  request: CancelTokenSource | null = null
): AxiosPromise<Order> {
  return Vue.$http.delete(
    `/public/order/${uuid}/experiences/${uuidExperience}`,
    {
      cancelToken: request?.token,
      metadata: { loading: REMOVE_ORDER_EXPERIENCE }
    }
  )
}

export function fetchOrder (
  uuid: string,
  request: CancelTokenSource | null = null
): AxiosPromise<Order> {
  return Vue.$http.get(
    `/public/order/${uuid}`,
    {
      cancelToken: request?.token,
      metadata: { loading: GET_ORDER }
    }
  )
}

// Product

export function putOrderProducts (
  uuid: string,
  body: OrderProductsRequest[],
  request: CancelTokenSource | null = null
): AxiosPromise<Order> {
  return Vue.$http.put(
    `/public/order/${uuid}/products`,
    body,
    {
      cancelToken: request?.token,
      metadata: { loading: UPDATE_ORDER_PRODUCTS }
    }
  )
}

// Customer

export function putOrderCustomer (
  uuid: string,
  body: OrderCustomerRequest,
  request: CancelTokenSource | null = null
): AxiosPromise<Order> {
  return Vue.$http.put(
    `/public/order/${uuid}/customer`,
    body,
    {
      cancelToken: request?.token,
      metadata: { loading: UPDATE_ORDER_CUSTOMER }
    }
  )
}

export function putOrderRecipient (
  uuid: string,
  body: OrderRecipientRequest,
  request: CancelTokenSource | null = null
): AxiosPromise<Order> {
  return Vue.$http.put(
    `/public/order/${uuid}/recipient`,
    body,
    {
      cancelToken: request?.token,
      metadata: { loading: UPDATE_ORDER_RECIPIENT }
    }
  )
}

// Coupon

export function getOrderCouponEligibility (
  uuid: string,
  code: string,
  request: CancelTokenSource | null = null
): AxiosPromise<CouponEligibility> {
  return Vue.$http.get(
    `/public/order/${uuid}/coupons/${code}`,
    {
      cancelToken: request?.token,
      metadata: { loading: GET_ORDER_COUPON_ELIGIBILITY }
    }
  )
}

export function postOrderCoupon (
  uuid: string,
  code: string,
  request: CancelTokenSource | null = null
): AxiosPromise<Order> {
  return Vue.$http.post(
    `/public/order/${uuid}/coupons/${code}`,
    {},
    {
      cancelToken: request?.token,
      metadata: { loading: POST_ORDER_COUPON }
    }
  )
}

export function deleteOrderCoupon (
  uuid: string,
  code: string,
  request: CancelTokenSource | null = null
): AxiosPromise<Order> {
  return Vue.$http.delete(
    `/public/order/${uuid}/coupons/${code}`,
    {
      cancelToken: request?.token,
      metadata: { loading: REMOVE_ORDER_COUPON }
    }
  )
}

// Validation

export function patchOrderValidation (
  uuid: string,
  request: CancelTokenSource | null = null
): AxiosPromise<Order> {
  return Vue.$http.patch(
    `/public/order/${uuid}/validation`,
    {},
    {
      cancelToken: request?.token,
      metadata: { loading: UPDATE_ORDER_VALIDATION }
    }
  )
}

// Action

export function postOrderEvent (
  uuid: string,
  event: OrderEvent,
  request: CancelTokenSource | null = null
): AxiosPromise<void> {
  return Vue.$http.post(
    `/public/order/${uuid}/events/${event}`,
    {},
    {
      cancelToken: request?.token,
      metadata: { loading: POST_ORDER_EVENT }
    }
  )
}

// Payment

export function getOrderPayment (
  uuid: string,
  request: CancelTokenSource | null = null
): AxiosPromise<PaymentInfo> {
  return Vue.$http.get(
    `/public/order/${uuid}/payment`,
    {
      cancelToken: request?.token,
      metadata: { loading: GET_ORDER_PAYMENT }
    }
  )
}
