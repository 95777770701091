
export enum AnalyticsPageView {
  CartActivities = 'GUIDAP - activities',
  CartUseGiftcard = 'GUIDAP - use giftcard',
  CartAddToCart = 'GUIDAP - add to cart',
  CartAddToCartEdition = 'GUIDAP - edit cart item',
  CartFinalizeOrderCart = 'GUIDAP - finalize order cart',
  CartFinalizeOrderCustomer = 'GUIDAP - finalize order customer',
  CartFinalizeOrderPayment = 'GUIDAP - finalize order payment',
  CartOrderComplete = 'GUIDAP - order complete',

  CustomerAccountOrdersReservations = 'GUIDAP - customer account - orders reservations',
  CustomerAccountOrdersSubscriptions = 'GUIDAP - customer account - orders subscriptions',
  CustomerAccountSubscription = 'GUIDAP - customer account - subscription',
  CustomerAccountInvoices = 'GUIDAP - customer account - invoices',
  CustomerAccountPaymentMethods = 'GUIDAP - customer account - payment methods'
}

export enum AnalyticsEvent {
  ViewActivityList = 'viewActivityList',
  SelectActivity = 'selectActivity',
  ViewPackageList = 'viewPackageList',
  SelectPackage = 'selectPackage',
  ViewPriceList = 'viewPriceList',
  SelectPrice = 'selectPrice',
  ViewAddonList = 'viewAddonList',
  SelectAddon = 'selectAddon',
  AddToCart = 'addToCart',
  RemoveExperienceFromCart = 'removeExperienceFromCart',
  RemoveAddonFromCart = 'removeAddonFromCart',
  ViewCart = 'viewCart',
  BeginCheckout = 'beginCheckout',
  AddPaymentInfo = 'addPaymentInfo',
  OrderCompleted = 'orderCompleted'
}
