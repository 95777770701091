import { setFeatureFlags } from '@/core/feature'
import { getTenant } from '@/shared/api/tenant'
import { Tenant } from '@/core/tenant/types'

/**
 * ⚠️ The "coreTenant" object is not reactive.
 */
export let coreTenant: Tenant

export async function fetchAndUseTenant (): Promise<void> {
  return getTenant()
    .then(response => {
      setFeatureFlags(response.data.features)
      coreTenant = response.data
      return Promise.resolve()
    })
    .catch(() => Promise.resolve())
}
