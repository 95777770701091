import Vue from 'vue'
import '@/shared/plugins/dependencies/buefy'
import '@/shared/plugins/dependencies/vue-awesome-countdown'
import store from '@/shared/store'
import MarkdownPlugin from '@/shared/plugins/markdown'
import PricePlugin from '@/shared/plugins/price'
import ToastPlugin from '@/shared/plugins/toast'
import AlertPlugin from '@/shared/plugins/alert'
import ModalPlugin from '@/shared/plugins/modal'
import AnalyticsPlugin from '@/shared/plugins/analytics'
import HttpPlugin from '@/shared/plugins/http'
import StoragePlugin from '@/shared/plugins/storage'
import UrlParamsPlugin from '@/shared/plugins/urlParams'
import RouterPlugin from '@/shared/router/plugin'
import { routes } from '@/routes'
import GuidapPlugin from '@/shared/plugins/guidap'
import { coreSettings } from '@/core/settings'

export function installPlugins (): void {
  Vue.use(MarkdownPlugin)
  Vue.use(PricePlugin)
  Vue.use(ToastPlugin)
  Vue.use(AlertPlugin)
  Vue.use(ModalPlugin)
  Vue.use(AnalyticsPlugin)
  Vue.use(HttpPlugin, store)
  Vue.use(StoragePlugin)

  // Plugins that must always be installed last
  Vue.use(UrlParamsPlugin)
  Vue.use(RouterPlugin, routes, { memoryRouter: coreSettings.memoryRouter })
  Vue.use(GuidapPlugin, store)
}
