import Vue from 'vue'
import { StorageKey } from '@/shared/plugins/storage/constants'
import { StoragePaymentSession } from '@/shared/types/payment'
import i18n from '@/i18n'
import { ROUTE_CART_PAYMENT_SESSION_LOADING } from '@/views/Cart/views/PaymentSession/routes'
import { RouteOptionsType } from '@/shared/router/types'

/**
 * Redirect if a payment session is already in progress
 * Returns true in case of redirection
 */
export function redirectIfPaymentSessionInProgress (next: (options?: RouteOptionsType) => void): boolean {
  const paymentSession = Vue.$storage.get(StorageKey.PaymentSession) as (StoragePaymentSession | null)

  if (paymentSession && paymentSession.paymentSessionUuid) {
    Vue.$toast.openWarning(i18n.t('prevent.payment-is-already-in-progress'))
    next({
      name: ROUTE_CART_PAYMENT_SESSION_LOADING,
      state: { paymentSessionUuid: paymentSession.paymentSessionUuid }
    })
    return true
  }
  return false
}
