import { Vue } from 'vue-property-decorator'
import { ROUTE_CART_USE_GIFTCARD } from '@/views/Cart/routes'
import { EntryWorkflowGiftcard } from '@/views/Cart/views/AddToCart/entry/types'

/**
 * Redirect if the giftcard coupon is missing
 * Returns true in case of redirection
 */
export function redirectIfGiftcardIsMissing (
  giftcard?: EntryWorkflowGiftcard
): giftcard is undefined {
  if (!giftcard || !giftcard.coupon || !giftcard.paidPrices) {
    console.error('[GUIDAP] the giftcard is missing')
    Vue.$grouter.replace({ name: ROUTE_CART_USE_GIFTCARD })
    return true
  }
  return false
}
