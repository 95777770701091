import cc from 'currency-codes'
import { CURRENCIES, CurrencyIso, DEFAULT_CURRENCY_DIGITS } from '@/shared/plugins/price/constants'

export function currencySymbol (currency: string = CurrencyIso.EUR): string {
  return CURRENCIES.get(currency) || CURRENCIES.get(CurrencyIso.EUR) as string
}

const decimalsByCurrency: Record<string, number> = {}

/**
 * Returns the number of digits used by a currency
 */
export function getCurrencyDigits (currency: string): number {
  if (!decimalsByCurrency[currency]) {
    const currencyDetails = cc.code(currency)
    decimalsByCurrency[currency] = currencyDetails ? currencyDetails.digits : DEFAULT_CURRENCY_DIGITS
  }

  return decimalsByCurrency[currency]
}
