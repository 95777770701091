
import { Component, Vue, Prop, Emit } from 'vue-property-decorator'

@Component({
})
export default class GuidapItem extends Vue {
  @Prop(Boolean) readonly selected!: boolean
  @Prop(Boolean) readonly clickable!: boolean
  @Prop(Boolean) readonly disabled!: boolean
  @Prop(Boolean) readonly error!: boolean

  onClick (): void {
    if (!this.clickable || this.disabled) {
      return
    }

    this.click()
  }

  @Emit('click')
  click (): void { /* empty */ }
}
