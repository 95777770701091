import Vue from 'vue'
import { ModalEvent } from '@/components/modals/constants'

// Listen for history changes and open the modal in case it is closed

let isModalOpened = false

export function installModalShouldOpenListener (): void {
  Vue.$modal.$on(ModalEvent.ModalStateChanged, (modalStateChanged: boolean) => {
    isModalOpened = modalStateChanged
  })

  Vue.$grouter.listen(() => {
    if (!isModalOpened) {
      Vue.$modal.$emit(ModalEvent.Open, { component: () => import(/* webpackChunkName: "guidap-modal-navigation" */'@/components/modals/impl/NavigationModal.vue') })
    }
  }, true)
}
