import { routes as paymentSessionRoutes } from '@/views/Cart/views/PaymentSession/routes'
import { EntryWorkflowType } from '@/views/Cart/views/AddToCart/entry/enums'
import { PluginRouteType, RouteOptionsType } from '@/shared/router/types'
import { redirectIfPaymentSessionInProgress } from '@/shared/router/guards/paymentSession'
import { redirectIfCartIsBuyingSubscription } from '@/shared/router/guards/subscription'
import { redirectIfCartIsBuyingGiftcard } from '@/shared/router/guards/giftcard'
import { redirectIfCartIsUsingCoupon } from '@/shared/router/guards/coupon'
import { redirectIfCartIsNonEmpty } from '@/shared/router/guards/cart'

export const ROUTE_CART_ACTIVITIES = 'cart-activities'
export const ROUTE_CART_USE_GIFTCARD = 'cart-use-giftcard'
export const ROUTE_CART_ADD_TO_CART = 'cart-add-to-cart'
export const ROUTE_CART_PAYMENT_SESSION = 'cart-payment-session'
export const ROUTE_CART_CONTENT = 'cart-content'

export const routes: PluginRouteType[] = [
  {
    path: '/activities/:uuid',
    name: ROUTE_CART_ACTIVITIES,
    component: () => import(/* webpackChunkName: "guidap-route-activities" */'@/views/Cart/views/Activities/index.vue'),
    beforeEnter: async (
      to: RouteOptionsType,
      from: RouteOptionsType | null,
      next: (options?: RouteOptionsType) => void
    ): Promise<void> => {
      if (
        redirectIfPaymentSessionInProgress(next) ||
        redirectIfCartIsBuyingGiftcard(next) ||
        redirectIfCartIsBuyingSubscription(next)
      ) {
        return
      }
      next()
    }
  },
  {
    path: '/use-giftcard',
    name: ROUTE_CART_USE_GIFTCARD,
    component: () => import(/* webpackChunkName: "guidap-route-use-giftcard" */'@/views/Cart/views/UseGiftcard/index.vue'),
    beforeEnter: async (
      to: RouteOptionsType,
      from: RouteOptionsType | null,
      next: (options?: RouteOptionsType) => void
    ): Promise<void> => {
      if (
        redirectIfPaymentSessionInProgress(next) ||
        redirectIfCartIsBuyingGiftcard(next) ||
        redirectIfCartIsBuyingSubscription(next) ||
        redirectIfCartIsUsingCoupon(next, 'prevent.to-use-giftcard-you-need-no-coupon-in-use')
      ) {
        return
      }
      next()
    }
  },
  {
    path: '/add-to-cart/:activityUuid/:packUuid/:type',
    name: ROUTE_CART_ADD_TO_CART,
    component: () => import(/* webpackChunkName: "guidap-route-add-to-cart-view" */'@/views/Cart/views/AddToCart/AddToCartView.vue'),
    beforeEnter: async (
      to: RouteOptionsType,
      from: RouteOptionsType | null,
      next: (options?: RouteOptionsType) => void
    ): Promise<void> => {
      if (
        redirectIfPaymentSessionInProgress(next) ||
        (
          to.props?.type === EntryWorkflowType.BuyGiftcard &&
          redirectIfCartIsNonEmpty(next, 'prevent.to-buy-giftcard-you-need-empty-cart')
        ) ||
        redirectIfCartIsBuyingGiftcard(next) ||
        redirectIfCartIsBuyingSubscription(next)
      ) {
        return
      }
      next()
    }
  },
  {
    path: '/payment-session',
    name: ROUTE_CART_PAYMENT_SESSION,
    component: () => import(/* webpackChunkName: "guidap-route-payment-session" */'@/views/Cart/views/PaymentSession/index.vue'),
    children: paymentSessionRoutes
  },
  {
    path: '/',
    name: ROUTE_CART_CONTENT,
    component: () => import(/* webpackChunkName: "guidap-route-finalize-order" */'@/views/Cart/views/FinalizeOrder/index.vue'),
    beforeEnter: async (
      to: RouteOptionsType,
      from: RouteOptionsType | null,
      next: (options?: RouteOptionsType) => void
    ): Promise<void> => {
      if (redirectIfPaymentSessionInProgress(next)) {
        return
      }
      next()
    }
  }
]
