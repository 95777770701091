import Vue from 'vue'
import store from '@/shared/store'
import { routeNameFromLocation } from '@/shared/router/utils/location'
import { SET_IS_FIRST_PAGE } from '@/shared/store/mutations'

// Stores whether the current route was the first opened

let firstRouteName: string | null = null
let lastRouteName: string | null = null

export function installIsFirstPageListener (): void {
  Vue.$grouter.listen(({ location }) => {
    const foundRouteName = routeNameFromLocation(location)

    if (!firstRouteName || !foundRouteName) {
      firstRouteName = foundRouteName
    }
    lastRouteName = foundRouteName

    store.commit(
      `navigation/${SET_IS_FIRST_PAGE}`,
      firstRouteName === lastRouteName
    )
  })
}
