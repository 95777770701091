import Vue from 'vue'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { isEmpty, keyBy, size } from 'lodash-es'
import i18n from '@/i18n'
import { RootState } from '@/shared/store'
import {
  CREATE_ORDER,
  CREATE_ORDER_EXPERIENCE,
  GET_ORDER,
  POST_ORDER_COUPON,
  REMOVE_ORDER_EXPERIENCE,
  REMOVE_ORDER_COUPON,
  RESET_ORDER,
  SET_MEMORY_EXPIRATION_TIMEOUT_ID,
  SET_MEMORY_EXPIRES_AT,
  SET_MEMORY_GIFTCARD,
  SET_MEMORY_UUID,
  SET_MEMORY_EXPERIENCES,
  SET_MEMORY_PAYMENT_INFO,
  UPDATE_ERROR,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_FULL,
  UPDATE_ORDER_EXPERIENCE,
  UPDATE_ORDER_CUSTOMER,
  UPDATE_ORDER_RECIPIENT,
  UPDATE_ORDER_VALIDATION,
  UPDATE_CUSTOMER_FORM,
  REMOVE_CUSTOMER_FORM,
  UPDATE_ORDER_PRODUCTS
} from '@/shared/store/mutations'
import {
  deleteOrderExperience,
  deleteOrderCoupon,
  fetchOrder,
  getOrderCouponEligibility,
  patchOrderValidation,
  postOrder,
  postOrderExperience,
  postOrderCoupon,
  putOrderExperience,
  putOrderCustomer,
  putOrderRecipient,
  putOrderProducts
} from '@/shared/api/order'
import {
  Customer,
  OrderExperience,
  Order,
  OrderPrice,
  CustomerFormType,
  OrderProduct
} from '@/shared/types/order'
import { MemoryGiftcard } from '@/shared/types/giftcard'
import {
  GiftcardOrderExperienceCreationRequest,
  GiftcardOrderExperienceUpdateRequest,
  OrderExperienceCreationRequest,
  OrderExperienceUpdateRequest,
  OrderCustomerRequest,
  OrderRecipientRequest,
  OrderProductsRequest
} from '@/shared/api/order/types'
import { dateWithoutTimezone, isTimestampExpired, timestampDifference } from '@/shared/utils/date'
import { CouponType } from '@/shared/constants/coupon'
import { OrderBehavior } from '@/shared/constants/order'
import { OrderError } from '@/shared/constants/api'
import { StorageKey } from '@/shared/plugins/storage/constants'
import { cleanCustomer } from '@/shared/utils/customer'
import { clearCartStorage } from '@/shared/plugins/storage/utils'
import { Form } from '@/shared/types/form'
import { MemoryPaymentInfo } from '@/shared/types/payment'
import { dispatchExternalEvent } from '@/shared/utils/event'
import { ExternalEvent } from '@/shared/utils/event/constants'
import { parsePhone } from '@guidap/utils/src/phone'

class StateMemory {
  uuid: string | null = null
  expiresAt: Date | null = null
  expirationTimeoutId: number | null = null
  experiences: OrderExperience[] | null = null
  giftcard: MemoryGiftcard | null = null
  paymentInfo: MemoryPaymentInfo | null = null
}
class StateErrors {
  customerEmailError = false
}
class StateCustomer implements Customer {
  gender = 0
  firstname = ''
  lastname = ''
  email = ''
  phone = ''
  company = ''
  locale = ''
  form: CustomerFormType[] = []
}
export class State {
  memory = new StateMemory()
  errors = new StateErrors()
  order = new Order()
  customer = new StateCustomer()
}

const getters = <GetterTree<State, RootState>>{
  isCartEmpty (state, getters): boolean {
    return state.order.experiences.length === 0
  },
  isCartUsingCoupon (state): boolean {
    return !!(state.order.coupon && state.order.coupon.code)
  },
  isCartGiftcard (state): boolean {
    return state.order.behavior === OrderBehavior.Giftcard
  },
  isCartSubscription (state): boolean {
    return state.order.behavior === OrderBehavior.Subscription
  },
  isCartWillNeedToPay (state): boolean {
    return 'payment' in state.order.requirements
  },
  isCartNeedToPay (state): boolean {
    return size(state.order.requirements) === 1 && 'payment' in state.order.requirements
  },
  experiencesByUuid (state): Record<string, OrderExperience> {
    return keyBy(state.order.experiences, 'uuid')
  },
  nestedExperienceUuids (state): string[] {
    return state.order.experiences
      .reduce((acc: string[], experience) => {
        if (experience.nestedExperiences) {
          acc.push(...experience.nestedExperiences)
        }
        return acc
      }, [])
  },
  /**
   * In this array, the first element is the parent experience
   * and the second element is an array of nested experiences
   */
  nestedExperiencesByExperience (state, getters): [OrderExperience, OrderExperience[]][] {
    return state.order.experiences
      .reduce((acc: [OrderExperience, OrderExperience[]][], experience) => {
        // Nested experiences are displayed along their parent
        // Experience without nested experiences being displayed on their own
        if (!getters.nestedExperienceUuids.includes(experience.uuid)) {
          acc.push([
            experience,
            (experience.nestedExperiences || []).map(uuid => getters.experiencesByUuid[uuid])
          ])
        }

        return acc
      }, [])
  },
  mainSubscriptionPrice (state, getters): OrderPrice | null {
    if (
      !getters.isCartSubscription ||
      !state.order.experiences.length ||
      !state.order.experiences[0].prices.length
    ) return null
    return state.order.experiences[0].prices[0]
  },
  /**
   * The amountDetails is shown next to the amount
   * - In case of subscription, we display the unit and period
   */
  amountDetails (state, getters): string | null {
    if (!getters.mainSubscriptionPrice) return null
    return i18n.tc(
      `utils.period.${getters.mainSubscriptionPrice.subscriptionPeriodUnit}`,
      getters.mainSubscriptionPrice.subscriptionPeriodLength || undefined
    )
  },
  /**
   * Excludes main bundle experience from the count
   * This means that it only counts non-bundle experiences and nested experiences
   */
  experiencesCount (state): number {
    return state.order.experiences.filter(experience => !experience.nestedExperiences).length
  },
  productByUuid (state): Record<string, OrderProduct> {
    return keyBy(state.order.products, 'uuid')
  },

  // Customer getters

  customerFormByUuid (state): Record<string, CustomerFormType> {
    return keyBy(state.customer.form || [], 'uuid')
  },
  isCustomerFormValid (state, getters, rootState): boolean {
    return !!rootState.form.formReservationCustomer &&
      rootState.form.formReservationCustomer.every(optIn => {
        if (optIn.required) {
          return !!getters.customerFormByUuid[optIn.uuid] &&
            getters.customerFormByUuid[optIn.uuid].answer
        }
        return true
      })
  },
  isCustomerValidationDisabled (state): boolean {
    return isEmpty(state.customer.firstname) ||
      isEmpty(state.customer.lastname) ||
      isEmpty(state.customer.email) ||
      state.errors.customerEmailError ||
      isEmpty(state.customer.phone)
  },
  customerCountryCode (state, getters): string | null {
    if (!getters.isCustomerFormValid) {
      return null
    }
    return parsePhone(state.customer.phone)?.country || null
  }
}

const mutations = <MutationTree<State>>{
  // Cookies
  [SET_MEMORY_UUID] (state, uuid: string) {
    Vue.set(state.memory, 'uuid', uuid)
    Vue.$storage.set(StorageKey.OrderUuid, uuid)
  },
  [SET_MEMORY_EXPIRES_AT] (state, expiresAt: number | null) {
    if (expiresAt === null) {
      Vue.set(state.memory, 'expiresAt', null)
      Vue.$storage.set(StorageKey.OrderExpiresAt, null)
      return
    }

    Vue.set(state.memory, 'expiresAt', dateWithoutTimezone(new Date(expiresAt * 1000)))
    Vue.$storage.set(StorageKey.OrderExpiresAt, expiresAt)
  },
  [SET_MEMORY_EXPIRATION_TIMEOUT_ID] (state, expirationTimeoutId: number | null) {
    Vue.set(state.memory, 'expirationTimeoutId', expirationTimeoutId)
  },
  [SET_MEMORY_EXPERIENCES] (state, experiences: OrderExperience[] | null) {
    Vue.set(state.memory, 'experiences', experiences)
    Vue.$storage.set(StorageKey.OrderExperiences, experiences)
  },
  [SET_MEMORY_GIFTCARD] (state, memoryGiftcard: MemoryGiftcard | null) {
    Vue.set(state.memory, 'giftcard', memoryGiftcard)
    Vue.$storage.set(StorageKey.OrderGiftcard, memoryGiftcard)
  },
  [SET_MEMORY_PAYMENT_INFO] (state, paymentInfo: MemoryPaymentInfo | null) {
    Vue.set(state.memory, 'paymentInfo', paymentInfo)
    Vue.$storage.set(StorageKey.PaymentInfo, paymentInfo)
  },

  // Errors
  [UPDATE_ERROR] (state, { key, value }: { key: string, value: boolean }) {
    Vue.set(state.errors, key, value)
  },

  // Locals - Customer & Coupon
  [UPDATE_CUSTOMER] (state, { key, value }: { key: string, value: unknown }) {
    Vue.set(state.customer, key, value)
    Vue.nextTick(() => {
      Vue.$storage.set(StorageKey.OrderCustomer, state.customer)
    })
  },
  [UPDATE_CUSTOMER_FORM] (state, customerForm: CustomerFormType) {
    const foundIndex = state.customer.form.findIndex(optIn => optIn.uuid === customerForm.uuid)
    if (foundIndex >= 0) {
      Vue.set(state.customer.form[foundIndex], 'answer', customerForm.answer)
    } else {
      state.customer.form.push(customerForm)
    }

    Vue.nextTick(() => {
      Vue.$storage.set(StorageKey.OrderCustomer, state.customer)
    })
  },
  [REMOVE_CUSTOMER_FORM] (state, index) {
    Vue.delete(state.customer.form, index)

    Vue.nextTick(() => {
      Vue.$storage.set(StorageKey.OrderCustomer, state.customer)
    })
  },
  [UPDATE_CUSTOMER_FULL] (state, customer: Customer) {
    Vue.set(state, 'customer', Object.assign({}, state.customer, cleanCustomer(customer)))
  },

  // Order
  [RESET_ORDER] (state) {
    Vue.set(state, 'order', new Order())
  },
  [CREATE_ORDER] (state, order: Order) {
    Vue.set(state, 'order', order)
  },
  [GET_ORDER] (state, order: Order) {
    Vue.set(state, 'order', order)
  },
  [CREATE_ORDER_EXPERIENCE] (state, order: Order) {
    Vue.set(state, 'order', order)
  },
  [UPDATE_ORDER_EXPERIENCE] (state, order: Order) {
    Vue.set(state, 'order', order)
  },
  [REMOVE_ORDER_EXPERIENCE] (state, order: Order) {
    Vue.set(state, 'order', order)
  },
  [UPDATE_ORDER_PRODUCTS] (state, order: Order) {
    Vue.set(state, 'order', order)
  },
  [UPDATE_ORDER_CUSTOMER] (state, order: Order) {
    Vue.set(state, 'order', order)
  },
  [UPDATE_ORDER_RECIPIENT] (state, order: Order) {
    Vue.set(state, 'order', order)
  },

  // Coupon
  [POST_ORDER_COUPON] (state, order: Order) {
    Vue.set(state, 'order', order)
  },
  [REMOVE_ORDER_COUPON] (state, order: Order) {
    Vue.set(state, 'order', order)
  },

  // Validation
  [UPDATE_ORDER_VALIDATION] (state, order: Order) {
    Vue.set(state, 'order', order)
  }
}

const actions = <ActionTree<State, RootState>>{
  // Memory
  setMemoryUuid (store, uuid: string) {
    let _uuid: string | null = uuid
    if (_uuid === 'null' || isEmpty(_uuid)) {
      _uuid = null
    }

    store.commit(SET_MEMORY_UUID, _uuid)
  },
  setMemoryExpiresAt (store, expiresAt: string | number | null) {
    if (store.state.memory.expirationTimeoutId) {
      clearTimeout(store.state.memory.expirationTimeoutId)
      store.commit(SET_MEMORY_EXPIRATION_TIMEOUT_ID, null)
    }

    let _expiresAt: number | null
    if (expiresAt === null || expiresAt === 'null') {
      _expiresAt = null
    } else {
      _expiresAt = Number(expiresAt)
      if (isNaN(_expiresAt)) {
        _expiresAt = null
      }
    }

    const isDateExpired = isTimestampExpired(_expiresAt)
    if (_expiresAt === null || isDateExpired) {
      store.commit(SET_MEMORY_EXPIRES_AT, null)
      return
    }

    if (store.state.order.uuid) {
      store.commit(
        SET_MEMORY_EXPIRATION_TIMEOUT_ID,
        setTimeout(
          () => {
            store.dispatch('fetchOrder', store.state.order.uuid)
          },
          timestampDifference(_expiresAt)
        )
      )
    }

    store.commit(SET_MEMORY_EXPIRES_AT, _expiresAt)
  },
  updateMemory (store, order: Order) {
    store.dispatch('setMemoryUuid', order.uuid)
    store.dispatch('setMemoryExpiresAt', order.expiresAt)
    store.commit(SET_MEMORY_EXPERIENCES, order.experiences)
  },
  setMemoryGiftcard (store, memoryGiftcard: MemoryGiftcard | string | null) {
    let _memoryGiftcard = null
    if (typeof memoryGiftcard === 'string' && memoryGiftcard !== 'null') {
      try {
        _memoryGiftcard = JSON.parse(memoryGiftcard)
      } catch (e) {
        _memoryGiftcard = null
      }
    } else if (typeof memoryGiftcard === 'object') {
      _memoryGiftcard = memoryGiftcard
    }
    store.commit(SET_MEMORY_GIFTCARD, _memoryGiftcard)
  },
  // Order
  resetOrder (store) {
    store.dispatch('setMemoryUuid', null)
    store.dispatch('setMemoryExpiresAt', null)
    store.commit(SET_MEMORY_GIFTCARD, null)
    store.commit(SET_MEMORY_PAYMENT_INFO, null)
    store.commit(RESET_ORDER)
    clearCartStorage()
  },
  createOrder (store) {
    return postOrder()
      .then(response => {
        store.commit(CREATE_ORDER, response.data)
        store.dispatch('updateMemory', response.data)
        return response.data
      })
  },
  fetchOrder (store, orderUuid: string) {
    return fetchOrder(orderUuid)
      .then(response => {
        const expiredExperiences = (store.state.memory.experiences || []).filter(
          experience => !response.data.experiences.some(
            (responseExperience: OrderExperience) => responseExperience.uuid === experience.uuid
          )
        )

        if (
          store.state.memory.experiences &&
          expiredExperiences.length > 0
        ) {
          // TODO: Remove this once we have the new toast style
          const expiredExperiencesToast = Vue.$toast.openWarning(
            `<div style="cursor: pointer;">${i18n.t('components.guidap.cart-activities-expired')}</div>`,
            true
          )
          const expiredExperiencesToastClick = () => {
            expiredExperiencesToast.$el.removeEventListener('click', expiredExperiencesToastClick, false)
            expiredExperiencesToast.close()
            Vue.$alert.$emit('open', {
              component: () => import(/* webpackChunkName: "guidap-alert-expired-experiences" */'@/components/alerts/impl/ExpiredExperiencesAlert.vue'),
              props: { orderExperiences: expiredExperiences }
            })
          }
          expiredExperiencesToast.$el.addEventListener('click', expiredExperiencesToastClick, false)
        }

        store.commit(GET_ORDER, response.data)
        store.dispatch('updateMemory', response.data)
        return response.data
      })
      .catch(error => {
        if (error?.response?.data?.error === OrderError.Expired) {
          Vue.$toast.openDanger(i18n.t('components.guidap.cart-expired'))
        }
        store.dispatch('resetOrder')
        return Promise.reject(error)
      })
  },
  createOrderExperience (store, experience: OrderExperienceCreationRequest | GiftcardOrderExperienceCreationRequest) {
    let createOrder = null
    if (!store.state.order.uuid) {
      createOrder = store.dispatch('createOrder')
    } else {
      createOrder = Promise.resolve()
    }

    return createOrder
      .then(() => {
        return postOrderExperience(`${store.state.order.uuid}`, experience)
          .then(response => {
            store.commit(CREATE_ORDER_EXPERIENCE, response.data)
            store.dispatch('updateMemory', response.data)
            return response.data
          })
      })
  },
  updateOrderExperience (store, { experienceUuid, experience }: { experienceUuid: string, experience: OrderExperienceUpdateRequest | GiftcardOrderExperienceUpdateRequest }) {
    return putOrderExperience(`${store.state.order.uuid}`, experienceUuid, experience)
      .then(response => {
        store.commit(UPDATE_ORDER_EXPERIENCE, response.data)
        store.dispatch('updateMemory', response.data)
        return response.data
      })
  },
  removeOrderExperience (store, experienceUuid: string) {
    return deleteOrderExperience(`${store.state.order.uuid}`, experienceUuid)
      .then(response => {
        store.commit(REMOVE_ORDER_EXPERIENCE, response.data)
        store.dispatch('updateMemory', response.data)
        return response.data
      })
  },
  // Product
  updateOrderProducts (store, products: OrderProductsRequest[]) {
    return putOrderProducts(`${store.state.order.uuid}`, products)
      .then(response => {
        store.commit(UPDATE_ORDER_PRODUCTS, response.data)
        return response.data
      })
  },
  // Customer
  updateOrderCustomer (store, customer: OrderCustomerRequest) {
    return putOrderCustomer(`${store.state.order.uuid}`, customer)
      .then(response => {
        store.commit(UPDATE_ORDER_CUSTOMER, response.data)
        store.dispatch('updateMemory', response.data)
        return response.data
      })
  },
  cleanOrderCustomerForm (store, formReservationCustomer: Form[]) {
    // Delete removed legals starting by the end
    const indexesToDelete: number[] = []
    store.state.customer.form.forEach((optIn, optInIndex) => {
      if (!formReservationCustomer.find(_optIn => _optIn.uuid === optIn.uuid)) {
        indexesToDelete.unshift(optInIndex)
      }
    })
    indexesToDelete.forEach(indexToDelete => {
      store.commit(REMOVE_CUSTOMER_FORM, indexToDelete)
    })

    // Add missing legals
    formReservationCustomer.forEach(_optIn => {
      if (!store.state.customer.form.find(optIn => optIn.uuid === _optIn.uuid)) {
        store.commit(UPDATE_CUSTOMER_FORM, {
          uuid: _optIn.uuid,
          answer: false
        })
      }
    })
  },
  updateOrderRecipient (store, recipient: OrderRecipientRequest) {
    return putOrderRecipient(`${store.state.order.uuid}`, recipient)
      .then(response => {
        store.commit(UPDATE_ORDER_RECIPIENT, response.data)
        store.dispatch('updateMemory', response.data)
        return response.data
      })
  },
  // Coupon
  fetchOrderCouponEligibility (store, { code, type }: { code: string, type: CouponType }) {
    let createOrder = null
    if (!store.state.order.uuid) {
      createOrder = store.dispatch('createOrder')
    } else {
      createOrder = Promise.resolve()
    }

    return createOrder
      .then(() => {
        return getOrderCouponEligibility(`${store.state.order.uuid}`, encodeURIComponent(code))
          .then(response => {
            if (
              (response.data.giftCardExperience && type === CouponType.Coupon) ||
              (!response.data.giftCardExperience && type === CouponType.Giftcard)
            ) {
              return Promise.reject(new TypeError())
            }
            return response.data
          })
      })
  },
  createOrderCoupon (store, code: string) {
    return postOrderCoupon(`${store.state.order.uuid}`, encodeURIComponent(code))
      .then(response => {
        store.commit(POST_ORDER_COUPON, response.data)
        store.dispatch('updateMemory', response.data)
        return response.data
      })
  },
  removeOrderCoupon (store) {
    if (!store.state.order.coupon || !store.state.order.coupon.code) {
      return Promise.reject(new Error())
    }

    return deleteOrderCoupon(`${store.state.order.uuid}`, `${store.state.order.coupon.code}`)
      .then(response => {
        store.commit(REMOVE_ORDER_COUPON, response.data)
        store.dispatch('updateMemory', response.data)
        return response.data
      })
  },
  async fetchAndCreateOrderCouponEligibility ({ dispatch }, { code, type }: { code: string, type: CouponType }) {
    await dispatch('fetchOrderCouponEligibility', { code, type })

    const result = await dispatch('createOrderCoupon', code)

    dispatchExternalEvent(ExternalEvent.CouponUsed, { code: result.coupon.code })

    return result
  },
  // Validation
  updateOrderValidation (store) {
    return patchOrderValidation(`${store.state.order.uuid}`)
      .then(response => {
        store.commit(UPDATE_ORDER_VALIDATION, response.data)
        store.dispatch('updateMemory', response.data)
        return response.data
      })
  }
}

export default {
  namespaced: true,
  state: new State(),
  getters,
  mutations,
  actions
}
