
import { Component, Prop, Vue } from 'vue-property-decorator'
import GuidapButton from '@/components/ui/GuidapButton.vue'

@Component({
  components: {
    GuidapButton
  }
})
export default class GuidapActivityReserveButton extends Vue {
  @Prop(String) readonly activityUuid!: string

  openActivityReserve (): void {
    this.$guidap.openActivityReserve(this.activityUuid)
  }
}
