import Vue from 'vue'
import store from '@/shared/store'
import {
  SET_AUTH_ACCESS_TOKEN,
  SET_AUTH_REFRESH_TOKEN,
  SET_MEMORY_EXPERIENCES,
  SET_MEMORY_PAYMENT_INFO,
  UPDATE_CUSTOMER_FULL
} from '@/shared/store/mutations'
import { StorageKey } from '@/shared/plugins/storage/constants'
import { coreSettings } from '@/core/settings'

export function getStorageKey (key: StorageKey): string {
  if (!coreSettings.token) {
    return key
  }
  return `${coreSettings.token}_${key}`
}

function initStorageItem (key: StorageKey, storePath: string, isStoreAction = false): void {
  const item = Vue.$storage.get(key)
  if (!item) {
    return
  }

  if (isStoreAction) {
    store.dispatch(storePath, item)
  } else {
    store.commit(storePath, item)
  }
}

export function initStorage (): void {
  // Need to be loaded as fast as possible
  initStorageItem(StorageKey.OrderUuid, 'cart/setMemoryUuid', true)
  initStorageItem(StorageKey.OrderExpiresAt, 'cart/setMemoryExpiresAt', true)
  initStorageItem(StorageKey.CustomerAccessToken, `auth/${SET_AUTH_ACCESS_TOKEN}`)
  initStorageItem(StorageKey.CustomerRefreshToken, `auth/${SET_AUTH_REFRESH_TOKEN}`)

  // Can be loaded later
  initStorageItem(StorageKey.OrderExperiences, `cart/${SET_MEMORY_EXPERIENCES}`)
  initStorageItem(StorageKey.OrderCustomer, `cart/${UPDATE_CUSTOMER_FULL}`)
  initStorageItem(StorageKey.OrderGiftcard, 'cart/setMemoryGiftcard', true)
  initStorageItem(StorageKey.PaymentInfo, `cart/${SET_MEMORY_PAYMENT_INFO}`)
}

export function clearCartStorage (): void {
  Vue.$storage.remove(StorageKey.OrderUuid)
  Vue.$storage.remove(StorageKey.OrderExpiresAt)
  Vue.$storage.remove(StorageKey.OrderExperiences)
  Vue.$storage.remove(StorageKey.OrderGiftcard)
  Vue.$storage.remove(StorageKey.PaymentInfo)
  Vue.$storage.remove(StorageKey.PaymentSession)
}
